import React from 'react';
import styles from '../../applicantContainers/Candidate/MainContent/Main.module.scss';

const StepItem = ({ stepNumber, icon, title, description, onClick, className, displayStyle }) => {
  
  const itemClassName = displayStyle && displayStyle.trim() === 'inline-block' ? '' : className;
 const createMarkup = (html) => {
    return { __html: html };
  };
 
 const isDisabled = className === 'disable';
 
  const handleClick = !isDisabled ? onClick : null;

  const anchorClassName = `${styles.btnContinue} ${ !isDisabled ? '' : styles.disabled}`;

  return (
    <li className={styles[itemClassName]}>
      <div>
        <img src={icon} alt={title} width="90" height="100" />
        <span dangerouslySetInnerHTML={createMarkup(title)} />
        <p>{description}</p>
        {/* Conditionally render the click event based on isClickable and isDisabled */}
        <a className={anchorClassName} onClick={handleClick}>
          <i className={styles.i} style={{ display: displayStyle }}></i> Step {stepNumber} →
        </a>
      </div>
    </li>
  );
};

export default StepItem;
