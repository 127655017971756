import React, { useState, useEffect } from 'react';
import styles from '../Boading.module.scss';
import User from '../../assets/images/icon-user.svg';
import Logo from '../../assets/images/logo.svg';
import 'react-tooltip/dist/react-tooltip.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import top_button from '../../assets/images/top_image.png';
import Search from '../../assets/images/icon-search.svg'


function BoadingHeader({admin}) {
    return (
        <div className={styles.header}>
            <div className={styles.grid}>
                <div className={styles.col12}>
                    <ul>
                        <li className={styles['li-first']}>
                            <div className={styles.logo}>
                                <img src={Logo} alt="Logo" width="195" height="42" />
                            </div>
                        </li>
                        <li className={styles['li-second']}>
                        <div></div>
                        </li>
                        <li className={styles['li-third']}>
                            <h1 className={styles['align-center']}>Offboarding - Employee Offboarding Checklist
                            </h1>
                        </li><li className={styles['li-fourth']}>

                        </li>
                        <li className={styles['li-fifth']}>
                            <div className={styles.admin}>
                                <img src={User} alt="User" width="25" height="25" />{admin}
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default BoadingHeader;
