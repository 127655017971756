// AuthContext.jsx
import React, { createContext, useContext } from 'react';

const TokenContext = createContext();

export const TokenProvider = ({ children }) => {
    const extractTokenFromURL = () => {
        const searchParams = new URLSearchParams(window.location.search);
    let token = searchParams.get('token');
    let zoho_candidate_id = searchParams.get('candidate_id');
    
    // If token or zoho_candidate_id is not found in the URL, set them to specific values
    token = token ? `${token}` : '\'\'';
    
        return { token, zoho_candidate_id };
    };

    return (
        <TokenContext.Provider value={extractTokenFromURL()}> 
            {children}
        </TokenContext.Provider>
    );
};

export const useToken = () => useContext(TokenContext);
