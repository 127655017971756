import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import styles from '../../applicantContainers/Candidate/MainContent/Main.module.scss';
import logo from '../../assets/images/logo.svg';
import generatePDF, { Resolution, Margin } from 'react-to-pdf';

const OffBoardingPdf = () => {
    const [formData, setFormData] = useState(null);
    const targetRef = useRef();
    const apiEndpoint = process.env.REACT_APP_API_URL + 'api/offboarding/view-offboarding-info/';

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post(
                    apiEndpoint,
                    { blitz_user_id: 468 },
                    {
                        headers: {
                            'token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo1NjYsInVzZXJfbmFtZSI6IktpcnRpIFNoYXJtYSIsImlwX2FkZHJlc3MiOiI0OS4zNi4xNzYuMTA3IiwiY3VycmVudF90aW1lIjoiMjAyNC0wOC0xOSAwNToxNTowMS4zNzMwMzEifQ.yXeRVqoEJ1Tpx3oGHJuaChYpLaAVXZPxDPCMvtGeOCw',
                            'accept': 'application/json',
                        },
                    }
                );
                setFormData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [apiEndpoint]);

    const options = {
        filename: "candidate-offboarding-detail.pdf",
        method: "save",
        resolution: Resolution.MEDIUM,
        page: {
            margin: Margin.LARGE,
            format: 'letter',
            orientation: 'landscape',
        },
        canvas: {
            mimeType: 'image/jpeg',
            qualityRatio: 0.8
        },
        overrides: {
            pdf: {
                compress: false
            },
            canvas: {
                useCORS: true
            }
        },
    };

    const getTargetElement = () => document.getElementById('content-id');

    return (
        <>
            <div className={styles['popup-preview']} style={{ display: "block" }}>
                <div className={styles['popup-content-preview']}>
                <a className={styles.btnpdfdwon} onClick={() => generatePDF(getTargetElement, options)}>Download PDF</a>
                <i class="Main_close-popup-preview__WkFPW" style={{ visibility: "hidden" }}></i>
                    <div id="content-id" ref={targetRef}>
                        <div className={styles['header-previwleft']}><img src={logo} alt="BluEnt" /></div>
                        <div>
                            <h2 className={styles.dashreview}><span>Preview</span> of offboarding Employee Details</h2>
                        </div>
                        <h4 className={styles['accordion-btn']} id="first-active"><span></span> Employee Details</h4>
                        <div className={styles['accordion-content']}>
                            <table cellPadding="0" cellSpacing="0" className={styles['basicTABLE']}>
                                <tbody>
                                    
                                    <tr>
                                        <td>
                                            <span className={styles['span']}>First Name:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <input
                                                    type="text"
                                                    className={styles.input}
                                                    value={formData?.info.employee_details.first_name || ''}
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <span className={styles['span']}>Middle Name:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <input
                                                    type="text"
                                                    className={styles.input}
                                                    value={formData?.info.employee_details.middle_name || ''}
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <span className={styles['span']}>Last Name:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <input
                                                    type="text"
                                                    className={styles.input}
                                                    value={formData?.info.employee_details.last_name || ''}
                                                    disabled
                                                />
                                            </div>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td>
                                            <span className={styles['span']}>Job Title:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <input
                                                    type="text"
                                                    className={styles.input}
                                                    value={formData?.info.employee_details.job_title || ''}
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <span className={styles['span']}>Manager Name:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <input
                                                    type="text"
                                                    className={styles.input}
                                                    value={formData?.info.employee_details.manager || ''}
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <span className={styles['span']}>Joining Date:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <input
                                                    type="text"
                                                    className={styles.input}
                                                    value={formData?.info.employee_details.date_of_joining || ''}
                                                    disabled
                                                />
                                            </div>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td>
                                            <span className={styles['span']}>Releaving Date:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <input
                                                    type="text"
                                                    className={styles.input}
                                                    value={formData?.info.employee_details.releaving_date || ''}
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <span className={styles['span']}>Personal Email:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <input
                                                    type="text"
                                                    className={styles.input}
                                                    value={formData?.info.employee_details.personal_email_address || ''}
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <span className={styles['span']}>Professional Email:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <input
                                                    type="text"
                                                    className={styles.input}
                                                    value={formData?.info.employee_details.professional_email_name_preferences || ''}
                                                    disabled
                                                />
                                            </div>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td>
                                            <span className={styles['span']}>Department:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <input
                                                    type="text"
                                                    className={styles.input}
                                                    value={`${formData?.info.employee_details.department || ''}`}
                                                    disabled
                                                />

                                            </div>
                                        </td>
                                        <td>
                                            <span className={styles['span']}>Address:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <textarea
                                                    className={`${styles.input} ${styles.textarea} `}
                                                    value={`${formData?.info.employee_details.street_address || ''}, ${formData?.info.employee_details.city || ''}, ${formData?.info.employee_details.state || ''}, ${formData?.info.employee_details.country || ''}`}
                                                    disabled
                                                >
                                                </textarea>
                                            </div>
                                        </td>
                                        <td>
                                            <span className={styles['span']}>Reason for Leaving:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <textarea
                                                    className={`${styles.input} ${styles.textarea} `}
                                                    value={formData?.info.employee_details.reason_for_leaving || ''}
                                                    disabled
                                                ></textarea>
                                            </div>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td>
                                            <span className={styles['span']}>Comment:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <textarea
                                                    className={`${styles.input} ${styles.textarea} `}
                                                    value={formData?.info.employee_details.comment || ''}
                                                    disabled
                                                >
                                                </textarea>
                                            </div>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td colSpan={3}>
                                        <h4 className={styles['accordion-btn']} id="first-active"><span></span> Classification</h4>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className={styles['span']}>Project Handover:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <input
                                                    type="text"
                                                    className={styles.input}
                                                    value={`${formData?.info.classification.project_handover || ''}`}
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <span className={styles['span']}>Google Email:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <input
                                                    type="text"
                                                    className={styles.input}
                                                    value={formData?.info.classification.google_email ? 'Yes' : 'No'}
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <span className={styles['span']}>Inform Client:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <input
                                                    type="text"
                                                    className={`${styles.input} ${styles.textarea} `}
                                                    value={formData?.info.classification.inform_client ? 'Yes' : 'No'}
                                                    disabled
                                                />
                                            </div>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td>
                                            <span className={styles['span']}>Project Backup Comment:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <textarea
                                                    type="text"
                                                    className={`${styles.input} ${styles.textarea} `}
                                                    value={formData?.info.classification.project_backup_comment || ''}
                                                    disabled
                                                >
                                                </textarea>
                                            </div>
                                        </td>
                                        <td>
                                            <span className={styles['span']}>Remove Software Access:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <ul className={styles.list}>
                                                    {formData?.info?.classification?.remove_software_access ? (
                                                        Object.entries(formData.info.classification.remove_software_access).map(([software, hasAccess]) =>
                                                            hasAccess ? (
                                                                <li key={software}>{software} - Yes</li>
                                                            ) : (
                                                                <li key={software}>{software} - No</li>
                                                            )
                                                        )
                                                    ) : (
                                                        <li>No software access information available</li>
                                                    )}
                                                </ul>
                                            </div>
                                        </td>

                                        <td>
                                            <span className={styles['span']}>Svn:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <input
                                                    type="text"
                                                    className={`${styles.input} ${styles.textarea} `}
                                                    value={formData?.info.classification.svn ? 'Yes' : 'No'}
                                                    disabled
                                                />
                                            </div>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td>
                                            <span className={styles['span']}>Inform Client Comment:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <textarea
                                                    type="text"
                                                    className={`${styles.input} ${styles.textarea} `}
                                                    value={`${formData?.info.classification.inform_client_comment || ''}`}
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <span className={styles['span']}>System Backup:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <input
                                                    type="text"
                                                    className={`${styles.input} ${styles.textarea} `}
                                                    value={formData?.info.classification.system_backup ? 'Yes' : 'No'}
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <span className={styles['span']}>Remove Software Access Comment:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <textarea
                                                    type="text"
                                                    className={`${styles.input} ${styles.textarea} `}
                                                    value={`${formData?.info.classification.remove_software_access_comment || ''}`}
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className={styles['span']}>Skype:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <input
                                                    type="text"
                                                    className={`${styles.input}  `}
                                                    value={formData?.info.classification.skype ? 'Yes' : 'No'}
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <span className={styles['span']}>Remove Client Folder Access:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <input
                                                    type="text"
                                                    className={`${styles.input} ${styles.remove_client_folder_access} `}
                                                    value={formData?.info.classification.system_backup ? 'Yes' : 'No'}
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <span className={styles['span']}>System Backup Comment:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <textarea
                                                    type="text"
                                                    className={`${styles.input} ${styles.textarea} `}
                                                    value={`${formData?.info.classification.system_backup_comment || ''}`}
                                                    disabled
                                                />
                                            </div>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td>
                                            <span className={styles['span']}>Remove Mobile Access:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <ul className={styles.list}>
                                                    {formData?.info?.classification?.remove_mobile_access? (
                                                        Object.entries(formData.info.classification.remove_mobile_access).map(([team, hasAccess]) =>
                                                            hasAccess ? (
                                                                <li key={team}>{team} - Yes</li>
                                                            ) : (
                                                                <li key={team}>{team} - No</li>
                                                            )
                                                        )
                                                    ) : (
                                                        <li>No mobile access information available</li>
                                                    )}
                                                </ul>
                                            </div>
                                        </td>
                                        <td>
                                            <span className={styles['span']}>Reason:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <textarea
                                                    type="text"
                                                    className={`${styles.input} ${styles.textarea} `}
                                                    value={`${formData?.info.classification.reason || ''}`}
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <span className={styles['span']}>Remove Client Folder Access Comment:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <textarea
                                                    type="text"
                                                    className={`${styles.input} ${styles.textarea} `}
                                                    value={`${formData?.info.classification.remove_client_folder_access_comment || ''}`}
                                                    disabled
                                                />
                                            </div>
                                        </td>


                                    </tr>
                                    <tr>
                                        <td>
                                            <span className={styles['span']}>Remove Mobile Access Comment:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <textarea
                                                    type="text"
                                                    className={`${styles.input} ${styles.textarea} `}
                                                    value={`${formData?.info.classification.remove_mobile_access_comment || ''}`}
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <span className={styles['span']}>Email Forward:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <input
                                                    type="text"
                                                    className={styles.input}
                                                    value={formData?.info.classification.email_forward || ''}
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <span className={styles['span']}>Email Backup:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <input
                                                    type="text"
                                                    className={styles.input}
                                                    value={formData?.info.classification.email_backup ? 'Yes' : 'No'}
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className={styles['span']}>For It Info:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <input
                                                    type="text"
                                                    className={styles.input}
                                                    value={formData?.info.classification.for_it_info ? 'Yes' : 'No'}
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <span className={styles['span']}>Project Backup:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <input
                                                    type="text"
                                                    className={styles.input}
                                                    value={formData?.info.classification.project_backup ? 'Yes' : 'No'}
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <span className={styles['span']}>Timesheet Approved:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <input
                                                    type="text"
                                                    className={styles.input}
                                                    value={formData?.info.classification.timesheet_approved ? 'Yes' : 'No'}
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className={styles['span']}>Email Backup Comment:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <textarea
                                                    type="text"
                                                    className={`${styles.input} ${styles.textarea} `}
                                                    value={`${formData?.info.classification.email_backup_comment || ''}`}
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <span className={styles['span']}>logme:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <input
                                                    type="text"
                                                    className={styles.input}
                                                    value={formData?.info.classification.logme ? 'Yes' : 'No'}
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <span className={styles['span']}>Enable Shared Folder:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <input
                                                    type="text"
                                                    className={styles.input}
                                                    value={formData?.info.classification.enable_shared_folder ? 'Yes' : 'No'}
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className={styles['span']}>Rack Space Email:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <input
                                                    type="text"
                                                    className={styles.input}
                                                    value={formData?.info.classification.rack_space_email ? 'Yes' : 'No'}
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <span className={styles['span']}>Time Duration:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <input
                                                    type="text"
                                                    className={styles.input}
                                                    value={formData?.info.classification.time_duration || ''}
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <span className={styles['span']}>Comment:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <textarea
                                                    type="text"
                                                    className={`${styles.input} ${styles.textarea} `}
                                                    value={`${formData?.info.classification.comment || ''}`}
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={3}>
                                        <h4 className={styles['accordion-btn']} id="first-active"><span></span> IT</h4>
                                        </td>
                                    </tr>
                                    <tr>
                                        {formData?.info.it?.access_control ? (
                                            <td>
                                                <span className={styles['span']}>Access Control:</span>
                                                <div className={styles.div}>
                                                    <label className={styles['P-label']}></label>
                                                    <input
                                                        type="text"
                                                        className={styles.input}
                                                        value='Yes'
                                                        disabled
                                                    />
                                                </div>
                                            </td>
                                        ) : formData?.info.it?.access_control_no ? (
                                            <>
                                                <td>
                                                    <span className={styles['span']}>Access Control No:</span>
                                                    <div className={styles.div}>
                                                        <label className={styles['P-label']}></label>
                                                        <input
                                                            type="text"
                                                            className={styles.input}
                                                            value='No'
                                                            disabled
                                                        />
                                                    </div>
                                                </td>
                                                {formData?.info.it?.access_control_comment && (
                                                    <td>
                                                        <span className={styles['span']}>Access Control Comment:</span>
                                                        <div className={styles.div}>
                                                            <label className={styles['P-label']}></label>
                                                            <textarea
                                                                className={`${styles.input} ${styles.textarea}`}
                                                                value={formData?.info.it?.access_control_comment || ''}
                                                                disabled
                                                            />
                                                        </div>
                                                    </td>
                                                )}
                                            </>
                                        ) : null}
                                        {formData?.info.it?.email_forward_no ? (
                                            <>
                                                <td>
                                                    <span className={styles['span']}>Email Forward No:</span>
                                                    <div className={styles.div}>
                                                        <label className={styles['P-label']}></label>
                                                        <input
                                                            type="text"
                                                            className={styles.input}
                                                            value='No'
                                                            disabled
                                                        />
                                                    </div>
                                                </td>
                                                {formData?.info.it?.email_forward_comment && (
                                                    <td>
                                                        <span className={styles['span']}>Email Forward Comment:</span>
                                                        <div className={styles.div}>
                                                            <label className={styles['P-label']}></label>
                                                            <textarea
                                                                className={`${styles.input} ${styles.textarea}`}
                                                                value={formData?.info.it?.email_forward_comment || ''}
                                                                disabled
                                                            />
                                                        </div>
                                                    </td>
                                                )}
                                            </>
                                        ) : formData?.info.it?.email_forward_to ? (
                                            <td>
                                                <span className={styles['span']}>Email Forward To:</span>
                                                <div className={styles.div}>
                                                    <label className={styles['P-label']}></label>
                                                    <textarea
                                                        className={`${styles.input} ${styles.textarea}`}
                                                        value={formData?.info.it?.email_forward_to || ''}
                                                        disabled
                                                    />
                                                </div>
                                            </td>
                                        ) : null}
                                        {formData?.info.it?.inform_client ? (
                                            <td>
                                                <span className={styles['span']}>Inform Client:</span>
                                                <div className={styles.div}>
                                                    <label className={styles['P-label']}></label>
                                                    <input
                                                        type="text"
                                                        className={styles.input}
                                                        value='Yes'
                                                        disabled
                                                    />
                                                </div>
                                            </td>
                                        ) : formData?.info.it?.inform_client_no ? (
                                            <>
                                                <td>
                                                    <span className={styles['span']}>Inform Client No:</span>
                                                    <div className={styles.div}>
                                                        <label className={styles['P-label']}></label>
                                                        <input
                                                            type="text"
                                                            className={styles.input}
                                                            value='No'
                                                            disabled
                                                        />
                                                    </div>
                                                </td>
                                                {formData?.info.it?.inform_client_comment && (
                                                    <td>
                                                        <span className={styles['span']}>Inform Client Comment:</span>
                                                        <div className={styles.div}>
                                                            <label className={styles['P-label']}></label>
                                                            <textarea
                                                                className={`${styles.input} ${styles.textarea}`}
                                                                value={formData?.info.it?.inform_client_comment || ''}
                                                                disabled
                                                            />
                                                        </div>
                                                    </td>
                                                )}
                                            </>
                                        ) : null}

                                    </tr>
                                    <tr>
                                        {formData?.info.it?.inform_client_classification_comment && (
                                            <td>
                                                <span className={styles['span']}>Inform Client Classification Comment:</span>
                                                <div className={styles.div}>
                                                    <label className={styles['P-label']}></label>
                                                    <textarea
                                                        className={`${styles.input} ${styles.textarea}`}
                                                        value={formData?.info.it.inform_client_classification_comment || ''}
                                                        disabled
                                                    />
                                                </div>
                                            </td>
                                        )}
                                        {formData?.info.it?.system_backup ? (
                                            <td>
                                                <span className={styles['span']}>System Backup:</span>
                                                <div className={styles.div}>
                                                    <label className={styles['P-label']}></label>
                                                    <input
                                                        type="text"
                                                        className={styles.input}
                                                        value='Yes'
                                                        disabled
                                                    />
                                                </div>
                                            </td>
                                        ) : formData?.info.it?.system_backup_no ? (
                                            <>
                                                <td>
                                                    <span className={styles['span']}>System Backup No:</span>
                                                    <div className={styles.div}>
                                                        <label className={styles['P-label']}></label>
                                                        <input
                                                            type="text"
                                                            className={styles.input}
                                                            value='No'
                                                            disabled
                                                        />
                                                    </div>
                                                </td>
                                                {formData?.info.it?.system_backup_comment && (
                                                    <td>
                                                        <span className={styles['span']}>System Backup Comment:</span>
                                                        <div className={styles.div}>
                                                            <label className={styles['P-label']}></label>
                                                            <textarea
                                                                className={`${styles.input} ${styles.textarea}`}
                                                                value={formData?.info.it?.system_backup_comment || ''}
                                                                disabled
                                                            />
                                                        </div>
                                                    </td>
                                                )}
                                            </>
                                        ) : null}
                                        {formData?.info.it?.system_backup_classification_comment && (
                                            <td>
                                                <span className={styles['span']}>System Backup Classification Comment:</span>
                                                <div className={styles.div}>
                                                    <label className={styles['P-label']}></label>
                                                    <textarea
                                                        className={`${styles.input} ${styles.textarea}`}
                                                        value={formData?.info.it.system_backup_classification_comment || ''}
                                                        disabled
                                                    />
                                                </div>
                                            </td>
                                        )}



                                    </tr>
                                    <tr>
                                        {formData?.info.it?.ad_account_disabled ? (
                                            <td>
                                                <span className={styles['span']}>AD Account Disabled:</span>
                                                <div className={styles.div}>
                                                    <label className={styles['P-label']}></label>
                                                    <input
                                                        type="text"
                                                        className={styles.input}
                                                        value='Yes'
                                                        disabled
                                                    />
                                                </div>
                                            </td>
                                        ) : formData?.info.it?.ad_account_disabled_no ? (
                                            <>
                                                <td>
                                                    <span className={styles['span']}>AD Account Disabled No:</span>
                                                    <div className={styles.div}>
                                                        <label className={styles['P-label']}></label>
                                                        <input
                                                            type="text"
                                                            className={styles.input}
                                                            value='No'
                                                            disabled
                                                        />
                                                    </div>
                                                </td>
                                                {formData?.info.it?.ad_account_disabled_comment && (
                                                    <td>
                                                        <span className={styles['span']}>AD Account Disabled Comment:</span>
                                                        <div className={styles.div}>
                                                            <label className={styles['P-label']}></label>
                                                            <textarea
                                                                className={`${styles.input} ${styles.textarea}`}
                                                                value={formData?.info.it?.ad_account_disabled_comment || ''}
                                                                disabled
                                                            />
                                                        </div>
                                                    </td>
                                                )}
                                            </>
                                        ) : null}

                                        {formData?.info.it?.project_backup ? (
                                            <td>
                                                <span className={styles['span']}>Project Backup:</span>
                                                <div className={styles.div}>
                                                    <label className={styles['P-label']}></label>
                                                    <input
                                                        type="text"
                                                        className={styles.input}
                                                        value='Yes'
                                                        disabled
                                                    />
                                                </div>
                                            </td>
                                        ) : formData?.info.it?.project_backup_no ? (
                                            <>
                                                <td>
                                                    <span className={styles['span']}>Project Backup No:</span>
                                                    <div className={styles.div}>
                                                        <label className={styles['P-label']}></label>
                                                        <input
                                                            type="text"
                                                            className={styles.input}
                                                            value='No'
                                                            disabled
                                                        />
                                                    </div>
                                                </td>
                                                {formData?.info.it?.project_backup_comment && (
                                                    <td>
                                                        <span className={styles['span']}>Project Backup Comment:</span>
                                                        <div className={styles.div}>
                                                            <label className={styles['P-label']}></label>
                                                            <textarea
                                                                className={`${styles.input} ${styles.textarea}`}
                                                                value={formData?.info.it?.project_backup_comment || ''}
                                                                disabled
                                                            />
                                                        </div>
                                                    </td>
                                                )}
                                            </>
                                        ) : null}
                                        {formData?.info.it?.folder_permission ? (
                                            <td>
                                                <span className={styles['span']}>Folder Permission:</span>
                                                <div className={styles.div}>
                                                    <label className={styles['P-label']}></label>
                                                    <input
                                                        type="text"
                                                        className={styles.input}
                                                        value='Yes'
                                                        disabled
                                                    />
                                                </div>
                                            </td>
                                        ) : formData?.info.it?.folder_permission_no ? (
                                            <>
                                                <td>
                                                    <span className={styles['span']}>Folder Permission No:</span>
                                                    <div className={styles.div}>
                                                        <label className={styles['P-label']}></label>
                                                        <input
                                                            type="text"
                                                            className={styles.input}
                                                            value='No'
                                                            disabled
                                                        />
                                                    </div>
                                                </td>
                                                {formData?.info.it?.folder_permission_comment && (
                                                    <td>
                                                        <span className={styles['span']}>Folder Permission Comment:</span>
                                                        <div className={styles.div}>
                                                            <label className={styles['P-label']}></label>
                                                            <textarea
                                                                className={`${styles.input} ${styles.textarea}`}
                                                                value={formData?.info.it?.folder_permission_comment || ''}
                                                                disabled
                                                            />
                                                        </div>
                                                    </td>
                                                )}
                                            </>
                                        ) : null}


                                    </tr>
                                    <tr>
                                        {formData?.info.it?.mobile_and_SIM ? (
                                            <td>
                                                <span className={styles['span']}>Mobile and SIM:</span>
                                                <div className={styles.div}>
                                                    <label className={styles['P-label']}></label>
                                                    <input
                                                        type="text"
                                                        className={styles.input}
                                                        value='Yes'
                                                        disabled
                                                    />
                                                </div>
                                            </td>
                                        ) : formData?.info.it?.mobile_and_SIM_no ? (
                                            <>
                                                <td>
                                                    <span className={styles['span']}>Mobile and SIM No:</span>
                                                    <div className={styles.div}>
                                                        <label className={styles['P-label']}></label>
                                                        <input
                                                            type="text"
                                                            className={styles.input}
                                                            value='No'
                                                            disabled
                                                        />
                                                    </div>
                                                </td>
                                                {formData?.info.it?.mobile_and_SIM_comment && (
                                                    <td>
                                                        <span className={styles['span']}>Mobile and SIM Comment:</span>
                                                        <div className={styles.div}>
                                                            <label className={styles['P-label']}></label>
                                                            <textarea
                                                                className={`${styles.input} ${styles.textarea}`}
                                                                value={formData?.info.it?.mobile_and_SIM_comment || ''}
                                                                disabled
                                                            />
                                                        </div>
                                                    </td>
                                                )}
                                            </>
                                        ) : null}
                                        {formData?.info.it?.email_backup ? (
                                            <td>
                                                <span className={styles['span']}>Email Backup:</span>
                                                <div className={styles.div}>
                                                    <label className={styles['P-label']}></label>
                                                    <input
                                                        type="text"
                                                        className={styles.input}
                                                        value='Yes'
                                                        disabled
                                                    />
                                                </div>
                                            </td>
                                        ) : formData?.info.it?.email_backup_no ? (
                                            <>
                                                <td>
                                                    <span className={styles['span']}>Email Backup No:</span>
                                                    <div className={styles.div}>
                                                        <label className={styles['P-label']}></label>
                                                        <input
                                                            type="text"
                                                            className={styles.input}
                                                            value='No'
                                                            disabled
                                                        />
                                                    </div>
                                                </td>
                                                {formData?.info.it?.email_backup_comment && (
                                                    <td>
                                                        <span className={styles['span']}>Email Backup Comment:</span>
                                                        <div className={styles.div}>
                                                            <label className={styles['P-label']}></label>
                                                            <textarea
                                                                className={`${styles.input} ${styles.textarea}`}
                                                                value={formData?.info.it?.email_backup_comment || ''}
                                                                disabled
                                                            />
                                                        </div>
                                                    </td>
                                                )}
                                            </>
                                        ) : null}
                                        {formData?.info.it?.email_backup_classification_comment && (
                                            <td>
                                                <span className={styles['span']}>Email Backup Classification Comment:</span>
                                                <div className={styles.div}>
                                                    <label className={styles['P-label']}></label>
                                                    <textarea
                                                        className={`${styles.input} ${styles.textarea}`}
                                                        value={formData?.info.it.email_backup_classification_comment || ''}
                                                        disabled
                                                    />
                                                </div>
                                            </td>
                                        )}

                                    </tr>
                                    <tr>
                                        {formData?.info.it?.enable_shared_folder ? (
                                            <td>
                                                <span className={styles['span']}>Enable Shared Folder:</span>
                                                <div className={styles.div}>
                                                    <label className={styles['P-label']}></label>
                                                    <input
                                                        type="text"
                                                        className={styles.input}
                                                        value='Yes'
                                                        disabled
                                                    />
                                                </div>
                                            </td>
                                        ) : formData?.info.it?.enable_shared_no ? (
                                            <>
                                                <td>
                                                    <span className={styles['span']}>Enable Shared No:</span>
                                                    <div className={styles.div}>
                                                        <label className={styles['P-label']}></label>
                                                        <input
                                                            type="text"
                                                            className={styles.input}
                                                            value='No'
                                                            disabled
                                                        />
                                                    </div>
                                                </td>
                                                {formData?.info.it?.enable_shared_comment && (
                                                    <td>
                                                        <span className={styles['span']}>Enable Shared Comment:</span>
                                                        <div className={styles.div}>
                                                            <label className={styles['P-label']}></label>
                                                            <textarea
                                                                className={`${styles.input} ${styles.textarea}`}
                                                                value={formData?.info.it?.enable_shared_comment || ''}
                                                                disabled
                                                            />
                                                        </div>
                                                    </td>
                                                )}
                                            </>
                                        ) : null}
                                        {formData?.info.it?.enable_shared_classification_comment && (
                                            <td>
                                                <span className={styles['span']}>Enable Shared Classification Comment:</span>
                                                <div className={styles.div}>
                                                    <label className={styles['P-label']}></label>
                                                    <textarea
                                                        className={`${styles.input} ${styles.textarea}`}
                                                        value={formData?.info.it.enable_shared_classification_comment || ''}
                                                        disabled
                                                    />
                                                </div>
                                            </td>
                                        )}
                                        {formData?.info.it?.google_email ? (
                                            <td>
                                                <span className={styles['span']}>Google Email:</span>
                                                <div className={styles.div}>
                                                    <label className={styles['P-label']}></label>
                                                    <input
                                                        type="text"
                                                        className={styles.input}
                                                        value='Yes'
                                                        disabled
                                                    />
                                                </div>
                                            </td>
                                        ) : formData?.info.it?.google_email_no ? (
                                            <>
                                                <td>
                                                    <span className={styles['span']}>Google Email No:</span>
                                                    <div className={styles.div}>
                                                        <label className={styles['P-label']}></label>
                                                        <input
                                                            type="text"
                                                            className={styles.input}
                                                            value='No'
                                                            disabled
                                                        />
                                                    </div>
                                                </td>
                                                {formData?.info.it?.google_email_comment && (
                                                    <td>
                                                        <span className={styles['span']}>Google Email Comment:</span>
                                                        <div className={styles.div}>
                                                            <label className={styles['P-label']}></label>
                                                            <textarea
                                                                className={`${styles.input} ${styles.textarea}`}
                                                                value={formData?.info.it?.google_email_comment || ''}
                                                                disabled
                                                            />
                                                        </div>
                                                    </td>
                                                )}
                                            </>
                                        ) : null}


                                    </tr>
                                    <tr>
                                        {formData?.info.it?.google_email_classification_comment && (
                                            <td>
                                                <span className={styles['span']}>Google Email Classification Comment:</span>
                                                <div className={styles.div}>
                                                    <label className={styles['P-label']}></label>
                                                    <textarea
                                                        className={`${styles.input} ${styles.textarea}`}
                                                        value={formData?.info.it.google_email_classification_comment || ''}
                                                        disabled
                                                    />
                                                </div>
                                            </td>
                                        )}
                                        {formData?.info.it?.email_address_removed ? (
                                            <td>
                                                <span className={styles['span']}>Email Address Removed:</span>
                                                <div className={styles.div}>
                                                    <label className={styles['P-label']}></label>
                                                    <input
                                                        type="text"
                                                        className={styles.input}
                                                        value='Yes'
                                                        disabled
                                                    />
                                                </div>
                                            </td>
                                        ) : formData?.info.it?.email_address_removed_no ? (
                                            <>
                                                <td>
                                                    <span className={styles['span']}>Email Address Removed No:</span>
                                                    <div className={styles.div}>
                                                        <label className={styles['P-label']}></label>
                                                        <input
                                                            type="text"
                                                            className={styles.input}
                                                            value='No'
                                                            disabled
                                                        />
                                                    </div>
                                                </td>
                                                {formData?.info.it?.email_address_removed_comment && (
                                                    <td>
                                                        <span className={styles['span']}>Email Address Removed Comment:</span>
                                                        <div className={styles.div}>
                                                            <label className={styles['P-label']}></label>
                                                            <textarea
                                                                className={`${styles.input} ${styles.textarea}`}
                                                                value={formData?.info.it?.email_address_removed_comment || ''}
                                                                disabled
                                                            />
                                                        </div>
                                                    </td>
                                                )}
                                            </>
                                        ) : null}

                                        {formData?.info.it?.remove_client_folder_access ? (
                                            <td>
                                                <span className={styles['span']}>Remove Client Folder Access:</span>
                                                <div className={styles.div}>
                                                    <label className={styles['P-label']}></label>
                                                    <input
                                                        type="text"
                                                        className={styles.input}
                                                        value='Yes'
                                                        disabled
                                                    />
                                                </div>
                                            </td>
                                        ) : formData?.info.it?.remove_client_folder_access_no ? (
                                            <>
                                                <td>
                                                    <span className={styles['span']}>Remove Client Folder Access No:</span>
                                                    <div className={styles.div}>
                                                        <label className={styles['P-label']}></label>
                                                        <input
                                                            type="text"
                                                            className={styles.input}
                                                            value='No'
                                                            disabled
                                                        />
                                                    </div>
                                                </td>
                                                {formData?.info.it?.remove_client_folder_access_comment && (
                                                    <td>
                                                        <span className={styles['span']}>Remove Client Folder Access Comment:</span>
                                                        <div className={styles.div}>
                                                            <label className={styles['P-label']}></label>
                                                            <textarea
                                                                className={`${styles.input} ${styles.textarea}`}
                                                                value={formData?.info.it?.remove_client_folder_access_comment || ''}
                                                                disabled
                                                            />
                                                        </div>
                                                    </td>
                                                )}
                                            </>
                                        ) : null}

                                    </tr>
                                    <tr>
                                        {formData?.info.it?.remove_client_folder_access_classification_comment && (
                                            <td>
                                                <span className={styles['span']}>Remove Client Folder Access Classification Comment:</span>
                                                <div className={styles.div}>
                                                    <label className={styles['P-label']}></label>
                                                    <textarea
                                                        className={`${styles.input} ${styles.textarea}`}
                                                        value={formData?.info.it.remove_client_folder_access_classification_comment || ''}
                                                        disabled
                                                    />
                                                </div>
                                            </td>
                                        )}
                                        {formData?.info.it?.software_access_classification_comment && (
                                            <td>
                                                <span className={styles['span']}>Software Access Classification Comment:</span>
                                                <div className={styles.div}>
                                                    <label className={styles['P-label']}></label>
                                                    <textarea
                                                        className={`${styles.input} ${styles.textarea}`}
                                                        value={formData?.info.it.software_access_classification_comment || ''}
                                                        disabled
                                                    />
                                                </div>
                                            </td>
                                        )}

                                        {formData?.info.it?.software_access_value && (
                                            <td>
                                                <span className={styles['span']}>Software Access:</span>
                                                <div className={styles.div}>
                                                    <label className={styles['P-label']}></label>
                                                    <ul className={styles.list}>
                                                        {Object.entries(formData.info.it.software_access_value).map(([software, hasAccess]) =>
                                                            hasAccess ? (
                                                                <li key={software}>{software}</li>
                                                            ) : null
                                                        )}
                                                    </ul>
                                                </div>
                                            </td>
                                        )}
                                    </tr>
                                    <tr>
                                        {formData?.info.it?.mobile_access_classification_comment && (
                                            <td>
                                                <span className={styles['span']}>Mobile Access Classification Comment:</span>
                                                <div className={styles.div}>
                                                    <label className={styles['P-label']}></label>
                                                    <textarea
                                                        className={`${styles.input} ${styles.textarea}`}
                                                        value={formData?.info.it.mobile_access_classification_comment || ''}
                                                        disabled
                                                    />
                                                </div>
                                            </td>
                                        )}

                                        {formData?.info.it?.mobile_access_value && (
                                            <td>
                                                <span className={styles['span']}>Mobile Access:</span>
                                                <div className={styles.div}>
                                                    <label className={styles['P-label']}></label>
                                                    <ul className={styles.list}>
                                                        {Object.entries(formData.info.it.mobile_access_value).map(([access, hasAccess]) =>
                                                            hasAccess ? (
                                                                <li key={access}>{access}</li>
                                                            ) : null
                                                        )}
                                                    </ul>
                                                </div>
                                            </td>
                                        )}
                                        {formData?.info.it?.time_duration && (
                                            <td>
                                                <span className={styles['span']}>Time Duration:</span>
                                                <div className={styles.div}>
                                                    <label className={styles['P-label']}></label>
                                                    <input
                                                        type="text"
                                                        className={styles.input}
                                                        value={formData?.info.it.time_duration || ''}
                                                        disabled
                                                    />
                                                </div>
                                            </td>
                                        )}
                                    </tr>
                                    <tr>{formData?.info.it?.pc_equipment_value && (
                                        <td>
                                            <span className={styles['span']}>PC Equipment:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <ul className={styles.list}>
                                                    {Object.entries(formData.info.it.pc_equipment_value).map(([equipment, hasAccess]) =>
                                                        hasAccess ? (
                                                            <li key={equipment}>{equipment}</li>
                                                        ) : null
                                                    )}
                                                </ul>
                                            </div>
                                        </td>
                                    )}
                                        <td>
                                            <span className={styles['span']}>Comment:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <textarea
                                                    type="text"
                                                    className={`${styles.input} ${styles.textarea} `}
                                                    value={`${formData?.info.classification.comment || ''}`}
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                    <td colSpan={3}>
                                        <h4 className={styles['accordion-btn']} id="first-active"><span></span> Administrative</h4>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className={styles['span']}>Return Business Card:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <input
                                                    type="text"
                                                    className={styles.input}
                                                    value={formData?.info.admin.return_business_card ? 'Yes' : 'No'}
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <span className={styles['span']}>Return Business Card Comment:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <textarea
                                                    type="text"
                                                    className={`${styles.input} ${styles.textarea} `}
                                                    value={`${formData?.info.classification.return_business_card_comment || ''}`}
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <span className={styles['span']}>Books:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <input
                                                    type="text"
                                                    className={styles.input}
                                                    value={formData?.info.admin.books ? 'Yes' : 'No'}
                                                    disabled
                                                />
                                            </div>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td>
                                            <span className={styles['span']}>Books Comment:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <textarea
                                                    type="text"
                                                    className={`${styles.input} ${styles.textarea} `}
                                                    value={`${formData?.info.classification.books_comment || ''}`}
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <span className={styles['span']}>No Dues:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <input
                                                    type="text"
                                                    className={styles.input}
                                                    value={formData?.info.admin.no_dues ? 'Yes' : 'No'}
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <span className={styles['span']}>No Dues Comment:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <textarea
                                                    type="text"
                                                    className={`${styles.input} ${styles.textarea} `}
                                                    value={`${formData?.info.classification.no_dues_comment || ''}`}
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className={styles['span']}>ID Card Returned:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <input
                                                    type="text"
                                                    className={styles.input}
                                                    value={
                                                        formData?.info?.admin?.id_card_returned
                                                            ? 'Yes'
                                                            : formData?.info?.admin?.id_card_returned_no
                                                                ? 'No'
                                                                : formData?.info?.admin?.id_card_returned_not_applicable
                                                                    ? 'Not Applicable'
                                                                    : 'Unknown'
                                                    }
                                                    disabled
                                                />
                                            </div>
                                        </td>

                                        <td>
                                            <span className={styles['span']}>Comment:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <textarea
                                                    type="text"
                                                    className={`${styles.input} ${styles.textarea} `}
                                                    value={`${formData?.info.classification.comment || ''}`}
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        
                                        <td colSpan={3}>
                                        <h4 className={styles['accordion-btn']} id="first-active"><span></span>Final HR Process</h4>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className={styles['span']}>Confidentiality Agreement Signed:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <input
                                                    type="text"
                                                    className={styles.input}
                                                    value={formData?.info.final_hr_process.confidentiality_agreement_signed ? 'Yes' : 'No'}
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <span className={styles['span']}>Clearance Certificate Signed:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <input
                                                    type="text"
                                                    className={styles.input}
                                                    value={formData?.info.final_hr_process.clearance_certificate_signed ? 'Yes' : 'No'}
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <span className={styles['span']}>Relieving Letter Issued:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <input
                                                    type="text"
                                                    className={styles.input}
                                                    value={formData?.info.final_hr_process.relieving_letter_issued ? 'Yes' : 'No'}
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>

                                        <td>
                                            <span className={styles['span']}>Blitz Account Removed:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <input
                                                    type="text"
                                                    className={styles.input}
                                                    value={formData?.info.final_hr_process.blitz_account_removed ? 'Yes' : 'No'}
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <span className={styles['span']}>Relieving Letter Issued Comment:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <textarea
                                                    type="text"
                                                    className={`${styles.input} ${styles.textarea} `}
                                                    value={`${formData?.info.classification.relieving_letter_issued_comment || ''}`}
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <span className={styles['span']}>Exit Interview:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <input
                                                    type="text"
                                                    className={styles.input}
                                                    value={formData?.info.final_hr_process.exit_interview ? 'Yes' : 'No'}
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>

                                        <td>
                                            <span className={styles['span']}>Exit Interview Comment:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <textarea
                                                    type="text"
                                                    className={`${styles.input} ${styles.textarea} `}
                                                    value={`${formData?.info.classification.exit_interview_comment || ''}`}
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <span className={styles['span']}>No Dues:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <input
                                                    type="text"
                                                    className={styles.input}
                                                    value={formData?.info.final_hr_process.no_dues ? 'Yes' : 'No'}
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <span className={styles['span']}>Classification:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <input
                                                    type="text"
                                                    className={styles.input}
                                                    value={formData?.info.final_hr_process.classification ? 'Yes' : 'No'}
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>

                                        <td>
                                            <span className={styles['span']}>Classification Comment:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <textarea
                                                    type="text"
                                                    className={`${styles.input} ${styles.textarea} `}
                                                    value={`${formData?.info.classification.classification_comment || ''}`}
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <span className={styles['span']}>Administrative:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <input
                                                    type="text"
                                                    className={styles.input}
                                                    value={formData?.info.final_hr_process.admin ? 'Yes' : 'No'}
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <span className={styles['span']}>Administrative Comment:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <textarea
                                                    type="text"
                                                    className={`${styles.input} ${styles.textarea} `}
                                                    value={`${formData?.info.classification.admin_comment || ''}`}
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>

                                        <td>
                                            <span className={styles['span']}>IT:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <input
                                                    type="text"
                                                    className={styles.input}
                                                    value={formData?.info.final_hr_process.it ? 'Yes' : 'No'}
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <span className={styles['span']}>IT Comment:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <textarea
                                                    type="text"
                                                    className={`${styles.input} ${styles.textarea} `}
                                                    value={`${formData?.info.classification.it_comment || ''}`}
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <span className={styles['span']}>Comment:</span>
                                            <div className={styles.div}>
                                                <label className={styles['P-label']}></label>
                                                <textarea
                                                    type="text"
                                                    className={`${styles.input} ${styles.textarea} `}
                                                    value={`${formData?.info.classification.comment || ''}`}
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                    </tr>



                                </tbody>
                            </table>
                        </div>
                    </div>
                    
                </div>
            </div>
        </>
    );
};

export default OffBoardingPdf;
