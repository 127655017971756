import axios from 'axios';


const OnboadingBlitzData = async (token, employeeId) => {
  
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}api/onboarding/employee-details?Employee_Id=`+employeeId,
            {
            },
            {
                headers: {
                    'token': token,
                    'accept': 'application/json',
                },
            }

        );
        
       return response.data;
    } catch (error) {
        console.error('Error fetching candidate details:', error);
        if (error.response) {
            console.log('Response data:', error.response.data);
        }
        throw error;
    }
};


export default OnboadingBlitzData;