import React, { useState, useEffect } from 'react';
import { useToken } from '../../../context/AuthContext'
import styles from '../MainContent/Main.module.scss';
import InputField from '../../../components/InputField/InputField';
import Button from '../../../components/Button/Button';
import ProgressBar from './ProgressBar';
import DatePicker from 'react-datepicker';
import GetCandidateDetails from '../../../api/fetchCandidateData'
import SaveCandidateData from '../../../api/SaveCandidateData'
import StepEmitter from '../../../components/StepEmitter/StepEmitter'
import ProfessionalEXP from '../Details/ProfesstionalRefrence'
import SelectField from '../../../components/SelectField/SelectField';
import OtherInformation from './OtherInformation';
const Education = ({ step, setStep, isEdit, setIsEdit, handlePreviewClick, apiData }) => {
    const { token, zoho_candidate_id } = useToken();
    // const [educationCount, setEducationCount] = useState(2);
    const initialEducationCount = 3;
    const [showRreview, setshowRreview] = useState(false);
    const [experiData, setexperiData] = useState('');
    const [showProfessionalEXP, setProfessionalEXP] = useState(false);
    const totalSteps = 12;
    const nameRegex = /^[A-Za-z.-]+(\s*[A-Za-z.-]+)*$/;
    const [showOtherInformation, setShowOtherInformation] = useState(false);

    const [educations, setEducations] = useState(Array.from({ length: initialEducationCount }, () => ({
        Qualification: '',
        Course_Name: '',
        Institution_Name: '',
        Institution_Address: '',
        Start_Date: '',
        End_Date: '',
        Percentage: '',
        Specialization: '',
        Event_Name: '',
        Event_Date: '',
        Event_Year: '',
        Represented_Whom: '',
        Awards_Received: '',
        error: false,



    })));
    const [educationCount, setEducationCount] = useState(initialEducationCount);
    useEffect(() => {
        setEducationCount(educations.length);
    }, [educations]);


    const handleBackClick = () => {

        StepEmitter.emit('stepChange', step - 1);


    };

    const educate = [
        { value: '-1', label: 'Select Course' },
        { value: '10th', label: '10th' },
        { value: '12th', label: '12th' },
        { value: 'Graduate', label: 'Graduate' },
        { value: 'PostGraducate', label: 'Post Graducate' },
        { value: 'Course', label: 'Other Course' },
    ];

    const addEducation = () => {
        setEducationCount(prevCount => prevCount + 1);
        setEducations(prevEducations => [
            ...prevEducations,
            {
                Qualification: '',
                Course_Name: '',
                Institution_Name: '',
                Institution_Address: '',
                Start_Date: '',
                End_Date: '',
                Percentage: '',
                Specialization: '',
                Event_Name: '',
                Event_Date: '',
                Event_Year: '',
                Represented_Whom: '',
                Awards_Received: '',
                error: false,
            }
        ]);
    };

    const removeEducation = (index) => {
        setEducationCount(prevCount => prevCount - 1);
        setEducations(prevEducations => prevEducations.filter((_, i) => i !== index));
    };

    const handleInputChange = (index, field, value) => {
        const newEducations = [...educations];
        newEducations[index][field] = value;
        setEducations(newEducations);
    };

    const handleSelectChange = (index, field, value) => {
        const newEducations = [...educations];
        newEducations[index][field] = value;
        setEducations(newEducations);
    };
    const handleDateChange = (date, index, field) => {
        const newEducations = [...educations];
        newEducations[index][field] = date;
        setEducations(newEducations);
    };

    const validateForm = () => {
        let isValid = true;
        const newEducations = [...educations];
        newEducations.forEach((education, index) => {
            if (education.Qualification === 'Course') {
                newEducations[index].error = false;
            } else {
                const skipCourseNameValidation = index === 0 || index === 1;

                if (!skipCourseNameValidation && ((!education.Institution_Name || !education.Institution_Name.trim()) ||
                    (!education.Course_Name || !education.Course_Name.trim()) || !nameRegex.test(education.Course_Name.trim()) || !education.Qualification || education.Qualification === '-1')) {
                    isValid = false;
                    newEducations[index].error = true;
                } else if (index === 0 && (!education.Institution_Name.trim() ||  !education.Start_Date || !education.End_Date)) {
                   
                    isValid = false;
                    newEducations[index].error = true;
                } else if (index === 1 && ( !education.Start_Date || !education.End_Date)) {
                    isValid = false;
                    newEducations[index].error = true;
                } else {
                    newEducations[index].error = false;
                }
            }
        });
        
        setEducations(newEducations);
        return isValid;
    };



    // Function to fetch candidate details from API
    const fetchCandidateDetails = async () => {
        try {
            const details = await GetCandidateDetails(token, zoho_candidate_id, step);
            const apiData = details.data;
            const work = apiData.basic_details.work_experience;
            setexperiData(work);
            if (apiData.candidate_educational_details && apiData.candidate_educational_details.length > 0) {
                let referencesFromApi = apiData.candidate_educational_details;
                setEducationCount(apiData.candidate_educational_details.length);
                setEducations(apiData.candidate_educational_details);

                if (referencesFromApi.length < 3) {
                    referencesFromApi = [
                        ...referencesFromApi,
                        {
                            Qualification: '',
                            Course_Name: '',
                            Institution_Name: '',
                            Institution_Address: '',
                            Start_Date: '',
                            End_Date: '',
                            Percentage: '',
                            Specialization: '',
                            Event_Name: '',
                            Event_Date: '',
                            Event_Year: '',
                            Represented_Whom: '',
                            Awards_Received: '',
                            error: false,
                        }
                    ];
                }
                setEducations(referencesFromApi);
            } else {
                setEducations([
                    {
                        Qualification: '',
                        Course_Name: '',
                        Institution_Name: '',
                        Institution_Address: '',
                        Start_Date: '',
                        End_Date: '',
                        Percentage: '',
                        Specialization: '',
                        Event_Name: '',
                        Event_Date: '',
                        Event_Year: '',
                        Represented_Whom: '',
                        Awards_Received: '',
                        error: false,
                    },
                    {
                        Qualification: '',
                        Course_Name: '',
                        Institution_Name: '',
                        Institution_Address: '',
                        Start_Date: '',
                        End_Date: '',
                        Percentage: '',
                        Specialization: '',
                        Event_Name: '',
                        Event_Date: '',
                        Event_Year: '',
                        Represented_Whom: '',
                        Awards_Received: '',
                        error: false,
                    },
                    {
                        Qualification: '',
                        Course_Name: '',
                        Institution_Name: '',
                        Institution_Address: '',
                        Start_Date: '',
                        End_Date: '',
                        Percentage: '',
                        Specialization: '',
                        Event_Name: '',
                        Event_Date: '',
                        Event_Year: '',
                        Represented_Whom: '',
                        Awards_Received: '',
                        error: false,
                    }
                ]);
            }
        } catch {
            console.error('API has no data');
        }
    };

    useEffect(() => {
        fetchCandidateDetails();
    }, []);

    const formatDate = (dateObject) => {
        const year = dateObject.getFullYear();
        const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
        const day = dateObject.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };


    const handleContinueClick = async () => {
        console.log(validateForm())
        if (validateForm()) {
           
            try {
                const educationsToSave = educations.map((education, index) => {
                    let formattedStart_Date = education.Start_Date;
                    let formattedEnd_Date = education.End_Date;
                    let formattedEvent_Date = education.Event_Date;
                    let Percentage = 0;
                    if (formattedStart_Date) {
                        if (typeof formattedStart_Date === 'string' && formattedStart_Date.match(/^\d{4}-\d{2}-\d{2}$/)) {
                        } else {
                            formattedStart_Date = formatDate(education.Start_Date);
                        }

                        if (typeof formattedEnd_Date === 'string' && formattedEnd_Date.match(/^\d{4}-\d{2}-\d{2}$/)) {
                            // Date is already in the expected format, no need to format it again
                        } else {
                            formattedEnd_Date = formatDate(education.End_Date);
                        }

                    }
                    if (education.Event_Date) {
                        if (typeof education.Event_Date === 'string' && education.Event_Date.match(/^\d{4}-\d{2}-\d{2}$/)) {
                            //formattedEvent_Date = education.Event_Date;
                        } else {
                            formattedEvent_Date = formatDate(education.Event_Date);
                        }
                    }

                    let qualificationValue = education.Qualification;
                    let CourseName = education.Course_Name;
                    if (index === 0) {
                        qualificationValue = '10th';
                        CourseName = '10th';
                    } else if (index === 1) {
                        qualificationValue = '12th';
                        CourseName = '12th';
                    }
                    if (education.Percentage) {
                        Percentage = education.Percentage;
                    } else {
                        Percentage = 0;
                    }

                    return {
                        Qualification: qualificationValue,
                        Course_Name: CourseName,
                        Institution_Name: education.Institution_Name,
                        Institution_Address: education.Institution_Address,
                        Start_Date: formattedStart_Date,
                        End_Date: formattedEnd_Date,
                        Percentage: Percentage,
                        Specialization: education.Specialization,
                        Event_Name: education.Event_Name || '',
                        Event_Date: education.Event_Date || '',
                        Event_Year: '',
                        Represented_Whom: education.Represented_Whom || '',
                        Awards_Received: education.Awards_Received || ''
                    };
                });

                const dataToSave = {
                    candidate_educational_details: educationsToSave,
                    Zoho_Item_Id: zoho_candidate_id,
                    Process_Status: "8"
                };

                const response = await SaveCandidateData(dataToSave);

                if (response.status === 200) {
                    setShowOtherInformation(true);
                   StepEmitter.emit('stepChange', step + 1);
                } else {
                    console.error('Error saving candidate data:', response.statusText);
                }
            } catch (error) {
                console.error('Error saving candidate data:', error);
            }
        }
    };

    const handlePreviewPage = async () => {
        if (validateForm()) {


            try {
                const educationsToSave = educations.map((education, index) => {
                    let formattedStart_Date = education.Start_Date;
                    let formattedEnd_Date = education.End_Date;
                    let formattedEvent_Date = '';
                    if (typeof formattedStart_Date === 'string' && formattedStart_Date.match(/^\d{4}-\d{2}-\d{2}$/)) {
                        // Date is already in the expected format, no need to format it again
                    } else {
                        formattedStart_Date = formatDate(education.Start_Date);
                    }

                    if (typeof formattedEnd_Date === 'string' && formattedEnd_Date.match(/^\d{4}-\d{2}-\d{2}$/)) {
                        // Date is already in the expected format, no need to format it again
                    } else {
                        formattedEnd_Date = formatDate(education.End_Date);
                    }
                    if (education.Event_Date) {
                        if (typeof education.Event_Date === 'string' && education.Event_Date.match(/^\d{4}-\d{2}-\d{2}$/)) {
                            formattedEvent_Date = education.Event_Date;
                        } else {
                            formattedEvent_Date = formatDate(education.Event_Date);
                        }
                    }

                    let qualificationValue = education.Qualification;
                    let CourseName = education.Course_Name;
                    if (index === 0) {
                        qualificationValue = '10th';
                        CourseName = '10th';
                    } else if (index === 1) {
                        qualificationValue = '12th';
                        CourseName = '12th';
                    }


                    return {
                        Qualification: qualificationValue,
                        Course_Name: CourseName,
                        Institution_Name: education.Institution_Name,
                        Institution_Address: education.Institution_Address,
                        Start_Date: formattedStart_Date,
                        End_Date: formattedEnd_Date,
                        Percentage: education.Percentage,
                        Specialization: education.Specialization,
                        Event_Name: education.Event_Name || '',
                        Event_Date: education.Event_Date || '',
                        Event_Year: '',
                        Represented_Whom: education.Represented_Whom || '',
                        Awards_Received: education.Awards_Received || ''
                    };
                });


                const dataToSave = {
                    candidate_educational_details: educationsToSave,
                    Zoho_Item_Id: zoho_candidate_id,
                    Process_Status: "8"
                };
                const response = await SaveCandidateData(dataToSave);


                if (response.status === 200) {
                    setshowRreview(true);

                    setStep(11);
                    StepEmitter.emit('stepChange', 11);
                } else {
                    console.error('Error saving candidate data:', response.statusText);

                }
            } catch (error) {
                console.error('Error saving candidate data:', error);

            }
        }
    };

    const renderEducations = () => {
        return educations.map((education, index) => {
            const disabledOptions = index > 1 ? ["10th", "12th"] : [];
            return (
                <div key={index} className={styles['experience-container']}>
                    <table>
                        <tbody>
                            {education.Qualification !== 'Course' ? (
                                <>
                                    <tr>
                                        <td>
                                            <strong className={styles.strong}>Qualification<i className={styles.red}>*</i>:</strong>
                                            {index === 0 ? (
                                                <InputField
                                                    type="text"
                                                    id={`Qualification_${index}`}
                                                    name={`Qualification_${index}`}
                                                    className={`${styles.input} ${styles.textarea}`}
                                                    mainClass={styles['div-right']}
                                                    value={'10th'}
                                                    disabled
                                                />
                                            ) : (
                                                <SelectField
                                                    id={`Qualification_${index}`}
                                                    name={`Qualification_${index}`}
                                                    options={educate}
                                                    disabledOptions={disabledOptions}
                                                    className={`${styles.select} ${education.error ? styles.error : ''}`}
                                                    value={education.Qualification}
                                                    onChange={(e) => handleSelectChange(index, 'Qualification', e.target.value)}
                                                />
                                            )}
                                        </td>
                                        {index > 1 && (
                                            <td>
                                                <strong className={styles.strong}>Name of the Course<i className={styles.red}>*</i>:</strong>
                                                <InputField
                                                    type="text"
                                                    id={`Course_Name_${index}`}
                                                    name={`Course_Name_${index}`}
                                                    className={styles.input}
                                                    required
                                                    mainClass={styles['div-right']}
                                                    value={education.Course_Name}
                                                    error={(education.error && (!education.Course_Name || !education.Course_Name.trim())) || (education.error && !nameRegex.test(education.Course_Name.trim())) ? "should only contain characters" : ''}
                                                    onChange={(e) => handleInputChange(index, 'Course_Name', e.target.value)}
                                                />
                                            </td>
                                        )}

                                        <td>
                                            <strong className={styles.strong}>Name of the Institution<i className={styles.red}>*</i>:</strong>
                                            <InputField
                                                type="text"
                                                id={`Institution_Name_${index}`}
                                                name={`Institution_Name_${index}`}
                                                className={styles.input}
                                                required
                                                mainClass={styles['div-right']}
                                                value={education.Institution_Name}
                                                error={(education.error && (!education.Institution_Name || !education.Institution_Name.trim())) || (education.error && !nameRegex.test(education.Institution_Name.trim())) ? "should only contain characters" : ''}
                                                onChange={(e) => handleInputChange(index, 'Institution_Name', e.target.value)}
                                            />
                                        </td>
                                        <td>
                                            <strong className={styles.strong}>Address of Institution:</strong>
                                            <div className="div-right">
                                                <textarea
                                                    id={`Institution_Address_${index}`}
                                                    name={`Institution_Address_${index}`}
                                                    className={`${styles.input} ${styles.textarea}`}
                                                    value={education.Institution_Address}
                                                    onChange={(e) => handleInputChange(index, 'Institution_Address', e.target.value)}
                                                ></textarea>
                                            </div>
                                        </td>
                                        {index <= 1 ? (
                                            <td>
                                                <strong className={styles.strong}>Subjects/Specialization:</strong>
                                                <div className="div-right">
                                                    <textarea
                                                        id={`Specialization_${index}`}
                                                        name={`Specialization_${index}`}
                                                        className={`${styles.input} ${styles.textarea}`}
                                                        required
                                                        value={education.Specialization}
                                                        onChange={(e) => handleInputChange(index, 'Specialization', e.target.value)}
                                                    ></textarea>
                                                </div>
                                            </td>
                                        ) : null}
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong className={styles.strong}>Period From:<i className={styles.red}>*</i></strong>
                                            <div className={styles['div-right']}>
                                                <div className={styles['row-c']}>
                                                    <DatePicker
                                                        selected={education.Start_Date}
                                                        onChange={(date) => handleDateChange(date, index, 'Start_Date')}
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        className={`${education.error && !education.Start_Date ? styles.error : ''} ${styles.input} ${styles.dateTimePicker}`}
                                                        id={`Start_Date_${index}`}
                                                        name={`Start_Date_${index}`}
                                                        autoComplete="off"
                                                        required
                                                        placeholderText="From"
                                                    />
                                                    <div className={styles.clear}></div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <strong className={styles.strong}>To:<i className={styles.red}>*</i></strong>
                                            <DatePicker
                                                selected={education.End_Date}
                                                onChange={(date) => handleDateChange(date, index, 'End_Date')}
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                className={`${education.error && !education.End_Date ? styles.error : ''} ${styles.input} ${styles.dateTimePicker}`}
                                                id={`End_Date_${index}`}
                                                name={`End_Date_${index}`}
                                                autoComplete="off"
                                                required
                                                placeholderText="To"
                                            />
                                        </td>
                                        <td>
                                            <strong className={styles.strong}>Percentage:</strong>
                                            <InputField
                                                type="number"
                                                id={`Percentage_${index}`}
                                                name={`Percentage_${index}`}
                                                className={`${styles.input} ${education.error && (parseFloat(education.Percentage) < 0) ? styles.error : ''}`}
                                                required
                                                mainClass={styles['div-right']}
                                                value={education.Percentage}
                                                onChange={(e) => handleInputChange(index, 'Percentage', e.target.value)}
                                                error={education.error && (parseFloat(education.Percentage) < 0) ? 'Percentage must be a non-negative number' : ''}
                                            />
                                        </td>
                                        {index > 1 ? (
                                            <td>
                                                <strong className={styles.strong}>Subjects/Specialization:</strong>
                                                <div className="div-right">
                                                    <textarea
                                                        id={`Specialization_${index}`}
                                                        name={`Specialization_${index}`}
                                                        className={`${styles.input} ${styles.textarea}`}
                                                        required
                                                        value={education.Specialization}
                                                        onChange={(e) => handleInputChange(index, 'Specialization', e.target.value)}
                                                    ></textarea>
                                                </div>
                                            </td>
                                        ) : null}
                                    </tr>
                                </>
                            ) : (
                                <tr>
                                    <td>
                                        <strong className={styles.strong}>Qualification<i className={styles.red}>*</i>:</strong>
                                        <SelectField
                                            id={`Qualification_${index}`}
                                            name={`Qualification_${index}`}
                                            options={educate}
                                            disabledOptions={disabledOptions}
                                            className={`${styles.select}`}
                                            value={education.Qualification}
                                            onChange={(e) => handleSelectChange(index, 'Qualification', e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <strong className={styles.strong}>Event:</strong>
                                        <InputField
                                            type="text"
                                            id={`Event_Name_${index}`}
                                            name={`Event_Name_${index}`}
                                            className={styles.input}
                                            required
                                            mainClass={styles['div-right']}
                                            value={education.Event_Name}
                                            onChange={(e) => handleInputChange(index, 'Event_Name', e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <strong className={styles.strong}>Date:</strong>
                                        <DatePicker
                                            selected={education.Event_Date}
                                            onChange={(date) => handleDateChange(date, index, 'Event_Date')}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            className={` ${styles.input} ${styles.dateTimePicker}`}
                                            id={`Event_Date_${index}`}
                                            name={`Event_Date_${index}`}
                                            autoComplete="off"
                                            required
                                            placeholderText="From"
                                        />
                                    </td>
                                    <td>
                                        <strong className={styles.strong}>Represented Whom:</strong>
                                        <InputField
                                            type="text"
                                            id={`Represented_Whom_${index}`}
                                            name={`Represented_Whom_${index}`}
                                            className={styles.input}
                                            required
                                            mainClass={styles['div-right']}
                                            value={education.Represented_Whom}
                                            onChange={(e) => handleInputChange(index, 'Represented_Whom', e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <strong className={styles.strong}>Awards Received, if any:</strong>
                                        <div className={styles['div-right']}>
                                            <textarea
                                                id={`Awards_Received_${index}`}
                                                name={`Awards_Received_${index}`}
                                                className={`${styles.input} ${styles.textarea}`}
                                                value={education.Awards_Received}
                                                onChange={(e) => handleInputChange(index, 'Awards_Received', e.target.value)}
                                            ></textarea>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    {index >= 3 && (
                        <div className={styles['align-center']}>
                            <input type="button" value="Remove" className={styles.remove} onClick={() => removeEducation(index)} />
                        </div>
                    )}
                </div>
            );
        });
    };


    return (
        <>
            {step === 8 && (
                <>
                    <ProgressBar currentStep={step} totalSteps={totalSteps} />
                    <div className={styles['required-details']}>
                        Kindly fill the required details <span>*</span> of the form below.
                    </div>
                    <h4 className={styles.heading1} style={{ display: step === 8 ? 'block' : 'none' }}>
                        <span>Education/Awards</span>
                    </h4>

                    <div className={`${styles['row-c']} ${styles.width} ${styles.educationalPROFESS}`} style={{ display: step === 8 ? 'block' : 'none' }}>
                        <form id="educationalPROFESS" name="educationalPROFESS">
                            <div className={styles.educational}>


                                {renderEducations()}



                                <div className={styles['align-center']}>
                                    <input type
                                        ="button" value="Add" className={styles.BTNadd2} onClick={addEducation} />
                                </div>
                            </div>
                        </form>
                        <Button
                            backButtonLabel="Back"
                            continueButtonLabel="Save & Next"
                            onBackClick={handleBackClick}
                            onContinueClick={handleContinueClick}
                            backButtonClass={styles['custom-back-class']}
                            continueButtonClass={styles['custom-continue-class']}
                            previewButtonLabel={isEdit ? "Preview" : ""}
                            previewButtonAction={isEdit ? handlePreviewPage : ''}
                        />

                    </div>

                </>
            )}
            {showOtherInformation && <OtherInformation step={step} setStep={setStep} isEdit={isEdit} setIsEdit={setIsEdit} handlePreviewClick={handlePreviewClick} apiData={apiData} />}
        </>
    );
}

export default Education;
