import React from 'react';
import styles from '../../applicantContainers/Candidate/MainContent/Main.module.scss';

const Button = ({ backButtonLabel, continueButtonLabel, onBackClick, onContinueClick, backButtonClass, continueButtonClass, previewButtonLabel, previewButtonAction, disabled }) => {
  return (
    <ol className={`${styles.btnContent} ${styles.btnContent2}`}>
      <li>
        {backButtonLabel && (
          <a
            href='#'
            className={`${styles.back} ${styles.btnBACK} ${backButtonClass}`}
            onClick={onBackClick}
            aria-disabled={disabled} // For accessibility
            style={{ pointerEvents: disabled ? 'none' : 'auto', opacity: disabled ? 0.5 : 1 }} // To visually disable the button
          >
            {backButtonLabel}
          </a>
        )}
        {continueButtonLabel && (
          <a
            href='#'
            className={`${styles.btnContinue} ${styles.btnNEXT2} ${continueButtonClass}`}
            onClick={onContinueClick}
            aria-disabled={disabled} // For accessibility
            style={{ pointerEvents: disabled ? 'none' : 'auto', opacity: disabled ? 0.5 : 1 }} // To visually disable the button
          >
            {continueButtonLabel}
          </a>
        )}
        {previewButtonLabel && (
          <a
            href='#'
            className={`${styles.back} ${styles.btnBACK} ${backButtonClass}`}
            onClick={previewButtonAction}
            aria-disabled={disabled} // For accessibility
            style={{ pointerEvents: disabled ? 'none' : 'auto', opacity: disabled ? 0.5 : 1 }} // To visually disable the button
          >
            {previewButtonLabel}
          </a>
        )}
      </li>
    </ol>
  );
};

export default Button;
