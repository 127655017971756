import React, { useState, useEffect } from 'react';
import styles from '../Boading.module.scss';
import EmployeeInfo from '../EmployeeInfo/EmployeeInfo';
import OnboadingDetails from '../../api/FetchOnboaingDetail';
import OnboadingSaveEmployeeDetails from '../../api/SaveOnboadingData';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const IT = ({ department, isActive, authorized, token, employeeId, setEmployeeId, admin, disableTab, onSubmit, activeTabIndex }) => {
    const [loading, setLoading] = useState(true);
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [trueOrNonEmptyFields, setTrueOrNonEmptyFields] = useState([]);

    const [formData, setFormData] = useState({
        //specialSoftwareNeeds: '',
        additional_access: false,
        additional_access_name: '',
        ship_laptop: false,
        other_location: '',
        network_calling: false,
        whichCountry: false,
       // businessCardRequired: '',
        special_software_needs: false,
        special_software_name: '',
        business_card_required: false,
        security_code: false,
        security_info: false,
        shipment_information: '',
        security_information_sent: false,
        security_code: false,
        country: '',
        comment: ''
    });
    const [pc_equipment, setPCEquipment] = useState({
        headphone: false,
        monitor: false,
        mouse: false,
        keyboard: false
    });
    const [softwareAccess, setSoftwareAccess] = useState({
        office365: false,
        Jira: false,
        dropbox: false,
        git: false,
        quickSight: false,
        power: false,
        CRM: false,
        adobe: false,
        winrar: false
    });
    const [mobileAccess, setmobileAccess] = useState({
        teams: false,
        jira: false,
        outlook: false
    });
    
    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: checked
        }));
    };

    const handlepcCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setPCEquipment(prevState => ({
            ...prevState,
            [name]: checked
        }));
    };
    const handleswCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setSoftwareAccess(prevState => ({
            ...prevState,
            [name]: checked
        }));
    };

    const handlemobileCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setmobileAccess(prevState => ({
            ...prevState,
            [name]: checked
        }));
    };
   
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    useEffect(() => {
        const url = 'get-employee-it-information';
        if (authorized) {
            const fetchData = async () => {
                try {
                    setLoading(true);
                    const employeeData = await OnboadingDetails(url, token, employeeId);
                    if (employeeData.status === 200) {
                        const empDetails = employeeData.it;
                        const trueFields = Object.keys(empDetails).filter(key => empDetails[key] === true);
                        const getTrueOrNonEmptyFields = (obj) => {
                            const trueOrNonEmptyFields = [];
                            for (const key in obj) {
                                if (obj.hasOwnProperty(key)) {
                                    const value = obj[key];
                                    if (value === true || (typeof value === 'string' && value.trim() !== '')) {
                                        trueOrNonEmptyFields.push(key);
                                    } else if (typeof value === 'object' && value !== null) {
                                        const nestedTrueOrNonEmptyFields = getTrueOrNonEmptyFields(value);
                                        trueOrNonEmptyFields.push(...nestedTrueOrNonEmptyFields.map(field => key + '.' + field));
                                    }
                                }
                            }
                            return trueOrNonEmptyFields;
                        };

                        let trueOrNonEmptyFields = getTrueOrNonEmptyFields(empDetails);

                        const fieldsToRemove = ["created_by", "created_at", "status", "update_first", "created_date"];
                        trueOrNonEmptyFields = trueOrNonEmptyFields.filter(field => !fieldsToRemove.includes(field));

                        


                        const initialFormData = {
                            //pcEquipment: empDetails.pcEquipment || '',
                            // softwareAccess: empDetails.softwareAccess || '',
                            // mobile_access: empDetails.mobile_access || '',
                            special_software_needs: empDetails.special_software_needs || '',
                            special_software_name: empDetails.special_software_name || '',
                            ship_laptop: empDetails.ship_laptop || '',
                            other_location: empDetails.other_location || '',
                            network_calling: empDetails.network_calling || '',
                            country: empDetails.country || '',
                            business_card_required: empDetails.business_card_required || '',
                            security_code: empDetails.security_code || false,
                            security_info: empDetails.security_info || false,
                            comment: empDetails.comment || '',
                            additional_access: empDetails.additional_access || '',
                            additional_access_name: empDetails.additional_access_name || '',
                            shipment_information: empDetails.shipment_information || '',
                            security_information_sent: empDetails.security_information_sent || false,
                            update_first: empDetails.update_first || ''
                        }
                        const validationState = {
                            pc_equipment: !!empDetails.pc_equipment,
                            softwareAccess: !!empDetails.softwareAccess,
                            mobile_access: !!empDetails.mobile_access,
                            special_software_needs: !!empDetails.special_software_needs,
                            special_software_name: !!empDetails.special_software_name,
                            ship_laptop: !!empDetails.ship_laptop,
                            other_location: !!empDetails.other_location,
                            network_calling: !!empDetails.network_calling,
                            country: !!empDetails.country,
                            business_card_required: !!empDetails.business_card_required,
                            security_code: !!empDetails.security_code,
                            security_info: !!empDetails.security_info,
                            comment: !!empDetails.comment,
                            additional_access: !!empDetails.additional_access,
                            additional_access_name: !!empDetails.additional_access_name,
                            shipment_information: !!empDetails.shipment_information,
                            security_information_sent: !!empDetails.security_information_sent
                        };

                        if (initialFormData.update_first === 'First') {
                            if (initialFormData.security_information_sent === true) {
                                initialFormData.security_information_sent = false;
                            }
                            else {
                                initialFormData.security_information_sent = '';
                            }
                            if (initialFormData.security_info === true) {
                                initialFormData.security_info = false;
                            }
                            else {
                                initialFormData.security_info = '';
                            }
                            if (initialFormData.security_code === true) {
                                initialFormData.security_code = false;
                            }
                            else {
                                initialFormData.security_code = '';
                            }
                            if (initialFormData.business_card_required === true) {
                                initialFormData.business_card_required = false;
                            }
                            else {
                                initialFormData.business_card_required = '';
                            }
                            if (initialFormData.network_calling === true) {
                                initialFormData.network_calling = false;
                            }
                            else {
                                initialFormData.network_calling = '';
                            }
                            if (initialFormData.ship_laptop === true) {
                                initialFormData.ship_laptop = false;
                            }
                            else {
                                initialFormData.ship_laptop = '';
                            }
                            if (initialFormData.additional_access === true) {
                                initialFormData.additional_access = false;
                            }
                            else {
                                initialFormData.additional_access = '';
                            }
                            if (initialFormData.special_software_needs === true) {
                                initialFormData.special_software_needs = false;
                            }
                            else {
                                initialFormData.special_software_needs = '';
                            }





                        }

                        const pc_equipment = empDetails.pc_equipment;
                        if (pc_equipment) {
                            if (initialFormData.update_first === 'First') {

                                setPCEquipment({
                                    headphone: pc_equipment.headphone ? false : '',
                                    monitor: pc_equipment.monitor ? false : '',
                                    mouse: pc_equipment.mouse ? false : '',
                                    keyboard: pc_equipment.keyboard ? false : ''
                                });
                            }
                            else if (initialFormData.update_first === 'Save') {

                                setPCEquipment({
                                    headphone: pc_equipment.headphone ? true : '',
                                    monitor: pc_equipment.monitor ? true : '',
                                    mouse: pc_equipment.mouse ? true : '',
                                    keyboard: pc_equipment.keyboard ? true : ''
                                });
                            }
                            else {
                                setPCEquipment({
                                    headphone: pc_equipment.headphone || false,
                                    monitor: pc_equipment.monitor || false,
                                    mouse: pc_equipment.mouse || false,
                                    keyboard: pc_equipment.keyboard || false
                                });
                            }
                        }

                        const mobileAccess = empDetails.mobile_access;
                        if (mobileAccess) {
                            if (initialFormData.update_first === 'First') {
                                setmobileAccess({
                                    teams: mobileAccess.teams ? false : '',
                                    outlook: mobileAccess.outlook ? false : '',
                                    jira: mobileAccess.jira ? false : ''

                                });
                            }
                            else if (initialFormData.update_first === 'Save') {
                                setmobileAccess({
                                    teams: mobileAccess.teams ? true : '',
                                    outlook: mobileAccess.outlook ? true : '',
                                    jira: mobileAccess.jira ? true : ''

                                });
                            }
                            else {
                                setmobileAccess({
                                    teams: mobileAccess.teams || false,
                                    outlook: mobileAccess.outlook || false,
                                    jira: mobileAccess.jira || false

                                });
                            }
                        }
                        const softwareAccess = empDetails.software_access;
                        if (softwareAccess) {
                            if (initialFormData.update_first === 'First') {
                                setSoftwareAccess({
                                    office365: softwareAccess.office365 ? false : '',
                                    Jira: softwareAccess.Jira ? false : '',
                                    dropbox: softwareAccess.dropbox ? false : '',
                                    git: softwareAccess.git ? false : '',
                                    quickSight: softwareAccess.quickSight ? false : '',
                                    power: softwareAccess.power ? false : '',
                                    CRM: softwareAccess.CRM ? false : '',
                                    winrar: softwareAccess.winrar ? false : '',
                                    adobe: softwareAccess.adobe ? false : ''
                                });
                            }
                            else if (initialFormData.update_first === 'Save') {
                                setSoftwareAccess({
                                    office365: softwareAccess.office365 ? true : '',
                                    Jira: softwareAccess.Jira ? true : '',
                                    dropbox: softwareAccess.dropbox ? true : '',
                                    git: softwareAccess.git ? true : '',
                                    quickSight: softwareAccess.quickSight ? true : '',
                                    power: softwareAccess.power ? true : '',
                                    CRM: softwareAccess.CRM ? true : '',
                                    winrar: softwareAccess.winrar ? true : '',
                                    adobe: softwareAccess.adobe ? true : ''
                                });
                            }
                            else {
                                setSoftwareAccess({
                                    office365: softwareAccess.office365 || false,
                                    Jira: softwareAccess.Jira || false,
                                    dropbox: softwareAccess.dropbox || false,
                                    git: softwareAccess.git || false,
                                    quickSight: softwareAccess.quickSight || false,
                                    power: softwareAccess.power || false,
                                    CRM: softwareAccess.CRM || false,
                                    winrar: softwareAccess.winrar || false,
                                    adobe: softwareAccess.adobe || false 
                                });
                            }
                        }
                        
                        setFormData(initialFormData);
                        setTrueOrNonEmptyFields(trueOrNonEmptyFields);
                        if (empDetails.status === 'Submitted') {
                            setButtonsDisabled(true);
                        }

                        setLoading(false);

                    } else {
                        console.error('Error fetching employee data:', employeeData.error);
                    }
                } catch (error) {
                    console.error('Error fetching employee data:', error);
                } finally {
                    setLoading(false);
                }
            };

            fetchData();
        }
    }, [authorized, token, employeeId]);
    console.log(pc_equipment)
    const validateNestedFields = (fields, obj) => {
        for (const key of fields) {
            const keys = key.split('.');
            let value = obj;
            for (const k of keys) {
                value = value[k];
                if (value === undefined) break;
            }
            if (value === undefined || value === '' || value === false) {
                
                return false;
            }
        }
        return true;
    };

    const validateFields = (fields, obj) => {
        // Extract nested fields
        const nestedFields = fields.filter(field => field.includes('.'));

        // Extract non-nested fields
        const nonNestedFields = fields.filter(field => !field.includes('.'));

        // Validate non-nested fields
        for (const key of nonNestedFields) {
            if (obj[key] === undefined || obj[key] === '' || obj[key] === false) {
                console.log(`Validation failed for field: ${key}, value: ${obj[key]}`); // Log the field and its value
                return false;
            }
        }

        // Validate nested fields
        if (!validateNestedFields(nestedFields, obj)) {
            return false;
        }

        return true;
    };

    const hanldeSaveInfo = async (event) => {
        event.preventDefault();
        if (!formData.comment) {
            toast.error('Please Provide Comment!');
            return;
        }
        try {
            setLoading(true);
            const url = 'save-employee-it-information';

            const requestData = {
                employee_id: employeeId,
                it: {
                    ...formData,
                    network_calling: formData.network_calling === '' ? false : formData.network_calling,
                    security_information_sent: formData.security_information_sent === '' ? false : formData.security_information_sent,
                    security_info: formData.security_info === '' ? false : formData.security_info,
                    security_code: formData.security_code === '' ? false : formData.security_code,
                    business_card_required: formData.business_card_required === '' ? false : formData.business_card_required,
                    additional_access: formData.additional_access === '' ? false : formData.additional_access,
                    special_software_needs: formData.special_software_needs === '' ? false : formData.special_software_needs,
                    ship_laptop: formData.ship_laptop === '' ? false : formData.ship_laptop,
                    pc_equipment: {
                        headphone: pc_equipment.headphone,
                        monitor: pc_equipment.monitor,
                        mouse: pc_equipment.mouse,
                        keyboard: pc_equipment.keyboard
                    },
                    software_access: {
                        office365: softwareAccess.office365,
                        Jira: softwareAccess.Jira,
                        dropbox: softwareAccess.dropbox,
                        git: softwareAccess.git,
                        quickSight: softwareAccess.quickSight,
                        power: softwareAccess.power,
                        CRM: softwareAccess.CRM,
                        adobe: softwareAccess.adobe,
                        winrar: softwareAccess.winrar
                    },
                    mobile_access: {
                        teams: mobileAccess.teams,
                        jira: mobileAccess.jira,
                        outlook: mobileAccess.outlook
                    },
                    
                    update_first: "Save",
                    status: "Pending",
                    created_by: admin,
                }
            };

            const fieldsToValidate = trueOrNonEmptyFields.filter(field =>
                !['created_by', 'created_at', 'status', 'update_first', 'created_date'].includes(field)
            );

            if (!validateFields(fieldsToValidate, requestData.it)) {
                toast.error('Please fill out all required fields.');
                setLoading(false);
                return;
            }

            const response = await OnboadingSaveEmployeeDetails(url, token, employeeId, requestData);
            if(!response.form_submitted){
             toast.error(response.msg);
            }
            else{
                toast.success(response.msg);
            }
        } catch (error) {
            console.error('Error saving form data:', error);
        } finally {
            setLoading(false);
        }
    }



    const hanldeSubmit = async (event) => {
        event.preventDefault();
        if (!formData.comment) {
            toast.error('Please Provide Comment!');
            return;
        }
        try {
            setLoading(true);
            const url = 'save-employee-it-information';
            const requestData = {
                employee_id: employeeId,
                it: {

                    ...formData,
                    network_calling: formData.network_calling === '' ? false : formData.network_calling,
                    security_information_sent: formData.security_information_sent === '' ? false : formData.security_information_sent,
                    security_info: formData.security_info === '' ? false : formData.security_info,
                    security_code: formData.security_code === '' ? false : formData.security_code,
                    business_card_required: formData.business_card_required === '' ? false : formData.business_card_required,
                    additional_access: formData.additional_access === '' ? false : formData.additional_access,
                    special_software_needs: formData.special_software_needs === '' ? false : formData.special_software_needs,
                    ship_laptop: formData.ship_laptop === '' ? false : formData.ship_laptop,
                    pc_equipment: {
                        headphone: pc_equipment.headphone,
                        monitor: pc_equipment.monitor,
                        mouse: pc_equipment.mouse,
                        keyboard: pc_equipment.keyboard
                    },
                    software_access: {
                        office365: softwareAccess.office365,
                        Jira: softwareAccess.Jira,
                        dropbox: softwareAccess.dropbox,
                        git: softwareAccess.git,
                        quickSight: softwareAccess.quickSight,
                        power: softwareAccess.power,
                        CRM: softwareAccess.CRM,
                        adobe: softwareAccess.adobe,
                        winrar: softwareAccess.winrar
                    },
                    mobile_access: {
                        teams: mobileAccess.teams,
                        jira: mobileAccess.jira,
                        outlook: mobileAccess.outlook
                    },
                    

                    update_first: "Submitted",
                    status: "Submitted",
                    created_by: admin,
                }
            };

            const response = await OnboadingSaveEmployeeDetails(url, token, employeeId, requestData);
            if(!response.form_submitted){
                toast.error(response.msg);
               }
               else{
                   toast.success(response.msg);
                   setButtonsDisabled(true);
               }
        } catch (error) {
            console.error('Error saving form data:', error);
        } finally {
           
            setLoading(false);
        }
    }
 
    return (
        <div className={styles.employee}>
            <div className={styles.grid}>
                <div className={styles['edit-bg']}>

                </div>

                <EmployeeInfo token={token} employeeId={employeeId} />

                <div className={styles.col9}>
                    <div className={`${styles['box-shadow']} ${styles['border-radius']} `}>
                        <fieldset className={styles.fieldset}>
                            <legend>Information Technology</legend>
                            <div className={styles.grid}>
                                <div className={styles.col6}>
                                    <div className={styles['right-space']}>
                                        <ul className={styles['two-col']}>
                                            {((pc_equipment.headphone !== '') || (pc_equipment.monitor !== '') || (pc_equipment.mouse !== '') || (pc_equipment.keyboard !== '')) && (
                                                <li>
                                                    <strong>PC Equipment<i className={styles.red}>*</i>:</strong>
                                                    <span>
                                                        {pc_equipment.headphone !== '' &&
                                                            <>
                                                                <input type="checkbox" id="headphone" name="headphone" value="Headphone" checked={pc_equipment.headphone} onChange={handlepcCheckboxChange} />
                                                                <label htmlFor="headphone">Headphone</label>
                                                            </>
                                                        }
                                                        {pc_equipment.monitor !== '' &&
                                                            <>
                                                                <input type="checkbox" id="monitor" name="monitor" value="Monitor" checked={pc_equipment.monitor} onChange={handlepcCheckboxChange} />
                                                                <label htmlFor="monitor">Monitor</label>
                                                            </>
                                                        }
                                                        {pc_equipment.mouse !== '' &&
                                                            <>
                                                                <input type="checkbox" id="mouse" name="mouse" value="Mouse" checked={pc_equipment.mouse} onChange={handlepcCheckboxChange} />
                                                                <label htmlFor="mouse">Mouse</label>
                                                            </>
                                                        }
                                                        {pc_equipment.keyboard !== '' &&
                                                            <>
                                                                <input type="checkbox" id="keyboard" name="keyboard" value="Keyboard" checked={pc_equipment.keyboard} onChange={handlepcCheckboxChange} />
                                                                <label htmlFor="keyboard">Keyboard</label>
                                                            </>
                                                        }
                                                    </span>
                                                </li>
                                            )}
                                            {(softwareAccess.office365 !== '' || softwareAccess.Jira !== '' || softwareAccess.dropbox !== '' || softwareAccess.git !== '' || softwareAccess.quickSight !== '' || softwareAccess.power !== '' || softwareAccess.CRM !== '') && (
                                                <li>
                                                    <strong>Software Access<i className={styles.red}>*</i>:</strong>
                                                    <span>
                                                        {softwareAccess.office365 !== '' &&
                                                            <>
                                                                <input type="checkbox" id="Office" name="office365" value="Office 365" checked={softwareAccess.office365} onChange={handleswCheckboxChange} />
                                                                <label htmlFor="Office">Office 365</label>
                                                            </>
                                                        }
                                                        {softwareAccess.Jira !== '' &&
                                                            <>
                                                                <input type="checkbox" id="Jira" name="Jira" value="Jira" checked={softwareAccess.Jira} onChange={handleswCheckboxChange} />
                                                                <label htmlFor="Jira">Jira</label>
                                                            </>
                                                        }
                                                        {softwareAccess.dropbox !== '' &&
                                                            <>
                                                                <input type="checkbox" id="Dropbox" name="dropbox" value="Dropbox" checked={softwareAccess.dropbox} onChange={handleswCheckboxChange} />
                                                                <label htmlFor="Dropbox">Dropbox</label>
                                                            </>
                                                        }
                                                        {softwareAccess.git !== '' &&
                                                            <>
                                                                <input type="checkbox" id="Git" name="git" value="Git" checked={softwareAccess.git} onChange={handleswCheckboxChange} />
                                                                <label htmlFor="Git">Git</label>
                                                            </>
                                                        }
                                                        {softwareAccess.quickSight !== '' &&
                                                            <>
                                                                <input type="checkbox" id="QuickSight" name="quickSight" value="QuickSight (Author or Reader)" checked={softwareAccess.quickSight} onChange={handleswCheckboxChange} />
                                                                <label htmlFor="QuickSight">QuickSight (Author or Reader)</label>
                                                            </>
                                                        }
                                                        {softwareAccess.power !== '' &&
                                                            <>
                                                                <input type="checkbox" id="PowerBI" name="power" value="Power BI" checked={softwareAccess.power} onChange={handleswCheckboxChange} />
                                                                <label htmlFor="PowerBI">Power BI</label>
                                                            </>
                                                        }
                                                        {softwareAccess.CRM !== '' &&
                                                            <>
                                                                <input type="checkbox" id="CRM" name="CRM" value="CRM" checked={softwareAccess.CRM} onChange={handleswCheckboxChange} />
                                                                <label htmlFor="CRM">CRM</label>
                                                            </>
                                                        }
                                                        
                                                        {softwareAccess.adobe !== '' &&
                                                            <>
                                                                <input type="checkbox" id="Adobe" name="adobe" checked={softwareAccess.adobe} onChange={handleswCheckboxChange} />
                                                                <label htmlFor="Adobe">Adobe</label>
                                                            </>
                                                        }
                                                        {softwareAccess.winrar !== '' &&
                                                            <>
                                                                <input type="checkbox" id="WinRar" name="winrar" checked={softwareAccess.winrar} onChange={handleswCheckboxChange} />
                                                                <label htmlFor="WinRar">WinRar</label>
                                                            </>
                                                        }
                                                    </span>
                                                </li>
                                            
                                                    
                                            )}

                                            {(mobileAccess.teams !== '' || mobileAccess.outlook !== '' || mobileAccess.jira !== '') && (
                                                <li>
                                                    <strong>Mobile Access<i className={styles.red}>*</i>:</strong>
                                                    <span>
                                                        {mobileAccess.teams !== '' &&
                                                            <>
                                                                <input type="checkbox" id="MS_Teams" name="teams" value="MS Teams" checked={mobileAccess.teams} onChange={handlemobileCheckboxChange} />
                                                                <label htmlFor="MS_Teams">MS Teams</label>
                                                            </>
                                                        }
                                                        {mobileAccess.outlook !== '' &&
                                                            <>
                                                                <input type="checkbox" id="Outlook" name="outlook" value="Outlook" checked={mobileAccess.outlook} onChange={handlemobileCheckboxChange} />
                                                                <label htmlFor="Outlook">Outlook</label>
                                                            </>
                                                        }
                                                        {mobileAccess.jira !== '' &&
                                                            <>
                                                                <input type="checkbox" id="Jira_Mobile" name="jira" value="Jira" checked={mobileAccess.jira} onChange={handlemobileCheckboxChange} />
                                                                <label htmlFor="Jira_Mobile">Jira</label>
                                                            </>
                                                        }
                                                    </span>
                                                </li>
                                            )}
                                           

                                        </ul>
                                    </div>
                                </div>

                                <div className={styles.col6}>
                                    <div className={styles['left-space']}>
                                        <ul className={styles['two-col']}>
                                            {formData.special_software_needs !== '' && (
                                                <li><strong>Special Software Needs<i className={styles.red}>*</i>:</strong> <span>
                                                    <input
                                                        type="checkbox"
                                                        id="special_software_need"
                                                        name="special_software_needs"
                                                        onChange={handleCheckboxChange}
                                                        checked={formData.special_software_needs}

                                                    />
                                                    <label htmlFor="special_software_need"></label>
                                                    <input
                                                        type="text"
                                                        className={styles.input}
                                                        name="special_software_name"
                                                        value={formData.special_software_name}
                                                        onChange={handleChange}
                                                    /></span>
                                                </li>
                                            )}
                                            {formData.additional_access !== '' && (
                                                <li>

                                                    <strong>Additional Access<i className={styles.red}>*</i>:</strong> <span>
                                                        <input
                                                            type="checkbox"
                                                            id="additional_access"
                                                            name="additional_access"
                                                            onChange={handleCheckboxChange}
                                                            checked={formData.additional_access}

                                                        />
                                                        <label htmlFor="additional_access"></label>
                                                        <input type="text" className={styles.input} name="additional_access_name" value={formData.additional_access_name} onChange={handleChange} /></span></li>
                                            )}
                                            
                                            {formData.ship_laptop !== '' && (
                                                <li>
                                                    <strong>Shipped Laptop to Address on File <i className={styles.red}>*</i>:</strong>
                                                    <span>

                                                        <input
                                                            type="checkbox"
                                                            id="ship_laptop"
                                                            name="ship_laptop"
                                                            checked={formData.ship_laptop}
                                                            onChange={handleCheckboxChange}
                                                        />
                                                        <label htmlFor="ship_laptop"></label>
                                                    </span>
                                                </li>
                                            )}
                                            <li><strong>Shipment information:</strong> <span><textarea className={styles.input} name="shipment_information" value={formData.shipment_information} onChange={handleChange}></textarea></span></li>
                                            {formData.other_location !== undefined && formData.other_location !== '' && (
                                                <li>
                                                    <strong>Other Location<i className={styles.red}>*</i>:</strong>
                                                    <span>
                                                        <textarea
                                                            className={styles.input}
                                                            name="other_location"
                                                            value={formData.other_location}
                                                            onChange={handleChange}
                                                        ></textarea>
                                                    </span>
                                                </li>
                                            )}
                                            {formData.network_calling !== '' && (
                                                <li>
                                                    <strong>Network Calling<i className={styles.red}>*</i>:</strong>
                                                    <span className={styles.calling}>

                                                        <input
                                                            type="checkbox"
                                                            id="network_calling"
                                                            name="network_calling"
                                                            checked={formData.network_calling}
                                                            onChange={handleCheckboxChange}
                                                        />

                                                        {formData.network_calling !== '' && <label htmlFor="network_calling">Toggle</label>}
                                                    </span>
                                                </li>
                                            )}
                                            {formData.country !== undefined && formData.country !== '' && (
                                                <li>
                                                    <strong>Which Country <i className={styles.red}>*</i>:</strong>
                                                    <span>
                                                        <input
                                                            type="text"
                                                            className={styles.input}
                                                            name="country"
                                                            value={formData.country}
                                                            onChange={handleChange}
                                                        />
                                                    </span>
                                                </li>
                                            )}
                                            {formData.business_card_required !== '' && (
                                                <li><strong>Business Card Required<i className={styles.red}>*</i>:</strong> <span>

                                                    <input
                                                        type="checkbox"
                                                        id="business_card_required"
                                                        name="business_card_required"
                                                        checked={formData.business_card_required}
                                                        onChange={handleCheckboxChange}
                                                    />

                                                    {formData.business_card_required !== '' && <label htmlFor="business_card_required"></label>}
                                                </span></li>
                                            )}

                                            <li><strong>Security Code:</strong>
                                                <span>

                                                    <input
                                                        type="checkbox"
                                                        id="security_code"
                                                        name="security_code"
                                                        checked={formData.security_code}
                                                        onChange={handleCheckboxChange}
                                                    />

                                                    <label htmlFor="security_code"></label>
                                                </span></li>


                                            <li><strong>Security Information Sent:</strong> <span>

                                                <input
                                                    type="checkbox"
                                                    id="security_information_sent"
                                                    name="security_information_sent"
                                                    checked={formData.security_information_sent}
                                                    onChange={handleCheckboxChange}
                                                />

                                                <label htmlFor="security_information_sent"></label>
                                            </span></li>


                                        </ul>
                                    </div>
                                </div>
                                <div className={styles['right-space']}>
                                    <ul className={styles['two-col']}>
                                        <li><strong>Comment<i className={styles['red']}>*</i>:</strong> <span><textarea name="comment" className={styles.input} value={formData.comment} onChange={handleChange} ></textarea></span></li>

                                    </ul>
                                </div>
                                {/* Button block */}
                                <div className={styles['button-block']}>
                                    <input type="submit" value="Save " className={styles.button} onClick={hanldeSaveInfo} disabled={loading || buttonsDisabled || department === "Human Resources"} />
                                    <input type="submit" value={buttonsDisabled ? 'Submitted' : 'Submit'} className={styles.button} onClick={hanldeSubmit} disabled={loading || buttonsDisabled || department === "Human Resources"} />
                                    <ToastContainer />
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
                {loading && <div className={`${styles['loader-center']} ${styles['loaderHeight-IT']} `}>
                    <div className={`${styles['newsloader-container']} ${styles['newsloaderContainer-IT']} `}>
                        <div className={styles.newsloader}></div></div>
                </div>}
            </div>
        </div>
    );
};

export default IT;
