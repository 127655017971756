import axios from 'axios';


const ViewFeedback = async (token) => {
 try {
  
    const response = await axios.post(
        process.env.REACT_APP_API_URL + 'api/feedback/view-candidate-feedback', 
        { token},
        {
            headers: {
              'Content-Type': 'application/json', 
            },
          }
      );
    
    return response;
  } catch (error) {
    console.error('Error fetching candidate details:', error);
    if (error.response) {
      console.log('Response data:', error.response.data);
    }
    throw error;
  }
};

export default ViewFeedback;



