
import React from 'react';
import styles from '../../applicantContainers/Candidate/MainContent/Main.module.scss';
const RadioButton = ({ name, id, value, label, className, onChange, checked,error  }) => {
  const labelClass = error ? `${className} ${styles.radioError}` : className;
  return (
    <li>
      <input
        type="radio"
        name={name}
        id={id}
        value={value}
        className={className}
        onChange={onChange}
        checked={checked} // Add onChange handler
      />
      <label htmlFor={id} className={labelClass}>
        <i></i> <span>{label}</span>
      </label>
    </li>
  );
};

export default RadioButton;
