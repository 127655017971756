import React, { useEffect, useState } from 'react';
import styles from '../Boading.module.scss';
import EmployeeInfo from '../EmployeeInfo/EmployeeInfo';
import OnboadingDetails from '../../api/FetchOnboaingDetail';
import OnboadingSaveEmployeeDetails from '../../api/SaveOnboadingData';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Payroll = ({department, isActive, authorized, token, employeeId, setEmployeeId, admin, disableTab, onSubmit, activeTabIndex }) => {
    const [loading, setLoading] = useState(true);
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [refreshEmployeeInfo, setRefreshEmployeeInfo] = useState(false);
    const [formData, setFormData] = useState({
        payroll_account_access: false, 
        salary_account_opened: false, 
        comment: ''
    });

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: checked
        }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    useEffect(() => {
        const fetchData = async () => {
            if (authorized) {
                try {
                    setLoading(true);
                    const url = 'get-employee-payroll';
                    const employeeData = await OnboadingDetails(url, token, employeeId);
                    if (employeeData.status === 200) {
                        const empDetails = employeeData.payroll;
                        setFormData({
                            payroll_account_access: empDetails.payroll_account_access || false,
                            salary_account_opened: empDetails.salary_account_opened || false,
                            comment: empDetails.comment || ''
                        });
                        if(empDetails.status === 'Submitted'){
                            setButtonsDisabled(true);
                        }
                    } else {
                        console.error('Error fetching employee data:', employeeData.error);
                    }
                } catch (error) {
                    console.error('Error fetching employee data:', error);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchData();
    }, [authorized, token, employeeId,refreshEmployeeInfo]);

    const hanldeSaveInfo = async (event) => {
        event.preventDefault();
        if (!formData.comment) {
            toast.error('Please Provide Comment!');
            return;
        }
        try {
            setLoading(true);
            const url = 'save-employee-payroll';
            const requestData = {
                employee_id: employeeId,
                payroll: {
                    ...formData,
                    status: "Pending",
                    created_by: admin,
                }
            };

            const response = await OnboadingSaveEmployeeDetails(url, token, employeeId, requestData);
            toast.success(response.msg);
            setRefreshEmployeeInfo(prevState => !prevState);
        } catch (error) {
            console.error('Error saving form data:', error);
        } finally {
            setLoading(false);
        }
    }
    const hanldeSubmit = async (event) => {
        event.preventDefault();
        if (!formData.comment) {
            toast.error('Please Provide Comment!');
            return;
        }
        try {
            setLoading(true);
            const url = 'save-employee-payroll';
            const requestData = {
                employee_id: employeeId,
                payroll: {
                    ...formData,
                    status: "Submitted",
                    created_by: admin,
                }
            };

            const response = await OnboadingSaveEmployeeDetails(url, token, employeeId, requestData);
            toast.success(response.msg);
           // onSubmit(activeTabIndex);
            setRefreshEmployeeInfo(prevState => !prevState);
        } catch (error) {
            console.error('Error saving form data:', error);
        } finally {
            setButtonsDisabled(true);
            setLoading(false);
        }
    }

    return (
        <div className={styles.employee}>
            <div className={styles.grid}>
                <div className={styles['edit-bg']}></div>

                <EmployeeInfo token={token} employeeId={employeeId} refreshEmployeeInfo={refreshEmployeeInfo}/>

                <div className={styles.col9}>
                    <div className={`${styles['box-shadow']} ${styles['border-radius']}`}>
                        <fieldset className={styles.fieldset}>
                            <legend>Account Information</legend>
                            <div className={styles.grid}>
                                <div className={styles.col6}>
                                    <div className={styles['right-space']}>
                                        <ul className={styles['two-col']}>
                                            <li>
                                                <strong>Salary Account Opened​:</strong> 
                                                <span>
                                                    <input 
                                                        type="checkbox" 
                                                        id="salary_account_opened" 
                                                        name="salary_account_opened" 
                                                        checked={formData.salary_account_opened}
                                                        onChange={handleCheckboxChange} 
                                                    /> <label htmlFor="salary_account_opened"></label>
                                                </span>
                                            </li>
                                            <li>
                                                <strong>Payroll Account Access</strong>
                                                <span>
                                                    <input 
                                                        type="checkbox" 
                                                        id="payroll_account_access" 
                                                        name="payroll_account_access" 
                                                        checked={formData.payroll_account_access} 
                                                        onChange={handleCheckboxChange} 
                                                    />
                                                    <label htmlFor="payroll_account_access"></label>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className={styles['right-space']}>
                                    <ul className={styles['two-col']}>
                                        <li>
                                            <strong>Comment<i className={styles['red']}>*</i>:</strong> 
                                            <span>
                                                <textarea 
                                                    name="comment" 
                                                    className={styles.input} 
                                                    value={formData.comment} 
                                                    onChange={handleChange} 
                                                ></textarea>
                                            </span>
                                        </li>
                                    </ul>
                                </div>

                                {/* Button block */}
                                <div className={styles['button-block']}>
                                    <input type="submit" value="Save" className={styles.button} onClick={hanldeSaveInfo} disabled={loading || buttonsDisabled || department === "Human Resources"}/>
                                    <input type="submit" value={buttonsDisabled ? 'Submitted' : 'Submit'} className={styles.button} onClick={hanldeSubmit} disabled={loading || buttonsDisabled || department === "Human Resources"}/>
                                    <ToastContainer />
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
                {loading && <div className={`${styles['loader-center']} ${styles['loaderHeight-payroll']} `}>
                    <div className={`${styles['newsloader-container']} ${styles['newsloaderContainer-payroll']} `}>
                            <div className={styles.newsloader}></div></div>
                        </div>}
            </div>
        </div>
    );
};

export default Payroll;
