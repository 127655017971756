import React, { useState, useEffect } from 'react';
import { useToken } from '../../../context/AuthContext'
import InputField from '../../../components/InputField/InputField'
import Button from '../../../components/Button/Button'
import styles from '../MainContent/Main.module.scss';
import Language from '../Details/Languages'
import ProgressBar from './ProgressBar'
import GetCandidateDetails from '../../../api/fetchCandidateData'
import SaveCandidateData from '../../../api/SaveCandidateData'
import StepEmitter from '../../../components/StepEmitter/StepEmitter'

const OtherInformation = ({ step, setStep, isEdit, setIsEdit, handlePreviewClick, apiData }) => {
    const { token, zoho_candidate_id } = useToken();
    const [showLanguage, setShowLanguage] = useState(false);
    const [showRreview, setshowRreview] = useState(false);
    const totalSteps = 12;
    const nameRegex = /^[A-Za-z.-]+(\s*[A-Za-z.-]+)*$/;
    const [formData, setFormData] = useState({
        Referred_For_Employment: "",
        Criminal_Case: "",
        Major_illness: "",
        Vehicle_Detail: "",
        Abroad_Detail: "",
        Passport_Detail: "",
        Aim_In_Life: "",
        Yourself_after_Years: "",

    });

    const [errors, setErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        const newErrors = { ...errors };
        if (!value.trim()) {
            newErrors[name] = 'This field is required.';
        } else {
            delete newErrors[name];
        }
        setErrors(newErrors);
    };


    const handleRadioChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
            ...(name === 'criminal_case' && value === 'No' && { Criminal_Case: '' }),
            ...(name === 'major_illness' && value === 'No' && { Major_illness: '' }),
            ...(name === 'vehical' && value === 'No' && { Vehicle_Detail: '' }),
            ...(name === 'abroad' && value === 'No' && { Abroad_Detail: '' }),
            ...(name === 'passport' && value === 'No' && { Passport_Detail: '' })
        }));
    };


    const validateForm = () => {
        const newErrors = {};
        let isValid = true;

        if (!formData.Referred_For_Employment.trim()) {
            newErrors.Referred_For_Employment = 'This field is required.';
            isValid = false;
        }
        else if (!nameRegex.test(formData.Referred_For_Employment.trim())) {
            newErrors.Referred_For_Employment = 'Referred should be characters.';
            isValid = false;
        }
        if (!formData.Aim_In_Life.trim()) {
            newErrors.Aim_In_Life = 'This field is required.';
            isValid = false;
        } else if (!nameRegex.test(formData.Aim_In_Life.trim())) {
            newErrors.Aim_In_Life = 'Aim should be characters.';
            isValid = false;
        }
        if (!formData.Yourself_after_Years.trim()) {
            newErrors.Yourself_after_Years = 'This field is required.';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    // Function to fetch candidate details from API
    const fetchCandidateDetails = async () => {
        try {
            const details = await GetCandidateDetails(token, zoho_candidate_id);
            const apiData = details.data;
            setFormData(prevState => ({
                ...prevState,
                Referred_For_Employment: apiData.candidate_other_information.Referred_For_Employment || '',
                Criminal_Case: apiData.candidate_other_information.Criminal_Case || '',
                Major_illness: apiData.candidate_other_information.Major_illness || '',
                Vehicle_Detail: apiData.candidate_other_information.Vehicle_Detail || '',
                Abroad_Detail: apiData.candidate_other_information.Abroad_Detail || '',
                Passport_Detail: apiData.candidate_other_information.Passport_Detail || '',
                Aim_In_Life: apiData.candidate_other_information.Aim_In_Life || '',
                Yourself_after_Years: apiData.candidate_other_information.Yourself_after_Years || '',
            }));

            // Set radio buttons based on fetched values
            if (apiData.candidate_other_information.Major_illness) {
                setFormData(prevState => ({
                    ...prevState,
                    major_illness: 'Yes'
                }));
            } else {
                setFormData(prevState => ({
                    ...prevState,
                    major_illness: 'No'
                }));
            }
            if (apiData.candidate_other_information.Criminal_Case) {
                setFormData(prevState => ({
                    ...prevState,
                    criminal_case: 'Yes'
                }));
            } else {
                setFormData(prevState => ({
                    ...prevState,
                    criminal_case: 'No'
                }));
            }

            if (apiData.candidate_other_information.Vehicle_Detail) {
                setFormData(prevState => ({
                    ...prevState,
                    vehical: 'Yes'
                }));
            } else {
                setFormData(prevState => ({
                    ...prevState,
                    vehical: 'No'
                }));
            }

            if (apiData.candidate_other_information.Abroad_Detail) {
                setFormData(prevState => ({
                    ...prevState,
                    abroad: 'Yes'
                }));
            } else {
                setFormData(prevState => ({
                    ...prevState,
                    abroad: 'No'
                }));
            }

            if (apiData.candidate_other_information.Passport_Detail) {
                setFormData(prevState => ({
                    ...prevState,
                    passport: 'Yes'
                }));
            } else {
                setFormData(prevState => ({
                    ...prevState,
                    passport: 'No'
                }));
            }
        } catch (error) {
            console.error('Error fetching candidate details:', error);

        }
    };



    useEffect(() => {
        fetchCandidateDetails();
    }, []);

    const handleContinueClick = async () => {
        if (validateForm()) {
            try {
                const dataToSave = {
                    candidate_other_information: {
                        Referred_For_Employment: formData.Referred_For_Employment,
                        Criminal_Case: formData.Criminal_Case,
                        Major_illness: formData.Major_illness,
                        Vehicle_Detail: formData.Vehicle_Detail,
                        Abroad_Detail: formData.Abroad_Detail,
                        Passport_Detail: formData.Passport_Detail,
                        Aim_In_Life: formData.Aim_In_Life,
                        Yourself_after_Years: formData.Yourself_after_Years,

                    },
                    Zoho_Item_Id: zoho_candidate_id,
                    Process_Status: "9"
                };
                const response = await SaveCandidateData(dataToSave);
                if (response.status === 200) {
                    setShowLanguage(true);
                    StepEmitter.emit('stepChange', step + 1);
                } else {
                    console.error('Error saving candidate data:', response.statusText);

                }
            } catch (error) {
                console.error('Error saving candidate data:', error);

            }

        }
    };

    const handlePreviewPage = async () => {
        if (validateForm()) {
            try {
                const dataToSave = {
                    candidate_other_information: {
                        Referred_For_Employment: formData.Referred_For_Employment,
                        Criminal_Case: formData.Criminal_Case,
                        Major_illness: formData.Major_illness,
                        Vehicle_Detail: formData.Vehicle_Detail,
                        Abroad_Detail: formData.Abroad_Detail,
                        Passport_Detail: formData.Passport_Detail,
                        Aim_In_Life: formData.Aim_In_Life,
                        Yourself_after_Years: formData.Yourself_after_Years,

                    },
                    Zoho_Item_Id: zoho_candidate_id,
                    Process_Status: "9"
                };
                const response = await SaveCandidateData(dataToSave);
                if (response.status === 200) {
                    setshowRreview(true);

                    setStep(11);
                    StepEmitter.emit('stepChange', 11);
                } else {
                    console.error('Error saving candidate data:', response.statusText);

                }
            } catch (error) {
                console.error('Error saving candidate data:', error);

            }

        }
    };

    const handleBackClick = () => {
        StepEmitter.emit('stepChange', step - 1);
    }
    return (
        <>
            {step === 9 && (
                <>
                    <ProgressBar currentStep={step} totalSteps={totalSteps} />
                    <div className={styles['required-details']}>
                        Kindly fill the required details <span>*</span> of the form below.
                    </div>
                    <h4 className={styles.heading1} style={{ display: step === 9? 'block' : 'none' }}>
                        <span>Other Information</span>
                    </h4>
                    <div className={`${styles['row-c']} ${styles.width} ${styles['other-INFORMATION']}`} style={{ display: step === 9 ? 'block' : 'none' }}>
                        <form id="other-INFORMATION" name="other-INFORMATION">
                            <div className={styles.other}>
                                <div className={styles['col-left']}>
                                    <ul>
                                        <li> <span className={styles.span}>Who referred you to us for employment?<i className={styles.red}>*</i></span>
                                            <InputField
                                                type="text"
                                                id="Referred_For_Employment"
                                                name="Referred_For_Employment"
                                                className={styles.input}
                                                required
                                                mainClass={styles.div}
                                                value={formData.Referred_For_Employment}
                                                onChange={(e) => {
                                                    handleInputChange(e);
                                                    setFormData({ ...formData, Referred_For_Employment: e.target.value }); // Update formData directly
                                                }}
                                                error={errors.Referred_For_Employment}
                                            />

                                        </li>
                                        <li> <span className={styles.span}>Have you been involved in any Civil/Criminal Litigation?<i className={styles.red}>*</i></span>
                                            <div className={styles.div}>
                                                <ol className={styles['yes-no-option']}>
                                                    <li>
                                                        <input
                                                            type="radio"
                                                            name="criminal_case"
                                                            className={styles['criminal_case_detail']}
                                                            id="criminal1"
                                                            value="Yes"
                                                            required="required"
                                                            checked={formData.criminal_case === 'Yes'}
                                                            onChange={handleRadioChange}
                                                        />
                                                        <label htmlFor="criminal1" className=""> <span>Yes</span></label>
                                                    </li>
                                                    <li>
                                                        <input
                                                            type="radio"
                                                            name="criminal_case"
                                                            className={styles['criminal_case_detail']}
                                                            id="criminal2"
                                                            value="No"
                                                            required="required"
                                                            checked={formData.criminal_case === 'No'}
                                                            onChange={handleRadioChange}
                                                        />
                                                        <label htmlFor="criminal2" className=""> <span>No</span></label>
                                                    </li>
                                                </ol>
                                                {formData.criminal_case === 'Yes' && (
                                                    <InputField
                                                        type="text"
                                                        id="Criminal_Case"
                                                        name="Criminal_Case"
                                                        className={`${styles.input} ${styles.no}`}
                                                        placeholder="if yes give detail"
                                                        value={(formData.criminal_case === 'Yes' && formData.Criminal_Case !== 'No') ? formData.Criminal_Case : ''}
                                                        onChange={handleInputChange}
                                                    />
                                                )}
                                            </div>
                                        </li>
                                        <li> <span className={styles.span}>Have you had major illness/operations in the last three years?<i className={styles.red}>*</i></span>
                                            <div className={styles.div}>
                                                <ol className={styles['yes-no-option']}>
                                                    <li>
                                                        <input
                                                            type="radio"
                                                            name="major_illness"
                                                            className={styles['major_illness_detail']}
                                                            id="illness1"
                                                            value="Yes"
                                                            required="required"
                                                            checked={formData.major_illness === 'Yes'}
                                                            onChange={handleRadioChange}
                                                        />
                                                        <label htmlFor="illness1" className=""> <span>Yes</span></label>
                                                    </li>
                                                    <li>
                                                        <input
                                                            type="radio"
                                                            name="major_illness"
                                                            className={styles['major_illness_detail']}
                                                            id="illness12"
                                                            value="No"
                                                            required="required"
                                                            checked={formData.major_illness === 'No'}
                                                            onChange={handleRadioChange}
                                                        />
                                                        <label htmlFor="illness12" className=""> <span>No</span></label>
                                                    </li>
                                                </ol>
                                                {formData.major_illness === 'Yes' && (
                                                    <InputField
                                                        type="text"
                                                        id="Major_illness"
                                                        name="Major_illness"
                                                        className={`${styles.input} ${styles.no}`}
                                                        placeholder="if yes give detail"
                                                        value={(formData.major_illness === 'Yes' && formData.Major_illness !== 'No') ? formData.Major_illness : ''}
                                                        onChange={handleInputChange}
                                                    />
                                                )}
                                            </div>
                                        </li>

                                        <li>
                                            <span className={styles.span}>Do you own any vehicle?<i className={styles.red}>*</i></span>
                                            <div className={styles.div}>
                                                <ol className={styles['yes-no-option']}>
                                                    <li>
                                                        <input
                                                            type="radio"
                                                            className={styles['vehical_detail']}
                                                            name="vehical"
                                                            id="vehical1"
                                                            value="Yes"
                                                            required="required"
                                                            checked={formData.vehical === 'Yes'}
                                                            onChange={handleRadioChange}
                                                        />
                                                        <label htmlFor="vehical1" className=""> <span>Yes</span></label>
                                                    </li>
                                                    <li>
                                                        <input
                                                            type="radio"
                                                            className={styles['vehical_detail']}
                                                            name="vehical"
                                                            id="vehical2"
                                                            value="No"
                                                            required="required"
                                                            checked={formData.vehical === 'No'}
                                                            onChange={handleRadioChange}
                                                        />
                                                        <label htmlFor="vehical2" className=""> <span>No</span></label>
                                                    </li>
                                                </ol>
                                                {formData.vehical === 'Yes' && (
                                                    <textarea
                                                        id="Vehicle_Detail"
                                                        name="Vehicle_Detail"
                                                        className={`${styles.input} ${styles.textarea}  `}
                                                        placeholder="if yes give detail"
                                                        value={(formData.vehical === 'Yes' && formData.Vehicle_Detail !== 'No') ? formData.Vehicle_Detail : ''}

                                                        onChange={handleInputChange}
                                                    />
                                                )}
                                            </div>
                                        </li>

                                        <li>
                                            <span className={styles.span}>Have you been abroad?<i className={styles.red}>*</i></span>
                                            <div className={styles.div}>
                                                <ol className={styles['yes-no-option']}>
                                                    <li>
                                                        <input
                                                            type="radio"
                                                            className={styles['abroad_detail']}
                                                            name="abroad"
                                                            id="abroad1"
                                                            value="Yes"
                                                            required="required"
                                                            checked={formData.abroad === 'Yes'}
                                                            onChange={handleRadioChange}
                                                        />
                                                        <label htmlFor="abroad1" className=""> <span>Yes</span></label>
                                                    </li>
                                                    <li>
                                                        <input
                                                            type="radio"
                                                            className={styles['abroad_detail']}
                                                            name="abroad"
                                                            id="abroad2"
                                                            value="No"
                                                            required="required"
                                                            checked={formData.abroad === 'No'}
                                                            onChange={handleRadioChange}
                                                        />
                                                        <label htmlFor="abroad2" className=""> <span>No</span></label>
                                                    </li>
                                                </ol>
                                                {formData.abroad === 'Yes' && (
                                                    <textarea
                                                        id="Abroad_Detail"
                                                        name="Abroad_Detail"
                                                        className={`${styles.input} ${styles.textarea}`}
                                                        placeholder="if yes, state countries visited, year & purpose"
                                                        value={(formData.abroad === 'Yes' && formData.Abroad_Detail !== 'No') ? formData.Abroad_Detail : ''}
                                                        onChange={handleInputChange}
                                                    />
                                                )}
                                            </div>
                                        </li>

                                        <li>
                                            <span className={styles.span}>Do you have a Valid passport?<i className={styles.red}>*</i></span>
                                            <div className={styles.div}>
                                                <ol className={styles['yes-no-option']}>
                                                    <li>
                                                        <input
                                                            type="radio"
                                                            className={styles['passport_no']}
                                                            name="passport"
                                                            id="passport1"
                                                            value="Yes"
                                                            required="required"
                                                            checked={formData.passport === 'Yes'}
                                                            onChange={handleRadioChange}
                                                        />
                                                        <label htmlFor="passport1" className=""> <span>Yes</span></label>
                                                    </li>
                                                    <li>
                                                        <input
                                                            type="radio"
                                                            className={styles['passport_no']}
                                                            name="passport"
                                                            id="passport2"
                                                            value="No"
                                                            required="required"
                                                            checked={formData.passport === 'No'}
                                                            onChange={handleRadioChange}
                                                        />
                                                        <label htmlFor="passport2" className=""> <span>No</span></label>
                                                    </li>
                                                </ol>
                                                {formData.passport === 'Yes' && (
                                                    <textarea
                                                        id="Passport_Detail"
                                                        name="Passport_Detail"
                                                        className={`${styles.input} ${styles.textarea}`}
                                                        placeholder="if yes, Passport"
                                                        value={(formData.passport === 'Yes' && formData.Passport_Detail !== 'No') ? formData.Passport_Detail : ''}
                                                        onChange={handleInputChange}
                                                    />
                                                )}
                                            </div>
                                        </li>

                                        <li> <span className={styles.span}>What is your aim in life?<i className={styles.red}>*</i></span>
                                            <div className={styles.div}>
                                                <textarea id="Aim_In_Life" name="Aim_In_Life" className={`${styles.input} ${styles.textarea} ${errors.Aim_In_Life ? styles.error : styles.valid}`}
                                                    required="required"
                                                    value={formData.Aim_In_Life}
                                                    onChange={(e) => {
                                                        handleInputChange(e);
                                                        setFormData({ ...formData, Aim_In_Life: e.target.value });
                                                    }} />
                                                {errors.Aim_In_Life && <label id="your_aim-error" className={styles.error} htmlFor="Aim_In_Life" style={{ display: 'inline' }}>{errors.Aim_In_Life}</label>}
                                            </div>
                                        </li>
                                        <li> <span className={styles.span}>Where will you find yourself after 5 years?<i className={styles.red}>*</i></span>
                                            <div className={styles.div}>
                                                <textarea
                                                    id="Yourself_after_Years"
                                                    name="Yourself_after_Years"
                                                    className={`${styles.input} ${styles.textarea} ${errors.Yourself_after_Years ? styles.error : styles.valid}`}
                                                    required="required"
                                                    value={formData.Yourself_after_Years}
                                                    onChange={(e) => {
                                                        handleInputChange(e);
                                                        setFormData({ ...formData, Yourself_after_Years: e.target.value });
                                                    }}
                                                />
                                                {errors.Yourself_after_Years && <label id="Yourself_after_Years-error" className={styles.error} htmlFor="Yourself_after_Years" style={{ display: 'inline' }}>{errors.Yourself_after_Years}</label>}
                                            </div>

                                        </li>
                                    </ul>
                                </div>
                                <div className={styles.clear}></div>
                            </div>
                        </form>
                        <Button
                            backButtonLabel="Back"
                            continueButtonLabel="Save & Next"
                            onBackClick={handleBackClick}
                            onContinueClick={handleContinueClick}
                            backButtonClass={styles['custom-back-class']}
                            continueButtonClass={styles['custom-continue-class']}
                            previewButtonLabel={isEdit ? "Preview" : ""}
                            previewButtonAction={isEdit ? handlePreviewPage : ''}
                        />

                    </div>

                </>
            )}
            {showLanguage && <Language step={step} setStep={setStep} isEdit={isEdit} setIsEdit={setIsEdit} handlePreviewClick={handlePreviewClick} apiData={apiData} />}
        </>
    );

}
export default OtherInformation;