import React, { useEffect, useState } from 'react';
import Basic from './BasicDetails';
import Family from './Family';
import Experience from './Experience';
import Education from './Education';
import OtherInformation from './OtherInformation';
import Preview from '../Details/Preview';
import Gender from './GenderDetails';
import styles from '../MainContent/Main.module.scss';
import StepEmitter from '../../../components/StepEmitter/StepEmitter';
import Awards from './Reward';
import ComputerSkills from './ComputerSkills';
import Language from './Languages';
import ProfessionalEXP from './ProfesstionalRefrence'
import Address from './Address';


const RightPopup = ({ isEdit, setIsEdit, handlePreviewClick, setPopupVisible, displayStyle,apiData={apiData}, setDisplayStyle={setDisplayStyle}, setApiData={setApiData} }) => {
    const [step, setStep] = useState(1);

    useEffect(() => {
        const handleStepChange = (newStep) => {
            setStep(newStep);
        };

        StepEmitter.on('stepChange', handleStepChange);

        // Clean up event listener on component unmount
        return () => {
            StepEmitter.off('stepChange', handleStepChange);
        };
    }, []);
   
    let componentToRender;
    switch (step) {
        case 2:
            componentToRender = <Basic isEdit={isEdit} setIsEdit={setIsEdit} handlePreviewClick={handlePreviewClick} setStep={setStep} apiData={apiData}/>;
            break;
        case 3:
            componentToRender = <Gender step={step} isEdit={isEdit} setIsEdit={setIsEdit} handlePreviewClick={handlePreviewClick} setStep={setStep} apiData={apiData} setApiData={setApiData}/>;
            break;
        case 4:
            componentToRender = <Address step={step} isEdit={isEdit} setIsEdit={setIsEdit} handlePreviewClick={handlePreviewClick} setStep={setStep} apiData={apiData}/>;
            break;
        case 5:
            componentToRender = <Family step={step} isEdit={isEdit} setIsEdit={setIsEdit} handlePreviewClick={handlePreviewClick} setStep={setStep} apiData={apiData}/>;
            break;
        case 6:
            componentToRender = <ProfessionalEXP step={step} isEdit={isEdit} setIsEdit={setIsEdit} handlePreviewClick={handlePreviewClick} setStep={setStep} apiData={apiData}/>;
            break;

        case 7:
            componentToRender = <Experience step={step} isEdit={isEdit} setIsEdit={setIsEdit} handlePreviewClick={handlePreviewClick} setStep={setStep} apiData={apiData}/>;
            break;
        case 8:
            componentToRender = <Education step={step} isEdit={isEdit} setIsEdit={setIsEdit} handlePreviewClick={handlePreviewClick} setStep={setStep} apiData={apiData}/>;
            break;
        
        case 9:
            componentToRender = <OtherInformation step={step} isEdit={isEdit} setIsEdit={setIsEdit} handlePreviewClick={handlePreviewClick} setStep={setStep} apiData={apiData}/>;
            break;

        case 10:
            componentToRender = <Language step={step} isEdit={isEdit} setIsEdit={setIsEdit} handlePreviewClick={handlePreviewClick} setStep={setStep} apiData={apiData}/>;
            break;
        case 11:
            componentToRender = <Preview step={step} isEdit={isEdit} setIsEdit={setIsEdit} handlePreviewClick={handlePreviewClick} setStep={setStep} setPopupVisible={setPopupVisible} displayStyle={displayStyle} apiData={apiData}  setDisplayStyle={setDisplayStyle}/>;
            break;
        // Add cases for other steps as needed*/
        default:
            componentToRender = <Basic step={step} isEdit={isEdit} setIsEdit={setIsEdit} handlePreviewClick={handlePreviewClick} setStep={setStep} apiData={apiData}/>;
            break;
    }

    return (
        <div className={styles['popup-right']}>
            <div className={styles.fieldset}>
                {componentToRender}
            </div>
        </div>
    );
}

export default RightPopup;
