import axios from 'axios';

const OffBoadingEmployeeList = async (authenticateToken) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_URL + 'api/offboarding/employee-list', 
      {
        headers: {
          'Content-Type': 'application/json',
          'token': authenticateToken
        }
      }
    );
    return response.data;
   
  } catch (error) {
    console.error('Error fetching employee list:', error);
    if (error.response) {
      console.log('Response data:', error.response.data);
    }
    throw error;
  }
};

export default OffBoadingEmployeeList;
