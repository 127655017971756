import React, { useState, useEffect } from 'react';
import styles from '../Boading.module.scss';
import EmployeeInfo from '../EmployeeInfo/EmployeeInfo';
import OnboadingDetails from '../../api/FetchOnboaingDetail';
import OnboadingClassificationEmployeeDetails from '../../api/SaveClassificationOnboading';
import getDepartmentList from '../../api/GetDepartmentList';
import DatePicker from "react-datepicker";
import SelectField from '../../components/SelectField/SelectField';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import axios from 'axios';


const Classification = ({ department, isActive, authorized, token, employeeId, setEmployeeId, admin, disableTab, onSubmit, activeTabIndex }) => {
    const [loading, setLoading] = useState(true);
    const [start_date, setStartDate] = useState(null);
    const [laptopDate, setLaptopDate] = useState('');
    const [endDate, setEndDate] = useState(null);
    const [trainingStartDate, setTrainingStartDate] = useState('');
    const [trainingEndDate, setTrainingEndDate] = useState('');
    const [departments, setDepartments] = useState([]);
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [trainingMaterial, settrainingMaterial] = useState('null');
    const [materialOption, setMaterialOption] = useState([]);
    const [selectedMaterial, setSelectedMaterial] = useState(null);
    const [error, setError] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedMaterials, setSelectedMaterials] = useState([]);
    const [refreshEmployeeInfo, setRefreshEmployeeInfo] = useState(false);
    let empDetailsjob = localStorage.getItem('employeeJob');
    const animatedComponents = makeAnimated();
    const [departmentValue, setdepartmentValue] = useState('');
    const [formData, setFormData] = useState({
        employment_type: '',
        title: empDetailsjob,
        start_date: null,
        laptop_date: null,
        department: '',
        role_type: '',
        proposed_consulting_project: '',
        location: 'India',
        locationRegion: '',
        special_software_needs: false,
        special_software_name: '',
        additional_access: false,
        additional_access_name: '',
        ship_laptop: false,
        shipLaptopToAddress: '',
        other_location: '',
        country: '',
        business_card_required: false,
        training_period_start: '',
        end_date_Of_training: '',
        trainingDay: '',
        network_calling: false,
        //training_material: false,
        all_hands: false,
        all_heads: false,
        strategy_meetings: false,
        training_tools_required: false,
        training_period_end: '',
        training_period_days: 0,
        operation: false,
        training_material: ''


    });

    const [pcEquipment, setPCEquipment] = useState({
        headphone: false,
        monitor: false,
        mouse: false,
        keyboard: false
    });
    const [softwareAccess, setSoftwareAccess] = useState({
        office365: false,
        Jira: false,
        dropbox: false,
        git: false,
        quickSight: false,
        power: false,
        CRM: false,
        adobe: false,
        winrar: false
    });
    const [mobileAccess, setmobileAccess] = useState({
        teams: false,
        jira: false,
        outlook: false
    });

    const locations = [
        { value: '-1', label: 'Select Location' },
        { value: '20, Okhla Phase III, New Delhi', label: '20, Okhla Phase III, New Delhi' },
        { value: 'B 21 Lajpat Nagar 2, New Delhi', label: 'B 21 Lajpat Nagar 2, New Delhi' },
    ];




    useEffect(() => {
        const url = 'get-employee-classification';
        if (authorized) {
            const fetchData = async () => {
                try {
                    setLoading(true);

                    const employeeData = await OnboadingDetails(url, token, employeeId);
                    let trainingDays = '';
                    if (employeeData.status === 200) {
                        const empDetails = employeeData.classification;
                        if (empDetails.training_period_end === 'None' || empDetails.training_period_start === 'None') {
                            trainingDays = 0;
                        }
                        else {
                            trainingDays = empDetails.training_period_days || 0;
                        }
                        setFormData({
                            employment_type: empDetails.employment_type || '',
                            title: empDetailsjob,
                            department: empDetails.department || '',
                            role_type: empDetails.role_type || '',
                            proposed_consulting_project: empDetails.proposed_consulting_project || '',
                            location: empDetails.location || '',
                            locationRegion: empDetails.locationRegion || '',
                            pcEquipment: empDetails.pcEquipment || '',
                            softwareAccess: empDetails.softwareAccess || '',
                            mobile_access: empDetails.mobile_access || '',
                            special_software_needs: empDetails.special_software_needs || false,
                            ship_laptop: empDetails.ship_laptop || false,
                            other_location: empDetails.other_location || '',
                            network_calling: empDetails.network_calling || false,
                            country: empDetails.country || '',
                            business_card_required: empDetails.business_card_required || false,
                            strategy_meetings: empDetails.strategy_meetings || false,
                            start_date: empDetails.start_date || null,
                            laptop_date: empDetails.laptop_date || null,
                            training_period_days: trainingDays,
                            comment: empDetails.comment || '',
                            additional_access: empDetails.additional_access || false,
                            all_hands: empDetails.all_hands || false,
                            all_heads: empDetails.all_heads || false,
                            operation: empDetails.operation || false,
                            special_software_name: empDetails.special_software_name || '',
                            additional_access_name: empDetails.additional_access_name || '',
                            training_period_start: empDetails.training_period_start || '',
                            training_period_end: empDetails.training_period_end || ''
                        });
                        const trainingMaterials = empDetails.training_material || [];
                        const selected = trainingMaterials.map(material => ({
                            label: material.training_name,
                            value: material.training_id,
                            trainID: material.training_id,
                        }));

                        setdepartmentValue(empDetails.department);
                        setSelectedMaterials(selected);

                        const pc_equipment = empDetails.pc_equipment;
                        if (pc_equipment) {
                            setPCEquipment({
                                headphone: pc_equipment.headphone || false,
                                monitor: pc_equipment.monitor || false,
                                mouse: pc_equipment.mouse || false,
                                keyboard: pc_equipment.keyboard || false
                            });
                        }
                        const mobileAccess = empDetails.mobile_access;
                        if (mobileAccess) {
                            setmobileAccess({
                                teams: mobileAccess.teams || false,
                                outlook: mobileAccess.outlook || false,
                                jira: mobileAccess.jira || false

                            });
                        }
                        const softwareAccess = empDetails.software_access;
                        if (softwareAccess) {
                            setSoftwareAccess({
                                office365: softwareAccess.office365 || false,
                                Jira: softwareAccess.Jira || false,
                                dropbox: softwareAccess.dropbox || false,
                                git: softwareAccess.git || false,
                                quickSight: softwareAccess.quickSight || false,
                                power: softwareAccess.power || false,
                                CRM: softwareAccess.CRM || false,
                                winrar: softwareAccess.winrar || false,
                                adobe: softwareAccess.adobe || false
                            });
                        }

                        if (empDetails.status === 'Submitted') {
                            setButtonsDisabled(true);
                        }

                        setLoading(false);

                    } else {
                        console.error('Error fetching employee data:', employeeData.error);
                    }
                } catch (error) {
                    console.error('Error fetching employee data:', error);
                } finally {
                    setLoading(false);
                }
            };

            fetchData();
        }
    }, [authorized, token, employeeId, refreshEmployeeInfo]);



    useEffect(() => {
        if (authorized) {

            const fetchDepartment = async () => {
                try {
                    const department = await getDepartmentList(token);
                    if (department.status === 200) {
                        setDepartments(department.department);

                    } else {
                        console.error('Error fetching employee data:');
                    }
                } catch (error) {
                    console.error('Error fetching employee data:', error);
                } finally {
                    setLoading(false);
                }
            };

            fetchDepartment();
        }
    }, [authorized, token]);

    useEffect(() => {
        if (authorized) {
            const getTrainingMaterial = async () => {
                try {
                    const response = await axios.get(
                        `${process.env.REACT_APP_API_URL}api/onboarding/training-material-list`,
                        {
                            headers: {
                                'token': token,
                                'accept': 'application/json',
                            },
                        }
                    );
                    if (response.status === 200) {

                        const options = response.data.training_material.map(trainObj => {
                            const trainID = Object.keys(trainObj)[0];
                            const material = trainObj[trainID];
                            return { value: material, label: material, trainID };
                        });
                        setMaterialOption(options);

                    } else {
                        console.error('Error fetching training material data:');
                    }
                } catch (error) {
                    console.error('Error fetching training material:', error);
                }
            };

            // Call the function to get the training material
            getTrainingMaterial();
        }
    }, [authorized, token]);
    const handleDeselectChange = (deselectedOption) => {
        const updatedOptions = materialOption.filter(option => option !== deselectedOption);
        setMaterialOption(updatedOptions);
        setSelectedOption(updatedOptions);
    };
    const orderOptions = (values) => {
        return values
            .filter((v) => v.isFixed)
            .concat(values.filter((v) => !v.isFixed));
    };
    const handleSelectChange = (newValue, actionMeta) => {
        switch (actionMeta.action) {
            case 'remove-value':
            case 'pop-value':
                if (actionMeta.removedValue.isFixed) {
                    return;
                }
                break;
            case 'clear':
                newValue = materialOption.filter((v) => v.isFixed);
                break;
        }
        setSelectedMaterials(orderOptions(newValue));

    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: checked
        }));
        
    };

    const handleCheckboxChangelocation = (e) => {
        const { name, checked } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: checked,
           other_location: checked ? prevData.other_location : "",
        }));
    };

    const handleCheckboxChangeIndia = (e) => {
        const { name, checked } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: checked,
          country: checked ? prevData.country : "",
        }));
    };

    const handlepcCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setPCEquipment(prevState => ({
            ...prevState,
            [name]: checked
        }));
    };
    const handleswCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setSoftwareAccess(prevState => ({
            ...prevState,
            [name]: checked
        }));
    };

    const handlemobileCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setmobileAccess(prevState => ({
            ...prevState,
            [name]: checked
        }));
    };




    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    const handleSubmit = (e) => {
        e.preventDefault();

    };

    const handleStartDateChange = (date) => {
        setFormData({ ...formData, start_date: date });
    };

    const handleLaptopDateChange = (date) => {
        setFormData({ ...formData, laptop_date: date });
    }
    const formatDate = (dateString) => {
        const dateObject = new Date(dateString);

        if (isNaN(dateObject.getTime())) {
            console.error('Invalid dateObject:', dateObject);
            return '';
        }

        const year = dateObject.getFullYear();
        const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
        const day = dateObject.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    function formattraingDate(date) {
        if (!date) return null;

        if (typeof date === 'string') {

            const parsedDate = new Date(date);
            const formattedDate = parsedDate.toISOString().split('T')[0];
            return formattedDate;
        } else if (date instanceof Date) {

            const formattedDate = date.toISOString().split('T')[0];
            return formattedDate;
        } else {
            return null;
        }
    }

    const handleTrainingStartDateChange = (date) => {
        const formattedDate = formattraingDate(date);
        if (formData.training_period_end && formattedDate > formData.training_period_end) {
            setButtonsDisabled(true);
            date = formData.training_period_end;
            setError("Start date should be before the end date.");
        } else {
            setButtonsDisabled(false);
            setError('');
        }

        setFormData(prevState => ({
            ...prevState,
            training_period_start: formattedDate
        }));

        if (formattedDate && formData.training_period_end) {
            const endDate = new Date(formattedDate);
            const startDate = new Date(formData.training_period_end);
            const millisecondsDifference = Math.abs(endDate - startDate);
            const daysDifference = Math.ceil(millisecondsDifference / (1000 * 60 * 60 * 24));
            const adjustedDaysDifference = daysDifference > 0 ? daysDifference + 1 : 1;
            setFormData(prevState => ({
                ...prevState,
                training_period_days: adjustedDaysDifference
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                training_period_days: 0
            }));
        }
    };



    const handleTrainingEndDateChange = (date) => {
        const formattedDate = formattraingDate(date);
        if (formData.training_period_start) {
            if (formattedDate < formData.training_period_start) {
                formattedDate = formData.training_period_start;
            }
        }
        setFormData(prevState => ({
            ...prevState,
            training_period_end: formattedDate
        }));
        if (formattedDate && formData.training_period_start) {
            const startDate = new Date(formData.training_period_start);
            const endDate = new Date(formattedDate);

            const millisecondsDifference = Math.abs(endDate - startDate);

            const daysDifference = Math.ceil(millisecondsDifference / (1000 * 60 * 60 * 24));

            const adjustedDaysDifference = daysDifference > 0 ? daysDifference + 1 : 1;
            setFormData(prevState => ({
                ...prevState,
                training_period_days: adjustedDaysDifference
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                training_period_days: 0
            }));
        }
    };





    const typeOptions = [
        { value: '-1', label: 'Employment Type' },
        { value: 'Full Time', label: 'Full Time' },
        { value: 'Consultant', label: 'Consultant' },
        { value: 'Contractual', label: 'Contractual' },
        { value: 'Intern', label: 'Intern' },


    ];

    const hanldeSaveInfo = async (event) => {
        event.preventDefault();
        if ((formData.training_period_start && !formData.training_period_end) ||
            (!formData.training_period_start && formData.training_period_end)) {
            toast.error('Please fill both start date and end date.');
            return;
        }
        if (formData.training_period_start && formData.training_period_end) {
            const startDate = formatDate(formData.training_period_start);
            const endDate = formatDate(formData.training_period_end);
            if (startDate > endDate) {
                toast.error('Start date should be before the end date.');
                return;
            }
        }
        if (formData.special_software_needs && !formData.special_software_name) {
            toast.error('Please provide special software needs.');
            return;
        }

        if (formData.additional_access && !formData.additional_access_name) {
            toast.error('Please provide additional access.');
            return;
        }
        if (!formData.comment) {
            toast.error('Please Provide Comment!');
            return;
        }
        if (formData.ship_laptop && !formData.other_location) {
            toast.error('Please Provide Other Location!');
            return;
        }
        if (formData.network_calling && !formData.country) {
            toast.error('Please Provide Which Country!');
            return;
        }
        try {
            setLoading(true);
            const url = 'save-employee-classification';


            let formattedtrainingPeriodStart = trainingStartDate ? formatDate(trainingStartDate) : '';
            let formattedtrainingPeriodEnd = trainingEndDate ? formatDate(trainingEndDate) : '';
            let trainingMaterials;
            if (selectedMaterials && selectedMaterials.length > 0) {
                trainingMaterials = selectedMaterials.map(option => ({
                    training_id: option.trainID,
                    training_name: option.label
                }));
            } else {
                trainingMaterials = [];
            }
            const start_date = formData.start_date ? formatDate(formData.start_date) : '';
            const laptop_date = formData.laptop_date ? formatDate(formData.laptop_date) : '';
            const requestData = {
                employee_id: employeeId,
                classifications: {
                    ...formData,
                    pc_equipment: {
                        headphone: pcEquipment.headphone,
                        monitor: pcEquipment.monitor,
                        mouse: pcEquipment.mouse,
                        keyboard: pcEquipment.keyboard
                    },
                    software_access: {
                        office365: softwareAccess.office365,
                        Jira: softwareAccess.Jira,
                        dropbox: softwareAccess.dropbox,
                        git: softwareAccess.git,
                        quickSight: softwareAccess.quickSight,
                        power: softwareAccess.power,
                        CRM: softwareAccess.CRM,
                        adobe: softwareAccess.adobe,
                        winrar: softwareAccess.winrar
                    },
                    mobile_access: {
                        teams: mobileAccess.teams,
                        jira: mobileAccess.jira,
                        outlook: mobileAccess.outlook
                    },
                    start_date: start_date,
                    laptop_date: laptop_date,
                    training_material: trainingMaterials,
                    status: "Pending",
                    created_by: admin,
                }
            };

            // Send request to the API
            const response = await OnboadingClassificationEmployeeDetails(url, token, employeeId, requestData);
            setRefreshEmployeeInfo(prevState => !prevState);
            toast.success(response.msg);
        } catch (error) {
            console.error('Error saving form data:', error);
        } finally {
            setLoading(false);
        }
    }

    const hanldeSubmit = async (event) => {
        if ((formData.training_period_start && !formData.training_period_end) ||
            (!formData.training_period_start && formData.training_period_end)) {
            toast.error('Please fill both start date and end date.');
            return;
        }
        if (formData.special_software_needs && !formData.special_software_name) {
            toast.error('Please provide  special software needs.');
            return;
        }

        // Validation for additional access
        if (formData.additional_access && !formData.additional_access_name) {
            toast.error('Please provide  additional access.');
            return;
        }
        if (!formData.comment) {
            toast.error('Please Provide Comment!');
            return;
        }
        event.preventDefault();
        try {
            setLoading(true);
            const url = 'save-employee-classification';



            let formattedtrainingPeriodStart = trainingStartDate ? formatDate(trainingStartDate) : '';
            let formattedtrainingPeriodEnd = trainingEndDate ? formatDate(trainingEndDate) : '';
            let trainingMaterials;
            const start_date = formData.start_date ? formatDate(formData.start_date) : '';
            const laptop_date = formData.laptop_date ? formatDate(formData.laptop_date) : '';
            if (selectedOption && selectedOption.length > 0) {
                trainingMaterials = selectedOption.map(option => ({
                    training_id: option.trainID,
                    training_name: option.label
                }));
            } else {
                trainingMaterials = [];
            }

            const requestData = {
                employee_id: employeeId,
                classifications: {
                    ...formData,
                    pc_equipment: {
                        headphone: pcEquipment.headphone,
                        monitor: pcEquipment.monitor,
                        mouse: pcEquipment.mouse,
                        keyboard: pcEquipment.keyboard
                    },
                    software_access: {
                        office365: softwareAccess.office365,
                        Jira: softwareAccess.Jira,
                        dropbox: softwareAccess.dropbox,
                        git: softwareAccess.git,
                        quickSight: softwareAccess.quickSight,
                        power: softwareAccess.power,
                        CRM: softwareAccess.CRM,
                        adobe: softwareAccess.adobe,
                        winrar: softwareAccess.winrar
                    },
                    mobile_access: {
                        teams: mobileAccess.teams,
                        jira: mobileAccess.jira,
                        outlook: mobileAccess.outlook
                    },
                    start_date: start_date,
                    laptop_date: laptop_date,
                    training_material: trainingMaterials,
                    status: "Submitted",
                    created_by: admin,
                }
            };

            // Send request to the API
            const response = await OnboadingClassificationEmployeeDetails(url, token, employeeId, requestData);
            toast.success(response.msg);
            //onSubmit(activeTabIndex);
        } catch (error) {
            console.error('Error saving form data:', error);
        } finally {
            setButtonsDisabled(true);
            setLoading(false);
        }
    }

    return (
        <div className={styles.employee}>
            <div className={styles.grid}>
                <div className={styles['edit-bg']}>

                </div>

                <EmployeeInfo token={token} employeeId={employeeId} refreshEmployeeInfo={refreshEmployeeInfo} />

                <div className={styles.col9}>
                    <div className={`${styles['box-shadow']} ${styles['border-radius']} `}>
                        <fieldset className={styles.fieldset}>
                            <legend>Employee Classification</legend>
                            <div className={styles.grid}>
                                <div className={styles.col6}>
                                    <div className={styles['right-space']}>
                                        <ul className={styles['two-col']}>


                                            <li><strong>Employment Type:</strong> <span>
                                                <SelectField
                                                    id="employment_type"
                                                    name="employment_type"
                                                    className={`${styles.select} ${styles.input}`}
                                                    options={typeOptions}
                                                    value={formData.employment_type}
                                                    onChange={handleChange}
                                                />
                                            </span></li>
                                            <li><strong>Title:<i className={styles.red}>*</i></strong>
                                                <span>
                                                    <input type="text" className={styles.input} value={formData.title} name="title" disabled />
                                                </span>
                                            </li>
                                            <li><strong>Start Date:</strong> <span>
                                                <DatePicker
                                                    selected={formData.start_date || ''}
                                                    onChange={handleStartDateChange}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    className={styles.input}
                                                    id="start_date"
                                                    name="start_date"
                                                    autoComplete="off"
                                                    required
                                                    timeZone="UTC"
                                                    disabled


                                                />
                                            </span></li>
                                            <li><strong>Laptop Date:</strong> <span>
                                                <DatePicker
                                                    selected={formData.laptop_date || ''}
                                                    onChange={handleLaptopDateChange}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    className={styles.input}
                                                    id="laptop_date"
                                                    name="laptop_date"
                                                    autoComplete="off"
                                                    required
                                                    timeZone="UTC"



                                                />
                                            </span></li>

                                            <li><strong>Role Type:</strong>
                                                <span>
                                                    <input type="text" className={styles.input} name="role_type" value={formData.role_type} onChange={handleChange} />
                                                </span>
                                            </li>
                                            <li><strong>Proposed Consulting Project:</strong> <span>
                                                <input type="text" className={styles.input} name="proposed_consulting_project" value={formData.proposed_consulting_project} onChange={handleChange} />
                                            </span></li>
                                            <li><strong>Location:</strong> <span>
                                                <SelectField
                                                    id="location"
                                                    name="location"
                                                    className={`${styles.select} ${styles.input}`}
                                                    options={locations}
                                                    onChange={handleChange}
                                                    value={formData.location}
                                                />
                                            </span></li>
                                            <li><strong>PC Equipment:</strong>
                                                <span>
                                                    <input type="checkbox" id="headphone" name="headphone" value="Headphone" checked={pcEquipment.headphone}
                                                        onChange={handlepcCheckboxChange} />
                                                    <label htmlFor="headphone">Headphone</label>
                                                    <input type="checkbox" id="monitor" name="monitor" value="Monitor" checked={pcEquipment.monitor}
                                                        onChange={handlepcCheckboxChange} />
                                                    <label htmlFor="monitor">Monitor</label>
                                                    <input type="checkbox" id="mouse" name="mouse" value="Mouse" checked={pcEquipment.mouse}
                                                        onChange={handlepcCheckboxChange} />
                                                    <label htmlFor="mouse">Mouse</label>
                                                    <input type="checkbox" id="keyboard" name="keyboard" value="Keyboard" checked={pcEquipment.keyboard}
                                                        onChange={handlepcCheckboxChange} />
                                                    <label htmlFor="keyboard">Keyboard</label>
                                                </span></li>
                                            <li><strong>Software Acces:</strong><span>
                                                <input type="checkbox" id="Office" name="office365" value="Office 365" checked={softwareAccess.office365}
                                                    onChange={handleswCheckboxChange} />
                                                <label htmlFor="Office">Office 365</label>
                                                <input type="checkbox" id="Jira" name="Jira" value="Jira" checked={softwareAccess.Jira}
                                                    onChange={handleswCheckboxChange} />
                                                <label htmlFor="Jira">Jira</label>
                                                <input type="checkbox" id="Dropbox" name="dropbox" value="Dropbox" checked={softwareAccess.dropbox}
                                                    onChange={handleswCheckboxChange} />
                                                <label htmlFor="Dropbox">Dropbox</label>
                                                <input type="checkbox" id="Git" name="git" value="Git" checked={softwareAccess.git}
                                                    onChange={handleswCheckboxChange} />
                                                <label htmlFor="Git">Git</label>
                                                <input type="checkbox" id="QuickSight" name="quickSight" value="QuickSight (Author or Reader)" checked={softwareAccess.quickSight}
                                                    onChange={handleswCheckboxChange} />
                                                <label htmlFor="QuickSight">QuickSight (Author or Reader)</label>
                                                <input type="checkbox" id="PowerBI" name="power" value="Power BI" checked={softwareAccess.power}
                                                    onChange={handleswCheckboxChange} />
                                                <label htmlFor="PowerBI">Power BI</label>
                                                <input type="checkbox" id="CRM" name="CRM" value="CRM" checked={softwareAccess.CRM}
                                                    onChange={handleswCheckboxChange} />
                                                <label htmlFor="CRM">CRM</label>
                                                <input type="checkbox" id="Adobe" name="adobe" checked={softwareAccess.adobe}
                                                    onChange={handleswCheckboxChange} />
                                                <label htmlFor="Adobe">Adobe</label>
                                                <input type="checkbox" id="WinRar" name="winrar" checked={softwareAccess.winrar}
                                                    onChange={handleswCheckboxChange} />
                                                <label htmlFor="WinRar">WinRar</label>
                                            </span>
                                            </li>
                                            <li>
                                                <strong>Mobile Access:</strong>
                                                <span>
                                                    <input type="checkbox" id="MS_Teams" name="teams" value="MS Teams" checked={mobileAccess.teams} onChange={handlemobileCheckboxChange} />
                                                    <label htmlFor="MS_Teams">MS Teams</label>

                                                    <input type="checkbox" id="Outlook" name="outlook" value="Outlook" checked={mobileAccess.outlook} onChange={handlemobileCheckboxChange} />
                                                    <label htmlFor="Outlook">Outlook</label>

                                                    <input type="checkbox" id="Jira_Mobile" name="jira" value="Jira" checked={mobileAccess.jira} onChange={handlemobileCheckboxChange} />
                                                    <label htmlFor="Jira_Mobile">Jira</label>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className={styles.col6}>
                                    <div className={styles['left-space']}>
                                        <ul className={styles['two-col']}>

                                            <li><strong>Special Software Needs:</strong> <span>
                                                <input
                                                    type="checkbox"
                                                    id="special_software_need"
                                                    name="special_software_needs"
                                                    onChange={handleCheckboxChange}
                                                    checked={formData.special_software_needs}

                                                />
                                                <label htmlFor="special_software_need"></label>
                                                {formData.special_software_needs && (
                                                    <input
                                                        type="text"
                                                        className={styles.input}
                                                        name="special_software_name"
                                                        value={formData.special_software_name}
                                                        onChange={handleChange}
                                                    />
                                                )}
                                            </span></li>

                                            <li>

                                                <strong>Additional Access:</strong> <span>

                                                    <input
                                                        type="checkbox"
                                                        id="additional_access"
                                                        name="additional_access"
                                                        onChange={handleCheckboxChange}
                                                        checked={formData.additional_access}

                                                    />
                                                    <label htmlFor="additional_access"></label>
                                                    {formData.additional_access && (
                                                        <input type="text" className={styles.input} name="additional_access_name" value={formData.additional_access_name} onChange={handleChange} />
                                                    )}

                                                </span></li>
                                            <li>
                                                <strong>Shipped Laptop to Address on File :</strong>
                                                <span>
                                                    <input type="checkbox" id="shipLaptopToAddress" name="ship_laptop" checked={formData.ship_laptop}
                                                        onChange={handleCheckboxChangelocation} />
                                                    <label htmlFor="shipLaptopToAddress"></label>
                                                </span>
                                            </li>
                                            {formData.ship_laptop && (
                                                <li>
                                                    <strong>Other Location :</strong>
                                                    <span>
                                                        <textarea
                                                            className={styles.input}
                                                            name="other_location"
                                                            value={formData.other_location}
                                                            onChange={handleChange}
                                                        ></textarea>
                                                    </span>
                                                </li>
                                            )}
                                            <li>
                                                <strong>Network Calling:</strong>
                                                <span className={styles.calling}>
                                                    <input
                                                        type="checkbox"
                                                        id="networkCalling"
                                                        name="network_calling"
                                                        checked={formData.network_calling}
                                                        onChange={handleCheckboxChangeIndia}
                                                    />
                                                    <label htmlFor="networkCalling">Toggle</label>
                                                </span>
                                            </li>
                                            {formData.network_calling && ( <li><strong>Which Country :</strong> <span><input type="text" className={styles.input} name="country" value={formData.country} onChange={handleChange} /></span></li>)}
                                            <li><strong>Business Card Required:</strong> <span>
                                                <input type="checkbox" id="businessCardRequired" name="business_card_required" value="yes" checked={formData.business_card_required}
                                                    onChange={handleCheckboxChange} />
                                                <label htmlFor="businessCardRequired"></label>
                                            </span></li>

                                        </ul>
                                        <ul className={styles['two-col']}>
                                            <li>
                                                <strong>All Hands:</strong>
                                                <span>
                                                    <input type="checkbox" id="allHand" name="all_hands" value="yes" checked={formData.all_hands}
                                                        onChange={handleCheckboxChange} />
                                                    <label htmlFor="allHand"></label>
                                                </span>
                                            </li>

                                            <li>
                                                <strong>All Heads:</strong>
                                                <span>
                                                    <input type="checkbox" id="allHead" name="all_heads" value="yes" checked={formData.all_heads}
                                                        onChange={handleCheckboxChange} />
                                                    <label htmlFor="allHead"></label>
                                                </span>
                                            </li>

                                            <li><strong>Strategy Meeting:<i className="required"></i></strong> <span>
                                                <input type="checkbox" id="Strategy" name="strategy_meetings" value="Strategy meeting" checked={formData.strategy_meetings}
                                                    onChange={handleCheckboxChange} />
                                                <label htmlFor="Strategy"></label>
                                            </span></li>
                                            <li><strong>Operation:<i className="required"></i></strong> <span>
                                                <input type="checkbox" id="Operation" name="operation" value="Operation" checked={formData.operation}
                                                    onChange={handleCheckboxChange} />
                                                <label htmlFor="Operation"></label>
                                            </span></li>
                                            <li><strong>Training Material:<i className="required"></i></strong> <span>
                                                <Select
                                                    options={materialOption}
                                                    onChange={handleSelectChange}
                                                    isClearable={materialOption.some((v) => !v.isFixed)}
                                                    isMulti
                                                    value={selectedMaterials}
                                                    name="training_material"
                                                />

                                            </span></li>



                                            <li><strong>Training Period:<i className="required"></i></strong>
                                                <strong>Start Date:</strong>
                                                <span>
                                                    <DatePicker
                                                        selected={formData.training_period_start}
                                                        onChange={(date) => handleTrainingStartDateChange(date)}
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        className={styles.input}
                                                        id="training_period_start"
                                                        name="training_period_start"
                                                        autoComplete="off"
                                                        required
                                                        timeZone="UTC"
                                                    />
                                                    <strong>End Date:</strong>
                                                    <DatePicker
                                                        selected={formData.training_period_end}
                                                        onChange={(date) => handleTrainingEndDateChange(date)}
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        className={styles.input}
                                                        id="training_period_end"
                                                        name="training_period_end"
                                                        autoComplete="off"
                                                        required
                                                    />
                                                </span>
                                                {error && <span style={{ color: 'red' }}>{error}</span>}
                                            </li>
                                            <li><strong>Training Days:<i className="required"></i></strong>
                                                <span>
                                                    <input type="number" id="trainingDay " name="training_period_days" className={styles.input} value={formData.training_period_days} onChange={handleChange} disabled />


                                                </span></li>

                                        </ul>
                                    </div>
                                </div>
                                <div className={styles['right-space']}>
                                    <ul className={styles['two-col']}>
                                        <li><strong>Comment<i className={styles['red']}>*</i>:</strong> <span><textarea name="comment" className={styles.input} value={formData.comment} onChange={handleChange} ></textarea></span></li>

                                    </ul>
                                </div>

                                {/* Button block */}
                                <div className={styles['button-block']}>
                                    <input type="submit" value="Save " className={styles.button} onClick={hanldeSaveInfo} disabled={loading || buttonsDisabled || department === "Human Resources"} />
                                    <input type="submit" value={buttonsDisabled ? 'Submitted' : 'Submit'} className={styles.button} onClick={hanldeSubmit} disabled={loading || buttonsDisabled || department === "Human Resources"} />
                                    <ToastContainer />
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
                {loading && <div className={`${styles['loader-center']} ${styles['loaderHeight-classification']} `}>
                    <div className={`${styles['newsloader-container']} ${styles['newsloaderContainer-classification']} `}>
                        <div className={styles.newsloader}></div></div>
                </div>}
            </div>

        </div>
    );
};

export default Classification;
