import React from 'react';
import logo from '../../../assets/images/logo.svg'; 
import styles from '../MainContent/Main.module.scss';

const Header = ({candidateData}) => {
  const isFeedbackAvailable = candidateData && Object.keys(candidateData).length > 0;
  return (
    <>
      
    <div className={styles.header}>
    <div className={styles.main}>
      <div className={styles['header-left']}>
         <img src={logo} alt="BluEnt"/></div>
      <div className={styles['header-right']}>
      <h1>{isFeedbackAvailable ? 'CANDIDATE FEEDBACK FORM' : 'APPLICATION FOR EMPLOYMENT'}</h1>
       
      </div>
      <div className={styles.clear}></div>
    </div>
  </div> 
  </>
  );
};

export default Header;  
