import React, { useEffect, useState } from 'react';
import styles from '../Boading.module.scss';
import EmployeeInfo from '../EmployeeInfo/EmployeeInfo';
import OnboadingDetails from '../../api/FetchOnboaingDetail';
import OnboadingSaveEmployeeDetails from '../../api/SaveOnboadingData';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Administrative = ({department, isActive, authorized, token, employeeId, setEmployeeId, admin, disableTab, onSubmit, activeTabIndex }) => {
    const [loading, setLoading] = useState(true);
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [formData, setFormData] = useState({
        business_card: false,
        parking_allocation: false,
        office_space: false,
        administration_email: false,
        comment: '',
        business_card_required:false,
        
    });
    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: checked
        }));
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    useEffect(() => {
        const url = 'get-employee-administrative';
        if (authorized) {
            const fetchData = async () => {
                try {
                    setLoading(true);
                    const employeeData = await OnboadingDetails(url, token, employeeId);
                    console.log(employeeData);
                    if (employeeData.status === 200) {
                        const empDetails = employeeData.administrative;
                        setFormData({
                            business_card: empDetails.business_card || false,
                            parking_allocation: empDetails.parking_allocation || false,
                            office_space: empDetails.office_space || false,
                            administration_email:empDetails.administration_email || false,
                            comment: empDetails.comment || '',
                            business_card_required: empDetails.business_card_required || false
                        });
                         setLoading(false);
                         if(empDetails.status === 'Submitted'){
                            setButtonsDisabled(true);
                        }

                    } else {
                        console.error('Error fetching employee data:', employeeData.error);
                    }
                } catch (error) {
                    console.error('Error fetching employee data:', error);
                } finally {
                    setLoading(false);
                }
            };

            fetchData();
        }
    }, [authorized, token, employeeId]);

    

    const hanldeSaveInfo = async (event) => {
        event.preventDefault();
        if (formData.business_card_required && !formData.business_card) {
            toast.error('Business Card field is required!');
            return;
        }
        if (!formData.comment) {
            toast.error('Please Provide Comment!');
            return;
        }
        const dataToSubmit = { ...formData };
        delete dataToSubmit.business_card_required;
        delete dataToSubmit.business_card;
        try {
            setLoading(true);
            const url = 'save-employee-administrative';
            
            const requestData = {
                employee_id: employeeId,
                administrative: {
                    ...dataToSubmit,
                    status: "Pending",
                    created_by: admin,
                }
            };

             const response = await OnboadingSaveEmployeeDetails(url, token, employeeId, requestData);
             if(!response.form_submitted){
                toast.error(response.msg);
               }
               else{
                   toast.success(response.msg);
                   //setButtonsDisabled(true);
               }
        } catch (error) {
            console.error('Error saving form data:', error);
        } finally {
            setLoading(false);
        }
    }

    const hanldeSubmit = async (event) => {
        event.preventDefault();
        if (formData.business_card_required && !formData.business_card) {
            toast.error('Business Card field is required!');
            return;
        }
        if (!formData.comment) {
            toast.error('Please Provide Comment!');
            return;
        }
        const dataToSubmit = { ...formData };
        delete dataToSubmit.business_card_required;
        delete dataToSubmit.business_card;
        try {
            setLoading(true);
            const url = 'save-employee-administrative';
            const requestData = {
                employee_id: employeeId,
                administrative: {
                    ...dataToSubmit,
                    status: "Submitted",
                    created_by: admin,
                }
            };

             const response = await OnboadingSaveEmployeeDetails(url, token, employeeId, requestData);
             if(!response.form_submitted){
                toast.error(response.msg);
               }
               else{
                   toast.success(response.msg);
                   setButtonsDisabled(true);
               }
        } catch (error) {
            console.error('Error saving form data:', error);
        } finally {
            //setButtonsDisabled(true);
            setLoading(false);
        }
    }

 
        return (
            <div className={styles.employee}>
                <div className={styles.grid}>
                    <div className={styles['edit-bg']}>
    
                    </div>
    
                    <EmployeeInfo token={token} employeeId={employeeId}/>
    
                    <div className={styles.col9}>
                        <div className={`${styles['box-shadow']} ${styles['border-radius']} `}>
                            <fieldset className={styles.fieldset}>
                                <legend>Administrative</legend>
                                <div className={styles.grid}>
                                    <div className={styles.col6}>
                                        <div className={styles['right-space']}>
                                            <ul className={styles['two-col']}>
                                                {formData.business_card_required && (
                                                <li><strong>Business Cards <i className={styles['red']}>*</i>:</strong> <span>
                                                    <input type="checkbox" id="business_card_yes" name="business_card" value="Yes"  checked={formData.business_card}
                                                        onChange={handleCheckboxChange}/>
                                                    <label htmlFor="business_card_yes"></label>
                                                    
                                                    </span></li>
                                                )}
                                                <li><strong>Administration Email with information on logins:</strong> <span>
                                                <input type="checkbox" id="administration_email_yes" name="administration_email" value="Yes" checked={formData.administration_email}
                                                        onChange={handleCheckboxChange} />
                                                    <label htmlFor="administration_email_yes"></label>
                                                    
                                                    </span></li>
                                            </ul>
                                        </div>
                                    </div>
    
                                    <div className={styles.col6}>
                                        <div className={styles['left-space']}>
                                            <ul className={styles['two-col']}>
                                                <li><strong>Office Space allocation:</strong> <span>
                                                    <input type="checkbox" id="office_space" name="office_space" value="Yes" checked={formData.office_space}
                                                        onChange={handleCheckboxChange}/>
                                                    <label htmlFor="office_space"></label>
                                                    
                                                    </span></li>
                                                <li><strong>Parking Allocation:</strong> <span>
                                                <input type="checkbox" id="parking_allocation" name="parking_allocation" value="Yes" checked={formData.parking_allocation}
                                                        onChange={handleCheckboxChange}/>
                                                    <label htmlFor="parking_allocation"></label>
                                                    
                                                    </span></li>
                                                
                                            </ul>
                                        </div>
                                    </div>
                                    <div className={styles['right-space']}>
                                    <ul className={styles['two-col']}>
                                        <li><strong>Comment<i className={styles['red']}>*</i>:</strong> <span><textarea name="comment" className={styles.input} value={formData.comment} onChange={handleChange} ></textarea></span></li>

                                    </ul>
                                </div>
                                    {/* Button block */}
                                    <div className={styles['button-block']}>
                                    <input type="submit" value="Save " className={styles.button} onClick={hanldeSaveInfo} disabled={loading || buttonsDisabled || department === "Human Resources"}/>
                                    <input type="submit" value={buttonsDisabled ? 'Submitted' : 'Submit'} className={styles.button} onClick={hanldeSubmit} disabled={loading || buttonsDisabled || department === "Human Resources"}/>
                                    <ToastContainer />
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    {loading && <div className={`${styles['loader-center']} ${styles['loaderHeight-sales']} `}>
                        <div className={`${styles['newsloader-container']} ${styles['newsloaderContainer-payroll']} `}>
                            <div className={styles.newsloader}></div></div>
                        </div>}
                </div>
            </div>
        );
    };

export default Administrative;
