import React, { useState, useEffect } from 'react'
import styles from '../MainContent/Main.module.scss'
import RadioButton from '../../../components/RadioField/RadioField'
import Button from '../../../components/Button/Button'
import UploadPhoto from '../../../components/FileUpload/FileUpload'
import "react-datepicker/dist/react-datepicker.css"
import Address from '../Details/Address'
import ProgressBar from './ProgressBar'
import GetCandidateDetails from '../../../api/fetchCandidateData'
import SaveCandidateData from '../../../api/SaveCandidateData'
import { useToken } from '../../../context/AuthContext'
import StepEmitter from '../../../components/StepEmitter/StepEmitter'



const Gender = ({ step, setStep, isEdit, setIsEdit, handlePreviewClick, apiData, setApiData}) => {
    const [image, setImage] = useState(null);
    const [showAddress, setShowAddress] = useState(false);
    const { token, zoho_candidate_id } = useToken();
    const [showRreview, setshowRreview] = useState(false);
    const [basicData, setbasicData] = useState(null);
    const [experiData, setexperiData] = useState('');
    const totalSteps = 12;
    const [formData, setFormData] = useState({
        Gender: '',
        Marital_Status: '',


    });
    const [errors, setErrors] = useState({
        Gender: '',
        Marital_Status: '',

    });

    // Function to fetch candidate details from API
    const fetchCandidateDetails = async () => {
        try {
            const apiData = await GetCandidateDetails(token, zoho_candidate_id);
            const details = apiData.data.basic_details;
            const work =details.work_experience; 
            setFormData({
                ...formData,
                Gender: details.Gender || '',
                Marital_Status: details.Marital_Status || '',
            });
            setexperiData(work);
                   
            setbasicData(details);
        } catch (error) {
            console.error('Error fetching candidate details:', error);

        }
    };

    useEffect(() => {
        fetchCandidateDetails();
    }, [token, zoho_candidate_id]);



    const handleBackClick = async () => {
        if (step === 3) {



            StepEmitter.emit('stepChange', step - 1);

        }
        else {

        }
    };

    const validateForm = () => {
        let isValid = true;
        const genderErrors = {};

        if (!formData.Gender) {
            genderErrors.Gender = 'Please select a gender.';
            isValid = false;
        }
        if (!formData.Marital_Status) {
            genderErrors.Marital_Status = 'Please select a marital status.';
            isValid = false;
        }

        setErrors({ ...errors, ...genderErrors });
        return isValid;
    };



    const handleImageUpload = (imageUrl) => {
        
        setImage(imageUrl);
    };


    const handleContinueClick = async () => {
        if (validateForm()) {
            try {
                const dataToSave = {
                    basic_details: {
                        ...formData,
                        First_Name: basicData.First_Name || '',
                        Middle_Name: basicData.Middle_Name || '',
                        Last_Name: basicData.Last_Name || '',
                        Height: basicData.Height || '',
                        Weight: basicData.Weight || '',
                        Date_Of_Birth: basicData.Date_Of_Birth || '',
                        Blood_Group: basicData.Blood_Group || '',
                        Identification_Marks: basicData.Identification_Marks || '',
                        Email: basicData.Email || '',
                        Email2: basicData.Email2 || '',
                        Email3: basicData.Email3 || '',
                        Zoho_Candidate_Id: zoho_candidate_id,
                        Zoho_Item_Id: zoho_candidate_id,
                        Job_Opening_Id: zoho_candidate_id,
                        FormStatus: 'Pending',

                    },
                    Zoho_Item_Id: zoho_candidate_id,
                    Process_Status: "4"
                };
                const [response, responseImage] = await Promise.all([
                    SaveCandidateData(dataToSave),

                ]);
                if (response.status === 200) {
                    
                    setShowAddress(true);
                    StepEmitter.emit('stepChange', step + 1);
                } else {
                    console.error('Error saving candidate data:', response.statusText);

                }
            } catch (error) {
                console.error('Error saving candidate data:', error);

            }
        }
    };

    const handlePreviewPage = async () => {
        if (validateForm()) {
            try {
                const dataToSave = {
                    basic_details: {
                        ...formData,
                        Zoho_Candidate_Id: 'ZR_666_CANV',
                        Zoho_Item_Id: zoho_candidate_id,
                        Job_Opening_Id: zoho_candidate_id,
                        FormStatus: 'Pending',

                    },
                    Zoho_Item_Id: zoho_candidate_id,
                    Process_Status: "4"
                };
                const [response, responseImage] = await Promise.all([
                    SaveCandidateData(dataToSave),

                ]);
                if (response.status === 200) {
                    setshowRreview(true);

                    setStep(11);
                    StepEmitter.emit('stepChange', 11);
                } else {
                    console.error('Error saving candidate data:', response.statusText);

                }
            } catch (error) {
                console.error('Error saving candidate data:', error);

            }
        }
    };



    return (
        <>
            {step === 3 && (
                <>
                    <ProgressBar currentStep={step} totalSteps={totalSteps} />
                    <div className={styles['required-details']}>Kindly fill the required details <span>*</span> of the form below.</div>
                    <h4 className={styles.heading1} style={{ display: step === 3 ? 'block' : 'none' }}><span>Basic Details</span></h4>

                    <div className={`${styles['row-c']} ${styles.width} ${styles.basicINFO2}`} style={{ display: step === 3 ? 'block' : 'none' }}>
                        <form id={styles.basicINFO2} name={styles.basicINFO2} encType="multipart/form-data">
                            <div className={styles['col-left']}>
                                <ul>
                                    <li> <span className={styles.span}>Sex<i className={styles.red}>*</i>:</span>
                                        <div className={styles.div}>
                                            <ol className={styles['sex-option']}>
                                                <RadioButton
                                                    name="Gender"
                                                    id="sex1"
                                                    value="Male"
                                                    label="Male"
                                                    checked={formData.Gender === "Male"}
                                                    onChange={(e) => {
                                                        setFormData({ ...formData, Gender: e.target.value });
                                                        setErrors({ ...errors, Gender: '' });
                                                    }}
                                                    error={errors.Gender}
                                                    className={styles['option-male']}
                                                />
                                                <RadioButton
                                                    name="Gender"
                                                    id="sex2"
                                                    value="Female"
                                                    label="Female"
                                                    checked={formData.Gender === "Female"}
                                                    onChange={(e) => {
                                                        setFormData({ ...formData, Gender: e.target.value });
                                                        setErrors({ ...errors, Gender: '' });
                                                    }}
                                                    error={errors.Gender}
                                                    className={styles['option-female']}
                                                />
                                                <RadioButton
                                                    name="Gender"
                                                    id="sex3"
                                                    value="Other"
                                                    label="Other"
                                                    checked={formData.Gender === "Other"}
                                                    onChange={(e) => {
                                                        setFormData({ ...formData, Gender: e.target.value });
                                                        setErrors({ ...errors, Gender: '' });
                                                    }}
                                                    error={errors.Gender}
                                                    className={styles['option-transgender']}
                                                />
                                            </ol>
                                        </div>
                                    </li>
                                    <li> <span className={styles.span}>Marital Status<i className={styles.red}>*</i>:</span>
                                        <div className={styles.div}>
                                            <ol className={styles['marital-option']}>
                                                <RadioButton
                                                    name="Marital_Status"
                                                    id="marital1"
                                                    value="Married"
                                                    label="Married"
                                                    checked={formData.Marital_Status === "Married"}
                                                    onChange={(e) => {
                                                        setFormData({ ...formData, Marital_Status: e.target.value });
                                                        setErrors({ ...errors, Marital_Status: '' });
                                                    }}
                                                    error={errors.Marital_Status}
                                                    className={styles['option-married']}
                                                    formValues={formData}
                                                    formErrors={errors}
                                                />
                                                <RadioButton
                                                    name="Marital_Status"
                                                    id="marital2"
                                                    value="Single"
                                                    label="Single"
                                                    checked={formData.Marital_Status === "Single"}
                                                    onChange={(e) => {
                                                        setFormData({ ...formData, Marital_Status: e.target.value });
                                                        setErrors({ ...errors, Marital_Status: '' });
                                                    }}
                                                    error={errors.Marital_Status}
                                                    className={styles['option-single']}
                                                    formValues={formData}
                                                    formErrors={errors}
                                                />
                                                <RadioButton
                                                    name="Marital_Status"
                                                    id="marital3"
                                                    value="Other"
                                                    label="Other"
                                                    checked={formData.Marital_Status === "Other"}
                                                    onChange={(e) => {
                                                        setFormData({ ...formData, Marital_Status: e.target.value });
                                                        setErrors({ ...errors, Marital_Status: '' });
                                                    }}
                                                    error={errors.Marital_Status}
                                                    className={styles['option-divorce']}
                                                    formValues={formData}
                                                    formErrors={errors}
                                                />
                                            </ol>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className={styles['col-right']}>
                                <ul>
                                    <li> <UploadPhoto onImageUpload={handleImageUpload} currentImage={formData.image} /></li>
                                </ul>
                            </div>

                        </form>
                        <div className={styles.clear}></div>

                        <Button
                            backButtonLabel="Back"
                            continueButtonLabel="Save & Next"
                            onBackClick={handleBackClick}
                            onContinueClick={handleContinueClick}
                            previewButtonLabel={isEdit ? "Preview" : ""}
                            previewButtonAction={isEdit ? handlePreviewPage : ''}
                        />


                    </div>
                </>
            )}

            {showAddress && (
                <Address step={step} setStep={setStep} isEdit={isEdit} setIsEdit={setIsEdit} handlePreviewClick={handlePreviewClick} apiData={apiData} />
            )}
        </>
    );
}

export default Gender;
