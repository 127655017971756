import React, { useState, useEffect, useRef  } from 'react';
import { useToken } from '../../context/AuthContext';
import styles from './Admin.module.scss';
import Table from '../../components/Table/Table';
import View from '../../assets/images/view.svg';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CandidateDetail from './CandidateDetail';
import FeedbackDetail from './FeedbackDetail';
import { Helmet } from 'react-helmet-async';
import EmployeeList from '../../api/EmployeeList';
import { Navigate } from "react-router-dom";

const EmployeeDetail = ({ deptname, activeTab, setActiveTab, authenticateToken, tabs }) => {
    const { token } = useToken();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [pagination, setPagination] = useState(true);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortState, setSortState] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const [showCandidateDetail, setCandidateDetail] = useState(false);
    const [showFeedback, setFeedback] = useState(false);
    const [tokenValue, settoken] = useState(null);
    const [redirect, setRedirect] = useState(false);
    const [disableTabs, setDisableTabs] = useState([false, false, false]);
    const [employeeId, setEmployeeId] = useState(null);
    const [noData, setNoData] = useState(false); // New state to track no data condition
    const [tabVisibility, setTabVisibility] = useState([true, true, true]); // Track visibility of each tab
    const tabTitles = [
        'Candidate List',
        'Onboarding Employee List',
        'Offboarding Employee List',
    ];

    const managerID = localStorage.getItem('managerID');
    console.log(managerID)
    const activeTabRef = useRef(activeTab);
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const candidateData = await EmployeeList(authenticateToken);
                if (candidateData.data.length === 0) {
                    setNoData(true);
                } else {
                    setData(candidateData.data);
                    const pages = Math.ceil(candidateData.data.length / pageSize);
                    setTotalPages(pages);
                    setNoData(false);
                }
            } catch (error) {
                console.error('Error fetching employee data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [authenticateToken, pageSize]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const activeTabsParam = parseInt(urlParams.get('activeTab'), 10) || 0;
        const tabIndices = {
            'Candidate List': 0,
            'Onboarding Employee List': 1,
            'Offboarding Employee List': 2,
        };

        const newDisableTabs = Array(3).fill(true);
        const activeIndices = tabs.map(tab => tabIndices[tab]);

        activeIndices.forEach(index => {
            if (index !== undefined) {
                newDisableTabs[index] = false;
            }
        });

        const newTabVisibility = newDisableTabs.map(disabled => !disabled);

        let newActiveTab = 0;

        if (activeTabsParam !== undefined && !newDisableTabs[activeTabsParam]) {
            newActiveTab = activeTabsParam;
        } else {
            const firstVisibleTabIndex = newTabVisibility.findIndex(visible => visible);
            if (firstVisibleTabIndex !== -1) {
                newActiveTab = firstVisibleTabIndex;
            }
        }

       if (activeTabRef.current !== newActiveTab) {
            activeTabRef.current = newActiveTab;
            //console.log('fff'+ activeTabRef.current);
            //setActiveTab(activeTabRef.current);
        }

        setDisableTabs(newDisableTabs);
        setTabVisibility(newTabVisibility);
    }, [deptname, tabs, setActiveTab]);

    const handleSort = (column) => {
        const sortedData = [...data];
        const currentSort = sortState[column] || "asc";
        sortedData.sort((a, b) => currentSort === "asc" ? (a[column] > b[column] ? 1 : -1) : (a[column] < b[column] ? 1 : -1));
        setSortState({ ...sortState, [column]: currentSort === "asc" ? "desc" : "asc" });
        setData(sortedData);
    };

    const handleSearch = (query) => {
        setSearchQuery(query);
        const filteredData = data.filter(item =>
            (item.First_Name && item.First_Name.toLowerCase().includes(query.toLowerCase())) ||
            (item.Last_Name && item.Last_Name.toLowerCase().includes(query.toLowerCase())) ||
            (item.Job_Title && item.Job_Title.toLowerCase().includes(query.toLowerCase())) ||
            (item.Department && item.Department.toLowerCase().includes(query.toLowerCase()))
        );
        setTotalPages(Math.ceil(filteredData.length / pageSize));
    };

    const filteredData = data.filter(item =>
        item.First_Name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.Last_Name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.Job_Title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.Department.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleChangePage = (page) => setCurrentPage(page);

    const handleClosePopup = () => {
        setCandidateDetail(false);
        setFeedback(false);
    };

    const sendEmployeeDetail = async (employeeId) => {
        setEmployeeId(employeeId);
        setRedirect(true);
    };

    const handleTabClick = (index) => {
        
        if (tabVisibility[index]) {
            setActiveTab(index);
        }
    };

    const columns = [
        { title: 'First Name', dataKey: 'First_Name', sortable: true, minwidth: 270 },
        { title: 'Last Name', dataKey: 'Last_Name', sortable: true, minwidth: 270 },
        { title: 'Job Title', dataKey: 'Job_Title', sortable: true, minwidth: 270 },
        { title: 'Department', dataKey: 'Department', sortable: true, minwidth: 250 },
        {
            title: 'Details',
            dataKey: 'Zoho_Item_Id',
            renderer: (rowData) => (
                <div className={styles.toolTip} key={rowData.Zoho_Item_Id}>
                    <button>
                    </button>
                    <button
                        data-tooltip-id={`view-tooltip_${rowData.Zoho_Item_Id}`}
                        data-tooltip-content="Employee Detail"
                        data-place='top'
                        className="custom-tooltip"
                        onClick={() => sendEmployeeDetail(rowData.Zoho_Item_Id)}>
                        <img src={View} alt="First Action" width="20" height="20" />
                        <Tooltip id={`view-tooltip_${rowData.Zoho_Item_Id}`} place="top" className="custom-tooltip" />
                    </button>
                </div>
            ),
            minwidth: 270,
        },
    ];
    const disabledTabClass = styles.disabledTab;
    return (
        <>
            <Helmet>
                <title>Employee List</title>
            </Helmet>
            {showCandidateDetail ? (
                <CandidateDetail url={tokenValue} onClose={handleClosePopup} />
            ) : showFeedback ? (
                <FeedbackDetail url={tokenValue} onClose={handleClosePopup} />
            ) : (
                <>
                    {activeTab === 1 && (
                        <>
                            <div className={styles.grid}>
                                <div className={styles.col8}>
                                    <ul className={styles.tab}>
                                        {tabTitles.map((title, index) => (
                                            <li key={index}>
                                                <a
                                                            href="#"
                                                            className={`${index === activeTab ? styles.current : ''} ${disableTabs[index] ? disabledTabClass : ''}`}
                                                            onClick={() => handleTabClick(index)}
                                                            style={disableTabs[index] ? { pointerEvents: 'none', background: '#c5c6c7', color: 'gray' } : {}}
                                                        >
                                                            {title}
                                                        </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className={styles.col4}>
                                    <div className={`${styles['search-icon']} ${styles['search-space-top']}`}>
                                        <input type="search" onChange={(e) => handleSearch(e.target.value)} className={styles['search-field']} placeholder="Search …" value={searchQuery} name="s" title="Search for:" />
                                    </div>
                                </div>
                            </div>
                            <div className={`${styles.grid} ${styles['grid-height']}`}>
                                {redirect && (
                                    <Navigate to={`/onboarding?token=${token}&employeeId=${employeeId}`} replace={true} />
                                )}
                                <div className={styles['dashboard-table1']}>
                                   
                                        <Table
                                            data={filteredData}
                                            columns={columns}
                                            pageSize={pageSize}
                                            onSort={handleSort}
                                            loading={loading}
                                            pagination={pagination}
                                            onChangePage={handleChangePage}
                                            total={totalPages}
                                            activePage={currentPage}
                                            limit={totalPages}
                                            minWidth={20}
                                        />
                                    
                                </div>
                            </div>
                        </>
                        
                    )}
                    <ToastContainer />
                </>
            )}
        </>
    );
};

export default EmployeeDetail;
