import axios from 'axios';

const CandidateList = async (authenticateToken) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_API_URL + 'api/employment-form/candidate-list', 
      {}, 
      {
        headers: {
          'Content-Type': 'application/json',
          'token': authenticateToken
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching candidate details:', error);
    if (error.response) {
      console.log('Response data:', error.response.data);
    }
    throw error;
  }
};

export default CandidateList;
