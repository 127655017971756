import React, { useState, useEffect } from 'react';
import styles from '../Boading.module.scss';
import OffBoadingEmployeeInfo from '../OffBoadingEmployeeInfo/OffBoadingEmployeeInfo';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import OffboadingEmployeeDetails from '../../api/FetchOffboadingEmployeeDetails';
import OffboadingSaveEmployeeDetails from '../../api/OffboadingSaveEmployeeDetails';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const OffBoadingEmployeeDetails = ({ department, isActive, authorized, token, employeeId, setEmployeeId, admin, disableTab, onSubmit, activeTabIndex }) => {
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState(null);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [cellError, setcellError] = useState('');
    const [refreshEmployeeInfo, setRefreshEmployeeInfo] = useState(false);
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [titleError, setTitleError] = useState('');
    const [managerError, setManagerError] = useState('');
    const [firstNameError, setFirstNameError] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [departmentError, setDepartmentError] = useState('');
    const [streetAddressError, setStreetAddressError] = useState('');
    const [cityError, setCityError] = useState('');
    const [countryError, setCountryError] = useState('');
    const [stateError, setStateError] = useState('');
    const [homePhoneError, setHomePhoneError] = useState('');
    const [dateOfJoiningError, setDateOfJoiningError] = useState('');
    const [relievingDateError, setRelievingDateError] = useState('');
    const [reasonForLeavingError, setReasonForLeavingError] = useState('');
    const navigate = useNavigate();
    const managerID = localStorage.getItem('managerID');
    useEffect(() => {
        const fetchData = async () => {
            const url = 'get-employee-details';
            if (authorized) {
                try {
                    setLoading(true);
                    const employeeData = await OffboadingEmployeeDetails(url, token, employeeId);
                    if (employeeData.status === 200) {
                        const empDetails = employeeData.employee;
                        setFormData({
                            first_name: empDetails.first_name || '',
                            manager: empDetails.manager || '',
                            last_name: empDetails.last_name || '',
                            department: empDetails.department || '',
                            date_of_joining: empDetails.date_of_joining || '',
                            street_address:empDetails.street_address || '',
                            personal_email_address: empDetails.personal_email_address || '',
                            releaving_date: empDetails.releaving_date || '',
                            job_title: empDetails.job_title || '',
                            created_by: admin || '',
                            comment: empDetails.comment || '',
                            reason_for_leaving:empDetails.reason_for_leaving || '',
                        });
                        if (empDetails.status === 'Submitted') {
                            setButtonsDisabled(true);
                        }

                    } else {
                        console.error('Error fetching employee data:', employeeData.error);
                    }
                } catch (error) {
                    console.error('Error fetching employee data:', error);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchData();
    }, [isActive, authorized, token, employeeId, admin, refreshEmployeeInfo]);

    const handleStartDateChange = (date) => {
        setFormData({ ...formData, date_of_joining: date });
    };

    const handleRelievingDateChange = (date) => {
        setFormData({ ...formData, releaving_date: date });
    };

    const handleInputChange = (event) => {
        setEmail(event.target.value);

        const { name, value } = event.target;
        if (name === 'personal_email_address') {
            if (!event.target.validity.valid) {
                setEmailError('Please enter a valid email address');
            } else {
                setEmailError('');
            }
        }
        
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleReasonChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleSaveInfo = async (event) => {
        event.preventDefault();
        let hasError = false;

        if (!formData.first_name) {
            toast.error('First name is required');
            hasError = true;
        } 

        if (!formData.last_name) {
            toast.error('Last name is required');
            hasError = true;
        } 

        if (!formData.manager) {
            toast.error('Manager name is required');
            hasError = true;
        } 

        if (!formData.department) {
            toast.error('Department is required');
            hasError = true;
        } 

        if (!formData.street_address) {
            toast.error('Street address is required');
            hasError = true;
        } 

        if (!formData.date_of_joining) {
            toast.error('Date of joining is required');
            hasError = true;
        } 

        if (!formData.releaving_date) {
            toast.error('Relieving date is required');
            hasError = true;
        }

        if (!formData.personal_email_address) {
            toast.error('Personal email address is required');
            hasError = true;
        } 
        if (!formData.job_title) {
            toast.error('Title is required');
            hasError = true;
        } 

        if (!formData.reason_for_leaving) {
            toast.error('Reason for leaving is required');
            hasError = true;
        } 
        if (!formData.comment) {
            toast.error('Please provide a comment.');
            hasError = true;
        } 
        
        if (hasError) {
            return;
        }
        const managerIDInt = parseInt(managerID, 10);
        try {
            setLoading(true);
            const requestData = {
                ...formData,
                status: "Pending",
                manager_id:managerIDInt,
                created_by: admin,

            };
            const url = 'save-employee-details';
            const response = await OffboadingSaveEmployeeDetails(url, token, employeeId, requestData);
            setRefreshEmployeeInfo(prevState => !prevState);
            toast.success('Form data saved successfully!');
        } catch (error) {
            console.error('Error saving form data:', error);
            toast.error('Failed to save form data.');
        } finally {
            setLoading(false);
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        let hasError = false;

        if (!formData.first_name) {
            toast.error('First name is required');
            hasError = true;
        } 
        if (!formData.last_name) {
            toast.error('Last name is required');
            hasError = true;
        } 

        if (!formData.manager) {
            toast.error('Manager name is required');
            hasError = true;
        } 

        if (!formData.department) {
            toast.error('Department is required');
            hasError = true;
        } 
        if (!formData.street_address) {
            toast.error('Street address is required');
            hasError = true;
        } 
        if (!formData.date_of_joining) {
            toast.error('Date of joining is required');
            hasError = true;
        } 

        if (!formData.releaving_date) {
            toast.error('Relieving date is required');
            hasError = true;
        } 
        if (!formData.personal_email_address) {
            toast.error('Personal email address is required');
            hasError = true;
        } 

        if (!formData.job_title) {
            toast.error('Title is required');
            hasError = true;
        } 

        if (!formData.reason_for_leaving) {
            toast.error('Reason for leaving is required');
            hasError = true;
        } 
        if (!formData.comment) {
            toast.error('Please provide a comment.');
            hasError = true;
        }
        
        if (hasError) {
            return;
        }
        const managerIDInt = parseInt(managerID, 10);
        try {
            setLoading(true);
            const requestData = {
                ...formData,
                manager_id: managerIDInt,
                status: "Submitted",
                created_by: admin,

            };
            const url = 'save-employee-details';
            const response = await OffboadingSaveEmployeeDetails(url, token, employeeId, requestData);
            setRefreshEmployeeInfo(prevState => !prevState);
            toast.success('Form data saved successfully!');
        } catch (error) {
            console.error('Error saving form data:', error);
            toast.error('Failed to save form data.');
        } finally {
            setButtonsDisabled(true);
            setLoading(false);
        }
    };

    if (!isActive) return null;

    return (
        <div className={styles.employee}>
            <div className={`${styles.grid} ${styles.relative}`}>
                <div className={styles['edit-bg']}></div>
                {!formData && (
                    <div className={styles['loaderMain-center']}>
                        <div className={styles['formsloader-container']}>
                            <div className={styles.newsloader}></div>
                        </div>
                    </div>
                )}
                {formData && (
                    <>
                        <OffBoadingEmployeeInfo token={token} employeeId={employeeId} refreshEmployeeInfo={refreshEmployeeInfo} />
                        <div className={styles.col9}>
                            <div className={`${styles['box-shadow']} ${styles['border-radius']} `}>
                                <fieldset className={styles.fieldset}>
                                    <form id={styles.basicINFO} name="basicINFO">
                                        <legend>Employee Details</legend>
                                        <div className={styles.grid} style={{ marginTop: "10px" }}>
                                            <div className={styles.col6}>
                                                <div className={styles['right-space']}>
                                                    <ul className={styles['two-col']}>
                                                        <li><strong>First Name<i className={styles['red']}>*</i>:</strong> <span><input type="text" name="first_name" className={styles.input} value={formData.first_name} onChange={handleInputChange} disabled/>
                                                        {firstNameError && <div className={styles['empl-error']}>{firstNameError}</div>}</span></li>
                                                        <li><strong>Last Name:</strong> <span><input type="text" name="last_name" className={styles.input} value={formData.last_name} onChange={handleInputChange} disabled/>
                                                        {lastNameError && <div className={styles['empl-error']}>{lastNameError}</div>}</span></li>
                                                        <li><strong>Manager Name:</strong> <span><input type="text" name="manager" className={styles.input} value={formData.manager} onChange={handleInputChange} disabled/>
                                                        {managerError && <div className={styles['empl-error']}>{managerError}</div>}</span></li>
                                                        <li><strong>Address<i className={styles['red']}>*</i>:</strong> <span><input type="text" name="street_address" className={styles.input} value={formData.street_address} onChange={handleInputChange} />
                                                        {streetAddressError && <div className={styles['empl-error']}>{streetAddressError}</div>}</span></li>
                                                        <li><strong>Department:</strong> <span><input type="text" name="department" className={styles.input} value={formData.department} onChange={handleInputChange} disabled/>
                                                        {departmentError && <div className={styles['empl-error']}>{departmentError}</div>}</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className={styles.col6}>
                                                <div className={styles['left-space']}>
                                                    <ul className={styles['two-col']}>
                                                        <li><strong>Designation:</strong>
                                                            <span>
                                                                <input type="text" name="job_title" className={styles.input} value={formData.job_title} onChange={handleInputChange} disabled/>
                                                                {titleError && <div className={styles['empl-error']}>{titleError}</div>}
                                                            </span>
                                                        </li>
                                                        <li><strong>Joining Date<i className={styles['red']}>*</i>:</strong> <span><DatePicker
                                                            selected={formData.date_of_joining}
                                                            onChange={handleStartDateChange}
                                                            peekNextMonth
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            className={styles.input}
                                                            id="date_of_joining"
                                                            name="date_of_joining"
                                                            autoComplete="off"
                                                            required
                                                            disabled
                                                        />
                                                        {dateOfJoiningError && <div className={styles['empl-error']}>{dateOfJoiningError}</div>}</span></li>
                                                        <li><strong>Relieving Date<i className={styles['red']}>*</i>:</strong> <span><DatePicker
                                                            selected={formData.releaving_date}
                                                            onChange={handleRelievingDateChange}
                                                            peekNextMonth
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            className={styles.input}
                                                            id="releaving_date"
                                                            name="releaving_date"
                                                            autoComplete="off"
                                                            required
                                                            
                                                        />
                                                         {relievingDateError && <div className={styles['empl-error']}>{relievingDateError}</div>}</span></li>
                                                        <li><strong>Personal Email Id<i className={styles['red']}>*</i>:</strong> <span><input type="email" name="personal_email_address" className={styles.input} value={formData.personal_email_address} onChange={handleInputChange} pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}" /> {emailError && <div className={styles['empl-error']}>{emailError}</div>}</span></li>
                                                        <li><strong>Reason for Leaving<i className={styles['red']}>*</i>:</strong> <span><textarea name="reason_for_leaving" className={styles.input} value={formData.reason_for_leaving} onChange={handleReasonChange} ></textarea>
                                                        {reasonForLeavingError && <div className={styles['empl-error']}>{reasonForLeavingError}</div>}</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className={styles['right-space']}>
                                                <ul className={styles['two-col']}>
                                                    <li><strong>Comment<i className={styles['red']}>*</i>:</strong> <span><textarea name="comment" className={styles.input} value={formData.comment} onChange={handleInputChange} ></textarea></span></li>
                                                </ul>
                                            </div>
                                            <div className={styles['button-block']}>
                                                <input type="submit" value="Save" className={styles.button} onClick={handleSaveInfo} disabled={loading || buttonsDisabled || !!emailError || !!phoneError || !!cellError} />
                                                <input type="submit" value={buttonsDisabled ? 'Submitted' : 'Submit'} className={styles.button} disabled={loading || !!emailError || buttonsDisabled || !!phoneError || !!cellError} onClick={handleSubmit} />
                                                <ToastContainer />
                                            </div>
                                        </div>
                                        <input type="hidden" name="created_by" className={styles.input} value={formData.created_by} />
                                    </form>
                                </fieldset>
                            </div>
                        </div>
                        {loading && <div className={`${styles['loader-center']} ${styles['loaderHeight-classification']} `}>
                            <div className={`${styles['newsloader-container']} ${styles['newsloaderContainer-classification']} `}>
                                <div className={styles.newsloader}></div></div>
                        </div>}
                    </>
                )}
            </div>
        </div>
    );
};

export default OffBoadingEmployeeDetails;
