import React, { useState, useRef, useEffect } from 'react';
import styles from '../Candidate/MainContent/Main.module.scss';
import Header from '../Candidate/Header/Header';
import Welcome from '../Candidate/MainContent/Welcome';
import GetFeedbackDetails from '../../api/FetchFeedback';
import SaveFeedbackData from '../../api/SaveFeedbackForm';
import { Helmet } from 'react-helmet-async';


const FeedbackForm = () => {
    const [isOptionSelected, setIsOptionSelected] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [showCommentBox, setShowCommentBox] = useState(false);
    const [showUnoutherizedModal, setShowUnoutherizedModal] = useState(false);
    const [showSuccessMessageModal, setShowSuccessMessageModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [userAnswers, setUserAnswers] = useState(Array(questions.length).fill(''));
    const [comment, setComment] = useState('');
    const [candidateId, setCandidateId] = useState(0);
    const [candidateData, setCandidateData] = useState(null);
    const [basicDetails, setcandidateName] = useState(null);



    // Function to fetch candidate details from API
    const fetchFeedbackDetails = async () => {
        const searchParams = new URLSearchParams(window.location.search);
        let token = searchParams.get('token');
        let candidate_id = searchParams.get('candidate_id');
        setCandidateId(candidate_id);
        if (!token) {
            setShowUnoutherizedModal(true);
            return;
        }
        try {
            const apiData = await GetFeedbackDetails(token, candidate_id);
            const { data } = apiData.data;
            const name = apiData.data.candidate_name;
            if (data) {
                if (Array.isArray(data)) {
                    setQuestions(data);
                }
            } else {
                setSuccessMessage(apiData.data.msg);
                setShowSuccessMessageModal(true);
            }
            setCandidateData('feedback');
            setcandidateName(name);
        } catch (error) {
            setShowUnoutherizedModal(true);
            console.error('Error fetching candidate details:', error);
        }
    };

    useEffect(() => {
        fetchFeedbackDetails();
    }, []);


    const handleNext = () => {
        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
            const nextQuestion = questions[currentQuestionIndex + 1];
            setIsOptionSelected(nextQuestion.question_type === 'comment' ? comment.trim() !== '' : !!userAnswers[currentQuestionIndex + 1]);
        } else {
            setShowCommentBox(true);
        }
    };

    const handlePrevious = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
            setIsOptionSelected(
                !!userAnswers[currentQuestionIndex - 1] ||
                (questions[currentQuestionIndex - 1].question_type === 'comment' && comment.trim() !== '')
            );
        }
    };


    const handleAnswerSelect = (option) => {
        const updatedUserAnswers = [...userAnswers];
        updatedUserAnswers[currentQuestionIndex] = option;
        setUserAnswers(updatedUserAnswers);

        const nextQuestion = questions[currentQuestionIndex + 1];
        setIsOptionSelected(option !== '' || (nextQuestion && nextQuestion.question_type === 'comment' && comment.trim() !== ''));
    };

    const handleSubmit = async () => {
        const feedbacks = [];
        for (let i = 0; i < questions.length; i++) {
            if (i === questions.length - 1) { // Last iteration
                feedbacks.push({
                    question_id: questions[i].id,
                    candidate_answer: comment.trim()
                });
            } else {
                feedbacks.push({
                    question_id: questions[i].id,
                    candidate_answer: userAnswers[i] || ''
                });
            }
        }



        const dataToSave = {
            candidate_id: candidateId,
            survey_id: 1,
            feedbacks: feedbacks,
        };

        try {
            const response = await SaveFeedbackData(dataToSave);


            if (response.status === 200) {
                setSuccessMessage('Survey submitted successfully');
                setShowSuccessMessageModal(true);
            } else {
                console.error('Error saving candidate data:', response.statusText);

            }
        } catch (error) {
            console.error('Error saving candidate data:', error);

        }

    };





    return (
        <>
       <Helmet>
                <title>Candidate Feedback Form</title>
                
            </Helmet>
            {showUnoutherizedModal && (
                <div id="myModal" className={styles.modal}>
                    <div className={styles['modal-content']}>
                        <p>You are not authorized to access this page.</p>
                    </div>
                </div>
            )}
            {showSuccessMessageModal && (
                            <div id="myModal" className={styles.modal}>
                                <div className={styles['modal-content']}>
                                    <p>{successMessage}</p>
                                </div>
                            </div>
                        )}
            {!showSuccessMessageModal && !showUnoutherizedModal && (
                <>
                    <Header candidateData={candidateData} />
                    <Welcome basicDetails={basicDetails} designationDetails="feedback" />
                    <div className={styles['feedback-container']}>
                        <div className={styles.main}>
                            {questions.length > 0 && questions.map((question, questionIndex) => (
                                <div className={styles.question} key={questionIndex} style={{ display: questionIndex === currentQuestionIndex ? 'block' : 'none' }}>
                                    {question.question_type === 'mcq' && (
                                        <span>Question {questionIndex + 1}</span>
                                    )}
                                    <h3>{question.question_title}</h3>
                                    {question.question_type === 'mcq' ? (
                                        <ul className={styles['ul-question']}>
                                            {[question.first_option, question.second_option, question.third_option, question.fourth_option].map((option, optionIndex) => (
                                                <li key={optionIndex}>
                                                    <input
                                                        type="checkbox"
                                                        id={`option_${questionIndex}_${optionIndex}`}
                                                        name={`question_${question.id}`}
                                                        value={option}
                                                        className={styles['feebback-ques']}
                                                        checked={userAnswers[questionIndex] === option}
                                                        onChange={() => handleAnswerSelect(option)}
                                                    />
                                                    <label htmlFor={`option_${questionIndex}_${optionIndex}`}>
                                                        <strong>{String.fromCharCode(65 + optionIndex)}</strong> <span>{option}</span>
                                                    </label>
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <div className={styles.question} style={{ display: 'block' }}>
                                            <form>
                                                <textarea
                                                    className={`${styles['input']} ${styles['textarea']}`}
                                                    value={comment}
                                                    onChange={(e) => {
                                                        setComment(e.target.value);
                                                        setIsOptionSelected(e.target.value.trim() !== '' || userAnswers[currentQuestionIndex] !== '');
                                                    }}
                                                    placeholder="Enter your comments here..."
                                                    required
                                                ></textarea>
                                            </form>
                                        </div>
                                    )}
                                    <div className={`${styles['align-center']} ${styles['btnContent']} `}>
                                        {currentQuestionIndex !== 0 && (
                                            <button className={`${styles['back']} ${styles['btnPrevious']}`} onClick={handlePrevious}>
                                                Previous
                                            </button>
                                        )}
                                        &nbsp;&nbsp;&nbsp;
                                        <button
                                            className={`${styles['btnContinue']} ${styles['btnNEXT']}`}
                                            onClick={currentQuestionIndex === questions.length - 1 ? handleSubmit : handleNext}
                                            disabled={!isOptionSelected}
                                        >
                                            {currentQuestionIndex === questions.length - 1 ? 'Submit' : 'Save & Next'}
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {/* Save & Next and Previous Button */}
                        
                    </div>
                </>
            )}
        </>
    );
}

export default FeedbackForm;
