import React, { useState, useEffect } from 'react';
import { useToken } from '../../../context/AuthContext'
import styles from '../MainContent/Main.module.scss';
import InputField from '../../../components/InputField/InputField';
import Button from '../../../components/Button/Button';
import Education from '../Details/Education';
import ProgressBar from './ProgressBar';
import DatePicker from 'react-datepicker';
import GetCandidateDetails from '../../../api/fetchCandidateData'
import SaveCandidateData from '../../../api/SaveCandidateData'
import StepEmitter from '../../../components/StepEmitter/StepEmitter'

const Experience = ({ step, setStep, isEdit, setIsEdit, handlePreviewClick, apiData }) => {
    const { token, zoho_candidate_id } = useToken();
    const [experienceCount, setExperienceCount] = useState(1); // Default to 1 experience
    const [showEducation, setShowEducation] = useState(false);
    const [experiData, setexperiData] = useState('');
    const [showRreview, setshowRreview] = useState(false);
    const totalSteps = 12;
    const nameRegex = /^[A-Za-z.-]+(\s*[A-Za-z.-]+)*$/;
    const [isCurrentlyWorking, setIsCurrentlyWorking] = useState(false);
    const [experiences, setExperiences] = useState([
        {
            Company_Name: '',
            Employer_Name: '',
            Start_Date: null,
            End_Date: null,
            Start_Salary: 0,
            Last_Salary: 0,
            Reason_For_Leaving: '',
            Currently_Working: false,
            error: false,
        },
    ]);

    useEffect(() => {
        setExperienceCount(experiences.length);
    }, [experiences]);

    const handleBackClick = () => {
        StepEmitter.emit('stepChange', step - 1);
    };



    const addExperience = () => {
        setExperienceCount(experienceCount + 1);
        setExperiences([
            ...experiences,
            {
                Company_Name: '',
                Employer_Name: '',
                Start_Date: null,
                End_Date: null,
                Start_Salary: 0,
                Last_Salary: 0,
                Reason_For_Leaving: '',
                Currently_Working: false,
                error: false,
            },
        ]);
    };

    const removeExperience = (index) => {
        setExperienceCount(experienceCount - 1);
        setExperiences(experiences.filter((_, i) => i !== index));
    };

    const handleInputChange = (index, field, value) => {
        const newExperiences = [...experiences];
        newExperiences[index][field] = value;
        if (field === 'Currently_Working') {
            newExperiences[index]['End_Date'] = value ? null : newExperiences[index]['End_Date']; 
        }
        setExperiences(newExperiences);
    };

    const handleDateChange = (date, index, field) => {
        const newExperiences = [...experiences];
        newExperiences[index][field] = date;
        setExperiences(newExperiences);
    };

    // Function to fetch candidate details from API
    const fetchCandidateDetails = async () => {
        try {
            const details = await GetCandidateDetails(token, zoho_candidate_id, step);
            const apiData = details.data;
            const work = apiData.basic_details.work_experience;
            setexperiData(work);
            if (apiData.candidate_professional_details && apiData.candidate_professional_details.length > 0) {
                setExperiences(apiData.candidate_professional_details);
            } else {
                setExperiences([
                    {
                        Company_Name: '',
                        Employer_Name: '',
                        Start_Date: null,
                        End_Date: null,
                        Start_Salary: 0,
                        Last_Salary: 0,
                        Reason_For_Leaving: '',
                        Currently_Working: false,
                        error: false,
                    },
                ]);
            }
        } catch {
            console.error('API has no data');
        }
    };

    useEffect(() => {
        fetchCandidateDetails();
    }, []);

    const validateForm = () => {
        let isValid = true;
        const newExperiences = [...experiences];
    
        newExperiences.forEach((experience, index) => {
            if (!experience.Currently_Working) {
              
                if (
                    !experience.Company_Name || !experience.Company_Name.trim() ||
                    !experience.Employer_Name || !experience.Employer_Name.trim() ||
                    !experience.Start_Date ||
                    !experience.End_Date ||
                    !experience.Start_Salary ||
                    experience.Start_Salary < 0 ||
                    !experience.Last_Salary ||
                    experience.Last_Salary < 0 ||
                    (!experience.Reason_For_Leaving || !experience.Reason_For_Leaving.trim())
                ) {
                    isValid = false;
                    newExperiences[index].error = true;
                } else {
                    newExperiences[index].error = false;
                }
            } else {
                
                if (
                    !experience.Company_Name || !experience.Company_Name.trim() ||
                    !experience.Employer_Name || !experience.Employer_Name.trim() ||
                    !experience.Start_Date ||
                    !experience.Start_Salary ||
                    experience.Start_Salary < 0 ||
                    !experience.Last_Salary ||
                    experience.Last_Salary < 0
                ) {
                    isValid = false;
                    newExperiences[index].error = true;
                } else {
                    newExperiences[index].error = false;
                }
            }
        });
    
        console.log(newExperiences);
        setExperiences(newExperiences);
        return isValid;
    };
    


    const formatDate = (dateObject) => {
        if (experiData === 'fresher') {
            return '';
        } else {

            if (Object.prototype.toString.call(dateObject) === "[object Date]" && !isNaN(dateObject.getTime())) {
                const year = dateObject.getFullYear();
                const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
                const day = dateObject.getDate().toString().padStart(2, '0');
                return `${year}-${month}-${day}`;
            } else {

                return '';
            }
        }
    };

    const handleContinueClick = async () => {
        if (experiData === 'fresher') {
            try {
                const experienceToSave = experiences.map(experience => {
                    let formattedStart_Date = experience.Start_Date;
                    let formattedEnd_Date = experience.End_Date;
                    if (typeof formattedStart_Date === 'string' && formattedStart_Date.match(/^\d{4}-\d{2}-\d{2}$/)) {
                    } else {
                        formattedStart_Date = formatDate(experience.Start_Date);
                    }

                    if (typeof formattedEnd_Date === 'string' && formattedEnd_Date.match(/^\d{4}-\d{2}-\d{2}$/)) {

                    } else {
                        formattedEnd_Date = formatDate(experience.End_Date);
                    }
                    return {
                        Company_Name: experience.Company_Name,
                        Employer_Name: experience.Employer_Name,
                        Start_Date: formattedStart_Date,
                        End_Date: formattedEnd_Date,
                        Start_Salary: experience.Start_Salary,
                        Last_Salary: experience.Last_Salary,
                        Currently_Working: experience.Currently_Working,
                        Reason_For_Leaving: experience.Reason_For_Leaving
                    };
                });
                const dataToSave = {
                    candidate_professional_details: experienceToSave,
                    Zoho_Item_Id: zoho_candidate_id,
                    Process_Status: "8"
                };
                const response = await SaveCandidateData(dataToSave);
                if (response.status === 200) {
                    setShowEducation(true);
                    StepEmitter.emit('stepChange', step + 1);
                } else {
                    console.error('Error saving candidate data:', response.statusText);

                }
            } catch (error) {
                console.error('Error saving candidate data:', error);

            }
        }
        else {
            if (validateForm()) {
                try {
                    const experienceToSave = experiences.map(experience => {
                        let formattedStart_Date = experience.Start_Date;
                        let formattedEnd_Date = experience.End_Date;
                        if (typeof formattedStart_Date === 'string' && formattedStart_Date.match(/^\d{4}-\d{2}-\d{2}$/)) {
                        } else {
                            formattedStart_Date = formatDate(experience.Start_Date);
                        }
                        if (typeof formattedEnd_Date === 'string' && formattedEnd_Date.match(/^\d{4}-\d{2}-\d{2}$/)) {

                        } else {
                            formattedEnd_Date = formatDate(experience.End_Date);
                        }

                        return {
                            Company_Name: experience.Company_Name,
                            Employer_Name: experience.Employer_Name,
                            Start_Date: formattedStart_Date,
                            End_Date: formattedEnd_Date,
                            Start_Salary: experience.Start_Salary,
                            Last_Salary: experience.Last_Salary,
                            Currently_Working: experience.Currently_Working,
                            Reason_For_Leaving: experience.Reason_For_Leaving
                        };
                    });


                    const dataToSave = {
                        candidate_professional_details: experienceToSave,
                        Zoho_Item_Id: zoho_candidate_id,
                        Process_Status: "8"
                    };
                    const response = await SaveCandidateData(dataToSave);


                    if (response.status === 200) {
                        setShowEducation(true);
                        StepEmitter.emit('stepChange', step + 1);
                    } else {
                        console.error('Error saving candidate data:', response.statusText);

                    }
                } catch (error) {
                    console.error('Error saving candidate data:', error);

                }
            }
        }
    };

    const handlePreviewPage = async () => {
        if (experiData === 'fresher') {
            try {
                const experienceToSave = experiences.map(experience => {
                    let formattedStart_Date = experience.Start_Date;
                    let formattedEnd_Date = experience.End_Date;

                    // Check if Start_Date is a string in the expected format
                    if (typeof formattedStart_Date === 'string' && formattedStart_Date.match(/^\d{4}-\d{2}-\d{2}$/)) {
                        // Date is already in the expected format, no need to format it again
                    } else {
                        formattedStart_Date = formatDate(experience.Start_Date);
                    }

                    if (typeof formattedEnd_Date === 'string' && formattedEnd_Date.match(/^\d{4}-\d{2}-\d{2}$/)) {

                    } else {
                        formattedEnd_Date = formatDate(experience.End_Date);
                    }

                    return {
                        Company_Name: experience.Company_Name,
                        Employer_Name: experience.Employer_Name,
                        Start_Date: formattedStart_Date,
                        End_Date: formattedEnd_Date,
                        Start_Salary: experience.Start_Salary,
                        Last_Salary: experience.Last_Salary,
                        Currently_Working: experience.Currently_Working,
                        Reason_For_Leaving: experience.Reason_For_Leaving
                    };
                });


                const dataToSave = {
                    candidate_professional_details: experienceToSave,
                    Zoho_Item_Id: zoho_candidate_id,
                    Process_Status: "8"
                };
                const response = await SaveCandidateData(dataToSave);


                if (response.status === 200) {
                    setshowRreview(true);

                    setStep(11);
                    StepEmitter.emit('stepChange', 11);
                } else {
                    console.error('Error saving candidate data:', response.statusText);

                }
            } catch (error) {
                console.error('Error saving candidate data:', error);

            }

        }
        else {
            if (validateForm()) {


                try {
                    const experienceToSave = experiences.map(experience => {
                        let formattedStart_Date = experience.Start_Date;
                        let formattedEnd_Date = experience.End_Date;

                        // Check if Start_Date is a string in the expected format
                        if (typeof formattedStart_Date === 'string' && formattedStart_Date.match(/^\d{4}-\d{2}-\d{2}$/)) {
                            // Date is already in the expected format, no need to format it again
                        } else {
                            formattedStart_Date = formatDate(experience.Start_Date);
                        }

                        if (typeof formattedEnd_Date === 'string' && formattedEnd_Date.match(/^\d{4}-\d{2}-\d{2}$/)) {

                        } else {
                            formattedEnd_Date = formatDate(experience.End_Date);
                        }

                        return {
                            Company_Name: experience.Company_Name,
                            Employer_Name: experience.Employer_Name,
                            Start_Date: formattedStart_Date,
                            End_Date: formattedEnd_Date,
                            Start_Salary: experience.Start_Salary,
                            Last_Salary: experience.Last_Salary,
                            Reason_For_Leaving: experience.Reason_For_Leaving
                        };
                    });


                    const dataToSave = {
                        candidate_professional_details: experienceToSave,
                        Zoho_Item_Id: zoho_candidate_id,
                        Process_Status: "8"
                    };
                    const response = await SaveCandidateData(dataToSave);


                    if (response.status === 200) {
                        setshowRreview(true);

                        setStep(11);
                        StepEmitter.emit('stepChange', 11);
                    } else {
                        console.error('Error saving candidate data:', response.statusText);

                    }
                } catch (error) {
                    console.error('Error saving candidate data:', error);

                }
            }
        }
    };


    const renderExperiences = () => { 
        return experiences.map((experience, index) => (
            <div key={index} className={styles['experience-container']}>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <strong className={styles.strong}>
                                    Name of the company{experiData !== 'fresher' && <i className={styles.red}>*</i>}:
                                </strong>
                                <InputField
                                    type="text"
                                    id={`Company_Name_${index}`}
                                    name={`Company_Name_${index}`}
                                    className={`${styles.input} ${experience.error && (!experience.Company_Name || !experience.Company_Name.trim()) ? styles.error : ''}`}
                                    required
                                    mainClass={styles['div-right']}
                                    value={experience.Company_Name}
                                    error={(experience.error && (!experience.Company_Name || !experience.Company_Name.trim()))}
                                    onChange={(e) => handleInputChange(index, 'Company_Name', e.target.value)}
                                />
                            </td>
                            <td>
                                <strong className={styles.strong}>
                                    Name of the manager/employer{experiData !== 'fresher' && <i className={styles.red}>*</i>}:
                                </strong>
                                <InputField
                                    type="text"
                                    id={`Employer_Name_${index}`}
                                    name={`Employer_Name_${index}`}
                                    className={`${styles.input} ${experience.error && (!experience.Employer_Name || !experience.Employer_Name.trim()) ? styles.error : ''}`}
                                    required
                                    mainClass={styles['div-right']}
                                    value={experience.Employer_Name}
                                    error={(experience.error && (!experience.Employer_Name || !experience.Employer_Name.trim()))}
                                    onChange={(e) => handleInputChange(index, 'Employer_Name', e.target.value)}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong className={styles.strong}>Period{experiData !== 'fresher' && <i className={styles.red}>*</i>}:</strong>
                                <div className={styles['div-right']}>
                                    <div className={styles['row-c']}>
                                        <DatePicker
                                            selected={experience.Start_Date}
                                            onChange={(date) => handleDateChange(date, index, 'Start_Date')}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            className={`${experience.error && !experience.Start_Date ? styles.error : ''} ${styles.input} ${styles.dateTimePicker}`}
                                            id={`Start_Date_${index}`}
                                            name={`Start_Date_${index}`}
                                            autoComplete="off"
                                            required
                                            placeholderText="From"
                                        />
                                        <div style={{ display: "inline-block", width: "60%" }}>
                                        <DatePicker
                                            selected={experience.End_Date}
                                            onChange={(date) => handleDateChange(date, index, 'End_Date')}
                                            disabled={experience.Currently_Working}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            className={`${experience.error && !experience.End_Date && !experience.Currently_Working ? styles.error : ''} ${styles.input} ${styles.dateTimePicker}`}
                                            id={`End_Date_${index}`}
                                            name={`End_Date_${index}`}
                                            autoComplete="off"
                                            required={!experience.Currently_Working}
                                            placeholderText="To"
                                        />
                                        </div>
                                        <div style={{ display: "inline-block" }}>
                                            <label className={styles.checkbox}>Till date
                                                <input
                                                    type="checkbox"
                                                    className={styles.declare}
                                                    id={`Currently_Working_${index}`}
                                                    name={`Currently_Working_${index}`}
                                                    checked={experience.Currently_Working}
                                                    onChange={(e) => handleInputChange(index, 'Currently_Working', e.target.checked)}
                                                />
                                                <span className={styles.checkmark}></span>
                                            </label>
                                        </div>
                                        <div className={styles.clear}></div>
                                    </div>
                                </div>
                            </td>
                            <td colSpan="2">
                                <strong className={styles.strong}>
                                    Salary Drawn{experiData !== 'fresher' && <i className={styles.red}>*</i>}
                                    : <span className={styles['span-small']}>(In lakh per annum)</span>
                                </strong>
                                <div className={styles['div-right']}>
                                    <div className={styles['row-c']}>
                                        <InputField
                                            type="number"
                                            id={`Start_Salary_${index}`}
                                            name={`Start_Salary_${index}`}
                                            className={styles.input}
                                            placeholder="Start"
                                            required
                                            mainClass={styles['td-left']}
                                            value={experience.Start_Salary}
                                            error={(experience.error && !experience.Start_Salary) ? "This field is required" : (experience.Start_Salary && experience.Start_Salary < 0) ? "Salary cannot be negative" : ''}
                                            onChange={(e) => handleInputChange(index, 'Start_Salary', e.target.value)}
                                            min="0"
                                        />
                                        <InputField
                                            type="number"
                                            id={`Last_Salary_${index}`}
                                            name={`Last_Salary_${index}`}
                                            className={styles.input}
                                            placeholder="Last"
                                            required
                                            mainClass={styles['td-right']}
                                            value={experience.Last_Salary}
                                            error={(experience.error && !experience.Last_Salary) ? "This field is required" : (experience.Last_Salary && experience.Last_Salary < 0) ? "Salary cannot be negative" : ''}
                                            onChange={(e) => handleInputChange(index, 'Last_Salary', e.target.value)}
                                            min="0"
                                        />
                                        <div className={styles.clear}></div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2">
                                <strong className={styles.strong}>Reason for Leaving{experiData !== 'fresher' && <i className={styles.red}>*</i>}
                                    :</strong>
                                <div className={styles['div-right']}>
                                    <textarea
                                        id={`Reason_For_Leaving_${index + 1}`}
                                        name={`Reason_For_Leaving_${index + 1}`}
                                        className={`${styles.input} ${styles.textarea} ${experience.error && (!experience.Reason_For_Leaving || !experience.Reason_For_Leaving.trim()) ? styles.error : ''}`}
                                        disabled={experience.Currently_Working}
                                        required
                                        value={experience.Reason_For_Leaving}
                                        onChange={(e) => handleInputChange(index, 'Reason_For_Leaving', e.target.value)}
                                    ></textarea>
                                </div>
                            </td>
                        </tr>
                        {index >= 1 && (
                            <tr>
                                <td colSpan="2" className={`${styles.colspan} ${styles['align-center']}`}>
                                    <input type="button" value="Remove" className={styles.remove} onClick={() => removeExperience(index)} />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        ));
    };
    

    return (
        <>
            {step === 7 && (
                <>
                    <ProgressBar currentStep={step} totalSteps={totalSteps} />
                    <div className={styles['required-details']}>
                        Kindly fill the required details <span>*</span> of the form below.
                    </div>
                    <h4 className={styles.heading1} style={{ display: step === 7 ? 'block' : 'none' }}>
                        <span>PROFESSIONAL/INTERNSHIP EXPERIENCE</span>
                    </h4>
                    <div className={`${styles['row-c']} ${styles.width} ${styles.professionalEXP}`} style={{ display: step === 7 ? 'block' : 'none' }}>
                        <form id="professionalEXP" name="professionalEXP">
                            <div className={styles.experience}>
                                {renderExperiences()}
                                <div id="moreexperience"></div>
                                <div className={styles['align-center']}>
                                    <input id="addexperience" className={styles.BTNadd2} type="button" value="Add" onClick={addExperience} />
                                </div>
                            </div>
                        </form>
                        <Button
                            backButtonLabel="Back"
                            continueButtonLabel="Save & Step 4 →"
                            onBackClick={handleBackClick}
                            onContinueClick={handleContinueClick}
                            backButtonClass={styles['custom-back-class']}
                            continueButtonClass={styles['custom-continue-class']}
                            previewButtonLabel={isEdit ? "Preview" : ""}
                            previewButtonAction={isEdit ? handlePreviewPage : ''}
                        />

                    </div>

                </>
            )}
            {showEducation && <Education step={step} setStep={setStep} isEdit={isEdit} setIsEdit={setIsEdit} handlePreviewClick={handlePreviewClick} apiData={apiData} />}
        </>
    );
};

export default Experience;

