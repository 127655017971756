import React, { useState, useEffect } from 'react'
import { useToken } from '../../../context/AuthContext'
import styles from './Main.module.scss';
import GetCandidateDetails from '../../../api/fetchCandidateData'

const Welcome = ({ basicDetails, designationDetails, stepNumber }) => {
  const { token, zoho_candidate_id } = useToken();
  let detail = "";
  let info ="";
  if (stepNumber === 1 || stepNumber === 2 || stepNumber === 3 || stepNumber === 4) {
    detail = "Basic Details";
    info ="then fill the form";
  }
  if (stepNumber === 5 || stepNumber === 6) {
    detail = "Family/Reference";
    info ="then fill the form";
  }

  if (stepNumber === 7) {
    detail = "Professional/Experience";
    info ="then fill the form";
  }

  if (stepNumber === 8 || stepNumber === 9 || stepNumber === 10) {
    detail = "Education/Awards";
    info ="then fill the form";
  }
  if (stepNumber === 11 || stepNumber === 12) {
    detail = "Other/Information";
    info ="then fill the form";
  }
  if (stepNumber === 13) {
    detail = "Preview ";
    info ="and check your filled information";
  }


  return (
    <div className={styles.main}>
      <div className={styles['application-container']}>
        <div className={styles['col-left70']}>
          <div className={styles.welcome}>
            <div className={styles['welcome-left']}>
              <p>Welcome to <strong id="fullName">{basicDetails}</strong></p>
              {designationDetails === 'feedback' ? (
                <p>Thanks for your feedback</p>
              ) : (
                <p>Thanks for applying for the job <strong>{designationDetails}</strong></p>
              )}
            </div>
            {designationDetails === 'feedback' ? (
              <div className={styles['welcome-right']}>
                <p><b>Instructions to Candidate:</b></p>
                <p>Please submit all questions</p>
              </div>
            ) : (
              <div className={styles['welcome-right']}>
                <p><b>Instructions to Candidate:</b></p>
                <p>Please click to <b>{detail}</b> {info}</p>
              </div>
            )}
            <div className={styles.clear}></div>
          </div>
        </div>

        <div className={styles.clear}></div>
      </div>
    </div>
  );
};

export default Welcome;
