import React from 'react';

const SelectField = ({ id, name, className, options, value, onChange, defaultValue, disabled, disabledOptions }) => {
  const selectedValue = value ? value : defaultValue; // Conditionally set the selected value
  
  return (
    <select id={id} name={name} className={className} value={selectedValue} onChange={onChange} disabled={disabled}>
      <option value="" disabled hidden>{defaultValue}</option>
      {options.map(option => (
        <option key={option.value} value={option.value} disabled={disabledOptions && disabledOptions.includes(option.value)}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default SelectField;
