import axios from 'axios';

const OnboadingClassificationEmployeeDetails = async (url, token, employeeId, formDataToSave) => {
    try {
        const response = await axios.post(
            process.env.REACT_APP_API_URL + 'api/onboarding/' + url, 
            {
                ...formDataToSave 
            },
            {
                headers: {
                    'token': token,
                    'accept': 'application/json',
                },
            }
        );

        return response.data;
    } catch (error) {
        console.error('Error saving form data:', error);
        throw error; 
    }
};

export default OnboadingClassificationEmployeeDetails;
