import React, { useState, useEffect } from 'react'
import { useToken } from '../../../context/AuthContext'
import InputField from '../../../components/InputField/InputField'
import SelectField from '../../../components/SelectField/SelectField'
import Button from '../../../components/Button/Button'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import ProgressBar from './ProgressBar'
import SaveCandidateData from '../../../api/SaveCandidateData'
import Gender from '../Details/GenderDetails'
import StepEmitter from '../../../components/StepEmitter/StepEmitter'
import styles from '../MainContent/Main.module.scss'
import GetCandidateDetails from '../../../api/fetchCandidateData'

const Basic = ({ isEdit, setIsEdit, handlePreviewClick, apiData }) => {
    const nameRegex = /^[A-Za-z]+$/;
    const [step, setStep] = useState(2);
    const [showGender, setShowGender] = useState(false);
    const [showRreview, setshowRreview] = useState(false);
    const { token, zoho_candidate_id } = useToken();
    const [basicData, setbasicData] = useState(null);
   
    const totalSteps = 12;
    const [formData, setFormData] = useState({
        First_Name: '',
        Middle_Name: '',
        Last_Name: '',
        Height: '',
        Weight: '',
        Date_Of_Birth: '',
        Blood_Group: '',
        Identification_Marks: '',
        work_experience: '',
        emergency_contact_number: '',
        emergency_contact_relation: '',
        emergency_contact_name: '',
        Email: '',
        Emails: []
        

    });
    const [errors, setErrors] = useState({
        First_Name: '',
        Last_Name: '',
        Date_Of_Birth: '',
        Email: '',


    });
    const [emailCount, setEmailCount] = useState(formData.Emails.length);

    const handleAddEmail = () => {

        if (emailCount < 3) {
            setEmailCount(prevCount => prevCount + 1);
            setFormData(prevFormData => ({
                ...prevFormData,
                Emails: [...prevFormData.Emails, ''] // Add a new empty email field
            }));
        }
    };


    const handleRemoveEmail = (index) => {
        const updatedEmails = [...formData.Emails];
        updatedEmails.splice(index, 1);

        setFormData(prevFormData => ({
            ...prevFormData,
            Emails: updatedEmails
        }));

        setEmailCount(prevCount => prevCount - 1);
    };



    const handleEmailChange = (value, index) => {
        const updatedEmails = [...formData.Emails];
        updatedEmails[index] = value;

        setFormData(prevFormData => ({
            ...prevFormData,
            Emails: updatedEmails
        }));
    };

    const renderEmailInputs = () => {
        return formData.Emails.slice(1).map((email, index) => (
            <div key={index + 1}>
                <input
                    type="email"
                    className={styles.input}
                    name={`Email${index + 2}`}
                    value={email}
                    onChange={(e) => handleEmailChange(e.target.value, index + 1)}
                />
                <input
                    type="button"
                    value="Remove"
                    className={styles.remove}
                    onClick={() => handleRemoveEmail(index + 1)}
                />
            </div>
        ));
    };



    const fetchCandidateDetails = async () => {
        try {
            const apiData = await GetCandidateDetails(token, zoho_candidate_id, step);

            const basicDetails = apiData.data.basic_details;
            const emailFields = Object.keys(basicDetails).filter(key => key.startsWith('Email'));
            const emails = emailFields.map(key => basicDetails[key]).filter(email => email !== null && email !== '');
            
            setFormData({
                ...formData,
                First_Name: basicDetails.First_Name || '',
                Middle_Name: basicDetails.Middle_Name || '',
                Last_Name: basicDetails.Last_Name || '',
                Height: basicDetails.Height || '',
                Weight: basicDetails.Weight || '',
                Date_Of_Birth: basicDetails.Date_Of_Birth || '',
                Blood_Group: basicDetails.Blood_Group || '',
                Identification_Marks: basicDetails.Identification_Marks || '',
                Email: basicDetails.Email || '',
                Emails: emails,
                work_experience: basicDetails.work_experience || '',
                emergency_contact_number: basicDetails.emergency_contact_number || '',
                emergency_contact_relation: basicDetails.emergency_contact_relation || '',
                emergency_contact_name: basicDetails.emergency_contact_name || '',
            });
            
            setbasicData(basicDetails);
            setEmailCount(emails.length);
        }
        catch (error) {
            console.error('Error fetching candidate details:', error);

        }

    };


    useEffect(() => {
        fetchCandidateDetails();
    }, [apiData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (value < 0) {
            return; // Do not update state for negative values
        }
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
        const newErrors = { ...errors };
        if (!value.trim()) {
            newErrors[name] = 'This field is required.';
        } else {
            delete newErrors[name];
        }
        setErrors(newErrors);
    };

    const handleInputDateChange = (date) => {
        setErrors({ ...errors, date_of_birth: '' });
        setFormData({ ...formData, date_of_birth: date });
    }
    const validateForm = () => {
        let isValid = true;
        const newErrors = {};
        if (!formData.First_Name.trim()) {
            newErrors.First_Name = 'This field is required.';
            isValid = false;
        }
        else if (!nameRegex.test(formData.First_Name.trim())) {
            newErrors.First_Name = 'Name should be characters.';
            isValid = false;
        }
        if (formData.Middle_Name.trim() !== '') {
            if (!nameRegex.test(formData.Middle_Name.trim())) {
                newErrors.Middle_Name = 'Middle name should only contain characters.';
                isValid = false;
            }
        }
        if (!formData.Last_Name.trim()) {
            newErrors.Last_Name = 'This field is required.';
            isValid = false;
        }
        else if (!nameRegex.test(formData.Last_Name.trim())) {
            newErrors.Last_Name = 'Name should be characters.';
            isValid = false;
        }
        if (!formData.emergency_contact_name.trim()) {
            newErrors.emergency_contact_name = 'This field is required.';
            isValid = false;
        }
        if (!formData.emergency_contact_relation.trim()) {
            newErrors.emergency_contact_relation = 'This field is required.';
            isValid = false;
        }
        if (!formData.Date_Of_Birth || formData.Date_Of_Birth.toString() === 'Invalid Date') {
            newErrors.Date_Of_Birth = 'This field is required.';
            isValid = false;
        }
        if (!formData.work_experience.trim() || formData.work_experience === "-1") {
            newErrors.work_experience = 'This field is required.';
            isValid = false;
        }
        if (!formData.emergency_contact_number.trim() || formData.emergency_contact_relation === "-1") {
            newErrors.emergency_contact_number = 'This field is required.';
            isValid = false;
        }else if (formData.emergency_contact_number < 0) {
            newErrors.emergency_contact_number = 'Contact Number should be positive.';
            isValid = false;
        }
        if (!formData.Email.trim()) {
            newErrors.Email = 'This field is required.';
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(formData.Email.trim())) {
            newErrors.Email = 'Invalid email format.';
            isValid = false;
        }
        if (formData.Height < 0) {
            newErrors.Height = 'Height must be a positive value.';
            isValid = false;
        }
        if (formData.Weight < 0) {
            newErrors.Weight = 'Weight must be a positive value.';
            isValid = false;
        }

        console.log(newErrors);
        setErrors(newErrors);
        return isValid;
    };



    const formatDate = (dateObject) => {
        const year = dateObject.getFullYear();
        const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
        const day = dateObject.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handleContinueClick = async () => {


        if (validateForm()) {
            try {
                const bloodGroupValue = formData.Blood_Group === '-1' ? '' : formData.Blood_Group;
                let formattedDateOfBirth = formData.Date_Of_Birth;
                if (typeof formattedDateOfBirth === 'string' && formattedDateOfBirth.match(/^\d{4}-\d{2}-\d{2}$/)) {
                    // Date is already in the expected format, no need to format it again
                } else {
                    formattedDateOfBirth = formatDate(formData.Date_Of_Birth);
                }
                const Emails = formData.Emails.slice(1).join(', ');
                const [Email2, Email3] = formData.Emails.slice(1, 3);

                const dataToSave = {
                    basic_details: {
                        ...formData,
                        Date_Of_Birth: formattedDateOfBirth,
                        Blood_Group: bloodGroupValue,
                        Marital_Status: basicData.Marital_Status || '',
                        Zoho_Candidate_Id: zoho_candidate_id,
                        Zoho_Item_Id: zoho_candidate_id,
                        Job_Opening_Id: zoho_candidate_id,
                        FormStatus: 'Pending',
                        Email2,
                        Email3
                    },
                    Zoho_Item_Id: zoho_candidate_id,
                    Process_Status: "4"
                };
                //console.log(dataToSave);
                const response = await SaveCandidateData(dataToSave);
                

                if (response.status === 200) {
                    setShowGender(true);
                    setStep(3);
                    StepEmitter.emit('stepChange', 3);
                } else {
                    console.error('Error saving candidate data:', response.statusText);

                }
            } catch (error) {
                console.error('Error saving candidate data:', error);

            }
        }
    };

    const handlePreviewPage = async () => {
        if (validateForm()) {
            try {
                //console.log(formData);
                let formattedDateOfBirth = formData.Date_Of_Birth;
                if (typeof formattedDateOfBirth === 'string' && formattedDateOfBirth.match(/^\d{4}-\d{2}-\d{2}$/)) {
                    // Date is already in the expected format, no need to format it again
                } else {
                    formattedDateOfBirth = formatDate(formData.Date_Of_Birth);
                }
                const Emails = formData.Emails.slice(1).join(', ');
                const [Email2, Email3] = formData.Emails.slice(1, 3);

                const dataToSave = {
                    basic_details: {
                        ...formData,
                        Date_Of_Birth: formattedDateOfBirth,
                        Marital_Status: basicData.Marital_Status || '',
                        Zoho_Candidate_Id: zoho_candidate_id,
                        Zoho_Item_Id: zoho_candidate_id,
                        Job_Opening_Id: zoho_candidate_id,
                        FormStatus: 'Pending',
                        Email2,
                        Email3
                    },
                    Zoho_Item_Id: zoho_candidate_id,
                    Process_Status: "4"
                };
                //console.log(dataToSave);
                const response = await SaveCandidateData(dataToSave);
               

                if (response.status === 200) {
                    setshowRreview(true);

                    setStep(11);
                    StepEmitter.emit('stepChange', 11);
                } else {
                    console.error('Error saving candidate data:', response.statusText);

                }
            } catch (error) {
                console.error('Error saving candidate data:', error);

            }
        }
    };


    const bloodGroups = [
        { value: ' ', label: 'Select Blood Group' },
        { value: 'A+', label: 'A Positive' },
        { value: 'A-', label: 'A Negative' },
        { value: 'B+', label: 'B Positive' },
        { value: 'B-', label: 'B Negative' },
        { value: 'AB+', label: 'AB Positive' },
        { value: 'AB-', label: 'AB Negative' },
        { value: 'O+', label: 'O Positive' },
        { value: 'O-', label: 'O Negative' }
    ];
    const work_experience = [
        { value: ' ', label: 'Select Work Experience' },
        { value: 'experienced', label: 'Experienced' },
        { value: 'fresher', label: 'Fresher' },

    ]

    const emergency_contact_relation =[
        { value: ' ', label: 'Select Emergency Contact relation' },
        { value: 'Spouse', label: 'Spouse' },
        { value: 'Father', label: 'Father' },
        { value: 'Mother', label: 'Mother' },
        { value: 'Brother', label: 'Brother' },
        { value: 'Sister', label: 'Sister' },
    ]

    return (
        <>

            {step === 2 && (
                <>
                    <ProgressBar currentStep={step} totalSteps={totalSteps} />
                    <div className={styles['required-details']}>Kindly fill the required details <span>*</span> of the form below.</div>
                    <h4 className={styles.heading1} style={{ display: step === 2 ? 'block' : 'none' }}><span>Basic Details</span></h4>
                    <div className={`${styles['row-c']} ${styles.width} ${styles.basicINFO}`} >
                        <form id={styles.basicINFO} name="basicINFO">
                            <div className={styles['col-left']}>
                                <ul>
                                    <li> <span className={styles.span}>First Name<i className={styles.red}>*</i>:</span>
                                        <InputField
                                            type="text"
                                            id="First_Name"
                                            name="First_Name"
                                            value={formData.First_Name}
                                            error={errors.First_Name}
                                            onChange={(e) => {
                                                handleInputChange(e);
                                                setFormData({ ...formData, First_Name: e.target.value });
                                            }}

                                            required
                                            aria-required="true"
                                            hidden={true}
                                            mainClass={styles.div}
                                        />
                                    </li>
                                    <li> <span className={styles.span}>Middle Name:</span>
                                        <InputField
                                            type="text"
                                            id="Middle_Name"
                                            name="Middle_Name"
                                            value={formData.Middle_Name}
                                            error={errors.Middle_Name}
                                            onChange={(e) => setFormData({ ...formData, Middle_Name: e.target.value })}
                                            mainClass={styles.div}
                                        />
                                    </li>
                                    <li> <span className={styles.span}>Last Name<i className={styles.red}>*</i>:</span>
                                        <InputField
                                            type="text"
                                            id="Last_Name"
                                            name="Last_Name"
                                            value={formData.Last_Name}
                                            error={errors.Last_Name}
                                            onChange={(e) => {
                                                handleInputChange(e);
                                                setFormData({ ...formData, Last_Name: e.target.value });
                                            }}
                                            required
                                            mainClass={styles.div}
                                        />
                                    </li>
                                    <li> <span className={styles.span}>Height: <span className={styles['span-small']}>(In cm)</span> </span>
                                        <InputField
                                            type="number"
                                            id="Height"
                                            name="Height"
                                            max="10"
                                            value={formData.Height}
                                            error={errors.Height}
                                            onChange={(e) => {
                                                handleInputChange(e);
                                                setFormData({ ...formData, Height: e.target.value });
                                            }}
                                            mainClass={styles.div}
                                            min="0"
                                        />
                                    </li>
                                    <li> <span className={styles.span}>Weight: <span className={styles['span-small']}>(In Kgs)</span> </span>
                                        <InputField
                                            type="number"
                                            id="Weight"
                                            name="Weight"
                                            max="200"
                                            value={formData.Weight}
                                            error={errors.Weight}
                                            onChange={(e) => {
                                                handleInputChange(e);
                                                setFormData({ ...formData, Weight: e.target.value });
                                            }}
                                            mainClass={styles.div}
                                            min="0"
                                        />
                                    </li>
                                    <li>
                                        <span className={styles.span}>Emergency Contact Name<i className={styles.red}>*</i>:</span>

                                        <InputField
                                            type="text"
                                            id={`emergency_contact_name`}
                                            name={`emergency_contact_name`}
                                            className={styles.input}
                                            error={errors.emergency_contact_name}
                                            mainClass={styles['div-right']}
                                            value={formData.emergency_contact_name}
                                            onChange={(e) => {
                                                handleInputChange(e);
                                                setFormData({ ...formData, emergency_contact_name: e.target.value });
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <span className={styles.span}>Emergency Contact Relation<i className={styles.red}>*</i>: </span>
                                        
                                        <SelectField
                                            id="emergency_contact_relation"
                                            name="emergency_contact_relation"
                                            className={`${styles.select} ${errors.emergency_contact_relation ? styles.error : styles.valid}`}
                                            value={formData.emergency_contact_relation}
                                            options={emergency_contact_relation}
                                            onChange={(e) => setFormData({ ...formData, emergency_contact_relation: e.target.value })}
                                            defaultValue={formData.emergency_contact_relation ? formData.emergency_contact_relation : "Select Work Experience"}
                                        />
                                    </li>
                                </ul>
                            </div>
                            <div className={styles['col-right']}>
                                <ul>
                                    <li>
                                        <span className={styles.span}>Date of Birth<i className={styles.red}>*</i>: <span className={styles['span-small>']}>(mm/dd/yyyy)</span> </span>
                                        <div className={` ${styles.divDatePicker}`}>
                                            <DatePicker
                                                selected={formData.Date_Of_Birth || null}
                                                onChange={(date) => {
                                                    handleInputDateChange(date);
                                                    setFormData({ ...formData, Date_Of_Birth: date });
                                                }}
                                                //isClearable
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                className={`${errors.Date_Of_Birth ? styles.error : styles.inputNoError} ${styles.input} ${styles.dateTimePicker}`}
                                                id="Date_Of_Birth"
                                                name="Date_Of_Birth"
                                                autoComplete="off"
                                                required
                                                error={errors.Date_Of_Birth}

                                            />
                                            {errors.Date_Of_Birth && <label id="date_of_birth-error" className={styles.error} htmlFor="date_of_birth" style={{ display: 'inline' }}>This field is required.</label>}
                                        </div>
                                    </li>
                                    <li> <span className={styles.span}>Blood Group:</span>
                                        <SelectField
                                            id="Blood_Group"
                                            name="Blood_Group"
                                            className={`${styles.select} ${styles.valid}`}
                                            value={formData.Blood_Group}
                                            options={bloodGroups}
                                            onChange={(e) => setFormData({ ...formData, Blood_Group: e.target.value })}
                                            defaultValue={formData.Blood_Group ? formData.Blood_Group : "Select Blood Group"}
                                        />

                                    </li>
                                    <li> <span className={styles.span}>Identification Marks:</span>
                                        <InputField
                                            type="text"
                                            id="Identification_Marks"
                                            name="Identification_Marks"
                                            className={styles.input}
                                            value={formData.Identification_Marks}
                                            onChange={(e) => {
                                                handleInputChange(e);
                                                setFormData({ ...formData, Identification_Marks: e.target.value });
                                            }}
                                            required
                                            mainClass={styles.div}
                                            min="0"
                                        />
                                    </li>
                                    <li> <span className={styles.span}>Email Id<i className={styles.red}>*</i>: </span>
                                        <InputField
                                            type="email"
                                            id="Email"
                                            name="Email"
                                            className={styles.input}
                                            addButton={true}
                                            containerId="TextBoxContainer"
                                            handleAddEmail={handleAddEmail}
                                            renderEmailInputs={renderEmailInputs}
                                            value={formData.Email}
                                            error={errors.Email}
                                            onChange={(e) => {
                                                handleInputChange(e);
                                                setFormData({ ...formData, Email: e.target.value });
                                            }}
                                            mainClass={styles.div}
                                        />
                                    </li>
                                    <li>
                                        <span className={styles.span}>Experience/Fresher<i className={styles.red}>*</i>:</span>
                                        <SelectField
                                            id="work_experience"
                                            name="work_experience"
                                            className={`${styles.select} ${errors.work_experience ? styles.error : styles.valid}`}
                                            value={formData.work_experience}
                                            options={work_experience}
                                            onChange={(e) => setFormData({ ...formData, work_experience: e.target.value })}
                                            defaultValue={formData.work_experience ? formData.work_experience : "Select Work Experience"}
                                        />

                                    </li>
                                    <li>
                                        <span className={styles.span}>Emergency Contact Number<i className={styles.red}>*</i>:</span>
                                        <InputField
                                            type="number"
                                            id={`emergency_contact_number`}
                                            name={`emergency_contact_number`}
                                            className={styles.input}
                                            error={errors.emergency_contact_number}
                                            mainClass={styles['div-right']}
                                            value={formData.emergency_contact_number}
                                            onChange={(e) => {
                                                handleInputChange(e);
                                                setFormData({ ...formData, emergency_contact_number: e.target.value });
                                            }}
                                        />

                                    </li>
                                </ul>
                            </div>

                        </form>
                        <div className={styles.clear}></div>
                        <Button
                            continueButtonLabel="Save & Next"
                            onContinueClick={handleContinueClick}
                            previewButtonLabel={isEdit ? "Preview" : ""}
                            previewButtonAction={isEdit ? handlePreviewPage : ''}
                        />

                        { /*<RightPopup step={step} />*/}
                    </div>
                </>
            )}

            {showGender && (
                <Gender step={step} setStep={setStep} isEdit={isEdit} setIsEdit={setIsEdit} handlePreviewClick={handlePreviewClick} apiData={apiData} />

            )}
        </>
    ); 
}

export default Basic;
