import React from 'react';
import styles from '../Boading.module.scss';
const BoadingFooter = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className={styles.grid}>
      <div className={styles.footer}>
        &copy; {currentYear}. BluEnt All Rights Reserved.
      </div>
    </div>
  );
};

export default BoadingFooter;
