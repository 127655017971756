import React from 'react';
import { Helmet } from 'react-helmet-async';
import styles from '../../applicantContainers/Candidate/MainContent/Main.module.scss'
function NotFound() {
  return (
    <>
    <Helmet>
                <title>Page Not Found</title>
                
            </Helmet>
    
    <main id="content" className={styles.content}>
      <section className={styles['bg-404']}>
        <div className={styles['container-404']}>  
          <div><img src="https://www.bluent.com/images/img-404.webp" alt="404" width="500" height="291" className="" /></div>
          <p className={styles.whoops}>Looks like your spaceship flew off course.</p>
         
        </div>
      </section>
    </main>
    </>
  );
}

export default NotFound;
