import React from 'react';
import styles from '../../applicantContainers/Candidate/MainContent/Main.module.scss';

const InputField = ({
  type,
  id,
  name,
  label,
  value,
  error,
  onChange,
  required,
  hidden,
  addButton,
  containerId,
  handleAddEmail,
  renderEmailInputs,
  handleAddMobile,
  renderMobileInputs,
  mainClass,
  placeholder,
  min,
  disabled
}) => {
  return (
    <div className={mainClass}>
      
      <input
        type={type}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        className={`${styles.input} ${error ? styles.error : ''} ${disabled ? styles.edudisable : ''}`}
        required={required}
        aria-required={required}
        aria-invalid={error ? 'true' : 'false'}
        placeholder ={placeholder}
        min={min}
        disabled={disabled}
      />
      {error && (
        <label id={`${id}-error`} className={styles.error} htmlFor={id}>
          {error}
        </label>
      )}
      {/* Hidden field */}
      {hidden && (
        <input
          type="hidden"
          name="formStep"
          className={styles.input}
          value="2"
        />
      )}
      {/* Conditionally  button */}
      {addButton && handleAddEmail && (
        <input
          id="addEmail"
          className={styles.btnAdd}
          type="button"
          value={value}
          onClick={handleAddEmail}
        />
      )}
      {addButton && handleAddMobile && (
        <input
          id="addMobile"
          className={styles.btnAdd}
          type="button"
          value="1"
          onClick={handleAddMobile}
        />
      )}
      {/* Conditionally  container */}
      {containerId && renderEmailInputs && (
        <div className={styles.TextBoxContainer} id={containerId}>
          {renderEmailInputs()}
        </div>
      )}
      {containerId && renderMobileInputs && (
        <div className={styles.TextBoxContainer} id={containerId}>
          {renderMobileInputs()}
        </div>
      )}
    </div>
  );
};
export default InputField;
