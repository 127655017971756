import axios from 'axios';


const OnboadingEmployeeDetails = async (url,token, employeeId) => {

    try {
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}api/onboarding/`+ url,
            {
                id: employeeId,

            },
            {
                headers: {
                    'token': token,
                    'accept': 'application/json',
                },
            }

        );
       return response.data;
    } catch (error) {
        console.error('Error fetching candidate details:', error);
        if (error.response) {
            console.log('Response data:', error.response.data);
        }
        throw error;
    }
};


export default OnboadingEmployeeDetails;