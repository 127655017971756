import React, { useState, useEffect } from 'react';
import { useToken } from '../../../context/AuthContext'
import styles from '../MainContent/Main.module.scss';
import RadioButton from '../../../components/RadioField/RadioField';
import Button from '../../../components/Button/Button';
import ProgressBar from './ProgressBar';
import LeftPopup from './LeftPopup';
import SelectField from '../../../components/SelectField/SelectField';
import InputField from '../../../components/InputField/InputField';
import ProfessionalEXP from '../Details/ProfesstionalRefrence'
import GetCandidateDetails from '../../../api/fetchCandidateData'
import SaveCandidateData from '../../../api/SaveCandidateData';
import StepEmitter from '../../../components/StepEmitter/StepEmitter'
const Family = ({ step, setStep, isEdit, setIsEdit, handlePreviewClick, apiData }) => {
    const nameRegex = /^[A-Za-z.-]+(\s*[A-Za-z.-]+)*$/;
    const ageRegex = /^-?\d+$/;
    const { token, zoho_candidate_id } = useToken();
    const [numDependents, setNumDependents] = useState(0);
    const [showFamilyDetail, setShowFamilyDetail] = useState(false);
    const [showProfessionalEXP, setProfessionalEXP] = useState(false);
    const [showRreview, setshowRreview] = useState(false);
    const [familyCount, setFamilyCount] = useState(1);
    const totalSteps = 12;
    const [showOccupationFields, setShowOccupationFields] = useState(false);

    const relationOptions = [
        { value: '-1', label: 'Select Relation' },
        { value: 'Spouse', label: 'Spouse' },
        { value: 'Father', label: 'Father' },
        { value: 'Mother', label: 'Mother' },
        { value: 'Brother', label: 'Brother' },
        { value: 'Sister', label: 'Sister' },
    ];

    const [families, setFamilies] = useState([
        {
            Relation: '',
            Name: '',
            Age: '',
            Occupation: '',
            error: false,
        },
    ]);

    useEffect(() => {
        setFamilyCount(families.length);
    }, [families]);

    const handleBackClick = () => {
        StepEmitter.emit('stepChange', step - 1);
    };
    const handleContinueClick = async () => {
        // Check if any radio button is selected
        if (numDependents > 0) {
            // Proceed with form validation and saving data
            if (validateForm()) {
                try {
                    const familyDetailsToSave = families.map(family => ({
                        Relation: family.Relation,
                        Name: family.Name,
                        Age: family.Age,
                        Occupation: family.Occupation
                    }));

                    const dataToSave = {
                        family_details: familyDetailsToSave,
                        Zoho_Item_Id: zoho_candidate_id,
                        Process_Status: "7"
                    };
                    const response = await SaveCandidateData(dataToSave);

                    if (response.status === 200) {
                        setProfessionalEXP(true);
                        StepEmitter.emit('stepChange', step + 1);
                    } else {
                        console.error('Error saving candidate data:', response.statusText);
                    }
                } catch (error) {
                    console.error('Error saving candidate data:', error);
                }
            }
        } else {
            // Proceed to the next step without validation if no radio button is selected
            setProfessionalEXP(true);
            StepEmitter.emit('stepChange', step + 1);
        }
    };
    const handlePreviewPage = async () => {
        // Check if any radio button is selected
        if (numDependents > 0) {
            // Proceed with form validation and saving data
            if (validateForm()) {
                try {
                    const familyDetailsToSave = families.map(family => ({
                        Relation: family.Relation,
                        Name: family.Name,
                        Age: family.Age,
                        Occupation: family.Occupation
                    }));

                    const dataToSave = {
                        family_details: familyDetailsToSave,
                        Zoho_Item_Id: zoho_candidate_id,
                        Process_Status: "7"
                    };
                    const response = await SaveCandidateData(dataToSave);

                    if (response.status === 200) {
                        setshowRreview(true);

                        setStep(11);
                        StepEmitter.emit('stepChange', 11);
                    } else {
                        console.error('Error saving candidate data:', response.statusText);
                    }
                } catch (error) {
                    console.error('Error saving candidate data:', error);
                }
            }
        } else {
            // Proceed to the next step without validation if no radio button is selected
            setProfessionalEXP(true);
            StepEmitter.emit('stepChange', step + 1);
        }
    };


    const handleDependentChange = (value) => {
        setNumDependents(value);
        setShowFamilyDetail(true);
        setShowOccupationFields(true);

        // Update the families array to match the number of dependents
        setFamilies(prevFamilies => {
            const newFamilies = [...prevFamilies];
            if (value > newFamilies.length) {
                while (newFamilies.length < value) {
                    newFamilies.push({
                        Relation: '',
                        Name: '',
                        Age: '',
                        Occupation: '',
                        error: false,
                    });
                }
            } else {
                newFamilies.splice(value);
            }
            return newFamilies;
        });
    };


    const handleInputChange = (index, field, value) => {
        const newFamilies = [...families];
        newFamilies[index][field] = value;
        setFamilies(newFamilies);
    };
    const handleSelectChange = (index, field, value) => {
        const newFamilies = [...families];
        newFamilies[index][field] = value;
        setFamilies(newFamilies);
    };
    // Function to fetch candidate details from API
    const fetchCandidateDetails = async () => {
        try {
            const details = await GetCandidateDetails(token, zoho_candidate_id, step);
            const apiData = details.data;
            if (apiData.candidate_family_details && apiData.candidate_family_details.length > 0) {
                setFamilies(apiData.candidate_family_details);
                setNumDependents(apiData.candidate_family_details.length);
                setShowOccupationFields(true);
            } else {
                // Initialize families with a default family object
                setFamilies([
                    {
                        Relation: '',
                        Name: '',
                        Age: '',
                        Occupation: '',
                        error: false,
                    }
                ]);
                setNumDependents(1);
                setShowOccupationFields(true);
            }
        } catch {
            console.error('API has no data');
        }
    };


    useEffect(() => {
        fetchCandidateDetails();
    }, []);



    const validateForm = () => {
        let isValid = true;
        const newFamilies = [...families];

        newFamilies.forEach((family, index) => {
            const isNameEmpty = family.Name.trim() === '';
            const isAgeEmpty = family.Age === '';
            const isOccupationEmpty = family.Occupation.trim() === '';
            const isRelationInvalid = family.Relation === '-1' || family.Relation.trim() === '';
            const isNameInvalid = !nameRegex.test(family.Name.trim());
            const isAgeInvalid = !ageRegex.test(family.Age.trim()) || family.Age < 0;
            const isOccupationInvalid = !nameRegex.test(family.Occupation.trim());
            if (isNameEmpty || isAgeEmpty || isOccupationEmpty || isRelationInvalid || isNameInvalid || isAgeInvalid || isOccupationInvalid) {
                isValid = false;
                newFamilies[index].error = true;
                
            } else {
                newFamilies[index].error = false;
                
            }
        });
       setFamilies(newFamilies);
        return isValid;
    };


    return (
        <>
            {step === 5 && (
                <>
                    <ProgressBar currentStep={step} totalSteps={totalSteps} />
                    <div className={styles['required-details']}>
                        Kindly fill the required details <span>*</span> of the form below.
                    </div>
                    <h4 className={styles.heading1}>
                        <span>Family</span>
                    </h4>

                    <div className={`${styles['row-c']} ${styles.width} ${styles.familyDETAILS}`} style={{ display: step === 5 ? 'block' : 'none' }}>
                        <h5>
                            <span>Family Details</span>
                        </h5>
                        <form id="familyDETAILS" name="familyDETAILS">
                            <div className={styles.dependents}>
                                <ul>
                                    <li>
                                        <span className={styles.span}>
                                            No. of Dependents<i className={styles.red}>*</i>:
                                        </span>
                                        <div className={styles.div}>
                                            <ol className={styles['yes-no-option']}>
                                                {[...Array(10)].map((_, index) => (
                                                    <RadioButton
                                                        key={`dependent${index + 1}`}
                                                        name="no_of_dependents"
                                                        id={`dependent${index + 1}`}
                                                        value={index + 1}
                                                        label={String(index + 1)}
                                                        className="dependentCLICK"
                                                        checked={numDependents === index + 1}
                                                        onChange={() => handleDependentChange(index + 1)}
                                                    />
                                                ))}
                                            </ol>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <div className={styles['family-details']} style={{ display: showOccupationFields ? 'block' : 'none' }}>
                                {families.map((family, index) => (
                                    <ul key={`reference_${index}`}>
                                        <li>
                                            <strong className={styles.strong}>
                                                RELATION<i className={styles.red}>*</i>:
                                            </strong>
                                            <SelectField
                                                id={`Relation_${index}`}
                                                name={`Relation_${index}`}
                                                className={`${styles.select} ${family.error && (family.Relation === '-1' || family.Relation.trim() === '') ? styles.error : ''}`}
                                                value={family.Relation}
                                                options={relationOptions}
                                                onChange={(e) => handleSelectChange(index, 'Relation', e.target.value)}
                                            />
                                        </li>
                                        <li>
                                            <strong className={styles.strong}>
                                                Name<i className={styles.red}>*</i>:
                                            </strong>
                                            <InputField
                                                type="text"
                                                id={`Name_${index}`}
                                                name={`Name_${index}`}
                                                className={`${styles.input} ${family.error && !family.Name.trim() ? styles.error : ''} ${family.error && !nameRegex.test(family.Name.trim()) ? styles.error : ''}`}
                                                required
                                                mainClass={styles['div-right']}
                                                value={family.Name}
                                                error={(family.error && !family.Name.trim()) || (family.error && !nameRegex.test(family.Name.trim())) ? "should only contain characters" : ''}
                                                onChange={(e) => handleInputChange(index, 'Name', e.target.value)}
                                            />
                                        </li>

                                        <li>
                                            <strong className={styles.strong}>
                                                Age<i className={styles.red}>*</i>:
                                            </strong>
                                            <InputField
                                                type="number"
                                                id={`Age_${index}`}
                                                name={`Age_${index}`}
                                                className={styles.input}
                                                required
                                                mainClass={styles['div-right']}
                                                value={family.Age}
                                                error={(family.error && !family.Age.trim()) ? "This field is required" : (family.error && family.Age < 0) ? "Age cannot be negative" : ''}
                                                onChange={(e) => handleInputChange(index, 'Age', e.target.value)}
                                                min="0"
                                            />
                                        </li>
                                        <li>
                                            <strong className={styles.strong}>OCCUPATION<i className={styles.red}>*</i>:</strong>
                                            <InputField
                                                type="text"
                                                id={`Occupation_${index}`}
                                                name={`Occupation_${index}`}
                                                className={styles.input}
                                                required
                                                value={family.Occupation}
                                                error={(family.error && !family.Occupation.trim()) || (family.error && !nameRegex.test(family.Occupation.trim())) ? "should only contain characters" : ''}
                                                onChange={(e) => handleInputChange(index, 'Occupation', e.target.value)}
                                            />
                                        </li>
                                    </ul>
                                ))}
                            </div>


                        </form>

                        <Button
                            backButtonLabel="Back"
                            continueButtonLabel="Save & Next"
                            onBackClick={handleBackClick}
                            onContinueClick={handleContinueClick}
                            backButtonClass={styles['custom-back-class']}
                            continueButtonClass={styles['custom-continue-class']}
                            previewButtonLabel={isEdit ? "Preview" : ""}
                            previewButtonAction={isEdit ? handlePreviewPage : ''}
                        />


                    </div>
                    <LeftPopup step={step} />
                </>
            )}
            {showProfessionalEXP && (
                <ProfessionalEXP step={step} setStep={setStep} isEdit={isEdit} setIsEdit={setIsEdit} handlePreviewClick={handlePreviewClick} apiData={apiData} />
            )}
        </>


    )
}


export default Family;
