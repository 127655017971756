import React, { useState, useEffect } from 'react';
import styles from '../Boading.module.scss';
import OffBoadingEmployeeInfo from '../OffBoadingEmployeeInfo/OffBoadingEmployeeInfo';
import OffboadingEmployeeDetails from '../../api/FetchOffboadingEmployeeDetails';
import OffboadingSaveEmployeeDetails from '../../api/OffboadingSaveEmployeeDetails';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import makeAnimated from 'react-select/animated';
import OnboadingDetails from '../../api/FetchOnboaingDetail';
import Select from 'react-select';
import OffboadingEmployeeEmailsList from '../../api/EmployeeEmailList';


const OffBoadingClassification = ({ department, isActive, authorized, token, employeeId, setEmployeeId, admin, disableTab, onSubmit, activeTabIndex }) => {
    const [loading, setLoading] = useState(true);
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [error, setError] = useState('');
    const animatedComponents = makeAnimated();
    const [emailOptions, setEmailOptions] = useState([]);
    const [selectedBuddyEmail, setSelectedBuddyEmail] = useState(null);
    const [isSystemBackupRequired, setIsSystemBackupRequired] = useState(false);
    const [backupText, setBackupText] = useState('');
    const [isEmailFieldActive, setEmailFieldActive] = useState(false);
    const [isSharedFolderActive, setSharedFolderActive] = useState(false);
    const [emailListOptions, setEmailListOptions] = useState([]);
    const [selectedEmailList, setSelectedEmailList] = useState(null);
    const [emailBox,setEmailBox]=useState(null);
    const [emailBoxList,setEmailBoxList]=useState(null);
    const [formData, setFormData] = useState({
        rack_space_email: false,
        google_email: false,
        comment: "",
        special_software_needs: false,
        svn: false,
        special_software_name: "",
        skype: false,
        additional_access: false,
        additional_access_name: '',
        reason: "",
        email_forward: "",
        mail_box:"",
        logme: false,
        timesheet_approved: false,
        remove_client_folder_access: false,
        remove_client_folder_access_comment: "",
        time_duration: '',
        email_backup: false,
        email_backup_comment: "",
        project_backup: false,
        project_backup_comment: "",
        system_backup: false,
        system_backup_comment: "",
        remove_software_access_comment: "",
        remove_mobile_access_comment: "",
        remove_basic_tools_comment: "",
        project_handover:"",
    });

    const [pcEquipment, setPCEquipment] = useState({
        headphone: false,
        monitor: false,
        mouse: false,
        keyboard: false
    });
    const [softwareAccess, setSoftwareAccess] = useState({
        office365: false,
        Jira: false,
        dropbox: false,
        bitbucket: false,
        quickSight: false,
        powerBI: false,
        CRM: false,
        adobe: false,
        
    });
    const [mobileAccess, setmobileAccess] = useState({
        teams: false,
        jira: false,
        outlook: false
    });
   


    const handleSelectChange1 = (selectedOption) => {
        setSelectedBuddyEmail(selectedOption);
        const emailForwardValue = selectedOption ? selectedOption.value : '';
        setFormData(prevData => ({
            ...prevData,
            email_forward: emailForwardValue,
            mail_box: '' 
        }));
        setEmailBox(null);
    };

    const handleSelectChange2 = (selectedOption) => {
        setSelectedEmailList(selectedOption);
       
    };
    const handleSelectChange3 = (selectedOption) => {
        setEmailBox(selectedOption);
        const emailBoxValue = selectedOption ? selectedOption.value : '';
        setFormData(prevData => ({
            ...prevData,
            mail_box: emailBoxValue,
            email_forward: '' 
        }));
        setSelectedBuddyEmail(null);
    };

    useEffect(() => {
        const url = 'get-classification';
        if (authorized) {
            const fetchData = async () => {
                try {
                    setLoading(true);
                    const url = 'get-employee-performance';
                    const saveurl = 'get-classification';
                    const employeeEmailListUrl = 'get-employee-email-list';
                    const employeeData = await OnboadingDetails(url, token, employeeId);
                    const employeeOffBoading = await OffboadingEmployeeDetails(saveurl, token, employeeId);
                    const EmployeeEmailsList = await OffboadingEmployeeEmailsList(employeeEmailListUrl, token);
                    if (employeeData.status === 200) {
                        const empDetailsEmail = employeeData.performance;
                        const buddyEmails = empDetailsEmail.emails;
                        const emailLists = EmployeeEmailsList.emails;
                        const options = buddyEmails.map(emailObj => {
                            const emailID = Object.keys(emailObj)[0];
                            const email = emailObj[emailID];
                            return { value: email, label: email, emailID };
                        });
                        const emailOptions = emailLists.map(emailObj => {
                            const emailID = Object.keys(emailObj)[0];
                            const email = emailObj[emailID];
                            return { value: email, label: email, emailID };
                        });

                        options.unshift({ value: '', label: 'Select Email', emailID: 'manual' });
                        emailOptions.unshift({ value: '', label: 'Select Email', emailID: 'manual' });
                        setEmailOptions(options);
                        setEmailListOptions(emailOptions);
                        //setEmailBoxList();
                        const empDetails = employeeOffBoading.department;

                        setFormData({
                            rack_space_email: empDetails.rack_space_email || false,
                            google_email: empDetails.google_email || false,
                            special_software_needs: empDetails.special_software_needs || '',
                            svn: empDetails.svn || false,
                            special_software_name: empDetails.special_software_name || '',
                            mobile_access: empDetails.mobile_access || '',
                            special_software_needs: empDetails.special_software_needs || false,
                            skype: empDetails.skype || false,
                            additional_access_name: empDetails.additional_access_name || '',
                            reason: empDetails.reason || '',
                            //email_forward: empDetails.email_forward || '',
                            //mail_box:empDetails.mail_box || '',
                            comment: empDetails.comment || '',
                            logme: empDetails.logme || false,
                            timesheet_approved: empDetails.timesheet_approved || false,
                            remove_client_folder_access: empDetails.remove_client_folder_access || false,
                            remove_client_folder_access_comment: empDetails.remove_client_folder_access_comment || "",
                            time_duration: empDetails.time_duration || '',
                            email_backup: empDetails.email_backup || false,
                            email_backup_comment: empDetails.email_backup_comment || "",
                            project_backup: empDetails.project_backup || false,
                            project_backup_comment: empDetails.project_backup_comment || "",
                            system_backup: empDetails.system_backup || false,
                            system_backup_comment: empDetails.system_backup_comment || "",
                            remove_software_access_comment: empDetails.remove_software_access_comment || "",
                            remove_mobile_access_comment: empDetails.remove_mobile_access_comment || "",
                            remove_basic_tools_comment: empDetails.remove_basic_tools_comment || "",
                            //project_handover:empDetails.project_handover || "",

                        });

                        setIsSystemBackupRequired(empDetails.backup);


                        const mobileAccess = empDetails.remove_mobile_access;
                        if (mobileAccess) {
                            setmobileAccess({
                                teams: mobileAccess.teams || false,
                                outlook: mobileAccess.outlook || false,
                                jira: mobileAccess.jira || false

                            });
                        }
                        const softwareAccess = empDetails.remove_software_access; 
                        if (softwareAccess) {
                            setSoftwareAccess({
                                office365: softwareAccess.office365 || false,
                                Jira: softwareAccess.Jira || false,
                                dropbox: softwareAccess.dropbox || false,
                                bitbucket: softwareAccess.bitbucket || false,
                                quickSight: softwareAccess.quickSight || false,
                                powerBI: softwareAccess.powerBI || false,
                                CRM: softwareAccess.CRM || false,
                                adobe: softwareAccess.adobe || false
                              
                                
                            });
                        }
                        
                        const selectedOption = options.find(option => option.value === empDetails.email_forward);
                        const selectedEmail = options.find(option => option.value === empDetails.project_handover);
                        const EmailBox = options.find(option => option.value === empDetails.mail_box);
                        setSelectedBuddyEmail(selectedOption);
                        setSelectedEmailList(selectedEmail);
                        setEmailBox(EmailBox);
                        if (empDetails.status === 'Submitted') {
                            setButtonsDisabled(true);
                        }
                        

                // Set disabling logic based on API values
                const emailForwardHasValue = empDetails.email_forward !== " ";
                const shouldDisableEmailField = empDetails.enable_shared_folder;
                 setSharedFolderActive(emailForwardHasValue);
                 setEmailFieldActive(shouldDisableEmailField);

                        setLoading(false);

                    } else {
                        console.error('Error fetching employee data:', employeeData.error);
                    }
                } catch (error) {
                    console.error('Error fetching employee data:', error);
                } finally {
                    setLoading(false);
                }
            };

            fetchData();
           
        }
    }, [authorized, token, employeeId]);




    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        
        setFormData(prevState => {
             const updatedFormData = { ...prevState, [name]: checked };
    
            
            if (name === 'remove_client_folder_access') {
                if (!checked) {
                    updatedFormData.remove_client_folder_access_comment = ''; 
                }
            }
            if (name === 'email_backup') {
                if (!checked) {
                    updatedFormData.email_backup_comment = ''; 
                }
            }
            if (name === 'project_backup') {
                if (!checked) {
                    updatedFormData.project_backup_comment = ''; 
                }
            }
            if (name === 'system_backup') {
                if (!checked) {
                    updatedFormData.system_backup_comment = ''; 
                }
            }
    
            return updatedFormData;
        });
    
        if (name === 'backup') {
            setIsSystemBackupRequired(checked);
        } 
    };
    

    const handlepcCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setPCEquipment(prevState => ({
            ...prevState,
            [name]: checked
        }));
    };
    const handleswCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setSoftwareAccess(prevState => ({
            ...prevState,
            [name]: checked
        }));
    };

    const handlemobileCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setmobileAccess(prevState => ({
            ...prevState,
            [name]: checked
        }));
    };
    




    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    
    const toggleBackupTextField = (event) => {
        setBackupText('');
        setIsSystemBackupRequired(!isSystemBackupRequired);
    };

    const hanldeSaveInfo = async (event) => {
        event.preventDefault();
        let validationError = false;

    
    if (formData.remove_client_folder_access && !formData.remove_client_folder_access_comment.trim()) {
        toast.error('Please enter a comment for "Remove client folder access".');
        validationError = true;
    }
    if (formData.email_backup && !formData.email_backup_comment.trim()) {
        toast.error('Please enter a comment for "Email Backup".');
        validationError = true;
    }
    if (formData.project_backup && !formData.project_backup_comment.trim()) {
        toast.error('Please enter a comment for "Project Backup".');
        validationError = true;
    }
    if (formData.system_backup && !formData.system_backup_comment.trim()) {
        toast.error('Please enter a comment for "System Backup".');
        validationError = true;
    }

    if (!formData.comment.trim()) {
        toast.error('Please enter a comment.');
        validationError = true;
    }

    if (validationError) {
        return; 
    }
  

        try {
            setLoading(true);
            const url = 'save-classification';


            const requestData = {
                ...formData,

                remove_software_access: {
                    office365: softwareAccess.office365,
                    Jira: softwareAccess.Jira,
                    dropbox: softwareAccess.dropbox,
                    bitbucket: softwareAccess.bitbucket,
                    quickSight: softwareAccess.quickSight,
                    powerBI: softwareAccess.powerBI,
                    CRM: softwareAccess.CRM,
                    adobe: softwareAccess.adobe
                    
                },
                remove_mobile_access: {
                    teams: mobileAccess.teams,
                    jira: mobileAccess.jira,
                    outlook: mobileAccess.outlook
                },
               
                backup: isSystemBackupRequired,
                ...(selectedBuddyEmail && selectedBuddyEmail.value ? { email_forward: selectedBuddyEmail.value } : {}),
                ...(selectedEmailList && selectedEmailList.value ? { project_handover: selectedEmailList.value } : {}),
                ...(emailBox && emailBox.value && emailBox.value.trim() ? { mail_box: emailBox.value } : {}),
                status: "Pending",
                created_by: admin,

            };

            const response = await OffboadingSaveEmployeeDetails(url, token, employeeId, requestData);
            toast.success('Form data saved successfully!');
        } catch (error) {
            console.error('Error saving form data:', error);
        } finally {
            setLoading(false);
        }
    }

    const hanldeSubmit = async (event) => {

        event.preventDefault();
        if (!formData.comment.trim()) {
            toast.error('Please enter a comment.');
            return;
        }
        
        
        
     
    if (formData.remove_client_folder_access && !formData.remove_client_folder_access_comment.trim()) {
        toast.error('Please enter a comment for "Remove client folder access".');
        return;
    }
    if (formData.email_backup && !formData.email_backup_comment.trim()) {
        toast.error('Please enter a comment for "Email Backup".');
        return;
    }
    if (formData.project_backup && !formData.project_backup_comment.trim()) {
        toast.error('Please enter a comment for "Project Backup".');
        return;
    }
    if (formData.system_backup && !formData.system_backup_comment.trim()) {
        toast.error('Please enter a comment for "System Backup".');
        return;
    }

    // General comment validation (if applicable)
    if (!formData.comment.trim()) {
        toast.error('Please enter a comment.');
        return;
    }

   
        try {
            setLoading(true);
            const url = 'save-classification';


            

                const requestData = {
                    ...formData,
    
                    remove_software_access: {
                        office365: softwareAccess.office365,
                        Jira: softwareAccess.Jira,
                        dropbox: softwareAccess.dropbox,
                        bitbucket: softwareAccess.bitbucket,
                        quickSight: softwareAccess.quickSight,
                        powerBI: softwareAccess.powerBI,
                        CRM: softwareAccess.CRM,
                        adobe: softwareAccess.adobe
                        
                    },
                    remove_mobile_access: {
                        teams: mobileAccess.teams,
                        jira: mobileAccess.jira,
                        outlook: mobileAccess.outlook
                    },
                   
                    backup: isSystemBackupRequired,
                    ...(selectedBuddyEmail && selectedBuddyEmail.value ? { email_forward: selectedBuddyEmail.value } : {}),
                    ...(selectedEmailList && selectedEmailList.value ? { project_handover: selectedEmailList.value } : {}),
                    ...(emailBox && emailBox.value ? { mail_box: emailBox.value } : ''),
                    status: "Submitted",
                    created_by: admin,
    
                };
            

            const response = await OffboadingSaveEmployeeDetails(url, token, employeeId, requestData);
            toast.success('Form data Submitted successfully!');
            //onSubmit(activeTabIndex);
        } catch (error) {
            console.error('Error saving form data:', error);
        } finally {
            setButtonsDisabled(true);
            setLoading(false);
        }
    }

    return (
        <div className={styles.employee}>
            <div className={styles.grid}>
                <div className={styles['edit-bg']}>

                </div>

                <OffBoadingEmployeeInfo token={token} employeeId={employeeId} />

                <div className={styles.col9}>
                    <div className={`${styles['box-shadow']} ${styles['border-radius']} `}>
                        <fieldset className={styles.fieldset}>
                            <legend>Employee Classification</legend>
                            <div className={styles.grid} style={{ marginTop: "10px" }}>
                                <div className={styles.col6}>
                                    <div className={styles['right-space']}>
                                        <ul className={styles['two-col']}>
                                           
                                            <li><strong>Remove Software Access:</strong><span>
                                                <input type="checkbox" id="Office" name="office365" value="Office 365" checked={softwareAccess.office365}
                                                    onChange={handleswCheckboxChange} />
                                                <label htmlFor="Office">Office 365</label>
                                                <input type="checkbox" id="Jira" name="Jira" value="Jira" checked={softwareAccess.Jira}
                                                    onChange={handleswCheckboxChange} />
                                                <label htmlFor="Jira">Jira</label>
                                                <input type="checkbox" id="Dropbox" name="dropbox" value="Dropbox" checked={softwareAccess.dropbox}
                                                    onChange={handleswCheckboxChange} />
                                                <label htmlFor="Dropbox">Dropbox</label>
                                                <input type="checkbox" id="bitbucket" name="bitbucket" value="Git" checked={softwareAccess.bitbucket}
                                                    onChange={handleswCheckboxChange} />
                                                <label htmlFor="bitbucket">Bitbucket</label>
                                                <input type="checkbox" id="QuickSight" name="quickSight" value="QuickSight (Author or Reader)" checked={softwareAccess.quickSight}
                                                    onChange={handleswCheckboxChange} />
                                                <label htmlFor="QuickSight">QuickSight (Author or Reader)</label>
                                                <input type="checkbox" id="PowerBI" name="powerBI" value="PowerBI" checked={softwareAccess.powerBI}
                                                    onChange={handleswCheckboxChange} />
                                                <label htmlFor="PowerBI">Power BI</label>
                                                <input type="checkbox" id="CRM" name="CRM" value="CRM" checked={softwareAccess.CRM}
                                                    onChange={handleswCheckboxChange} />
                                                <label htmlFor="CRM">CRM</label>
                                                
                                                
                                                
                                            </span>
                                                <strong></strong>





                                                <textarea
                                                    value={formData.remove_software_access_comment}
                                                    onChange={handleChange}
                                                    name="remove_software_access_comment"
                                                    placeholder="Add Comment..."
                                                    className={styles.input}
                                                />


                                            </li>
                                            <li>
                                                <strong>Remove Mobile Access:</strong>
                                                <span>
                                                    <input type="checkbox" id="MS_Teams" name="teams" value="MS Teams" checked={mobileAccess.teams} onChange={handlemobileCheckboxChange} />
                                                    <label htmlFor="MS_Teams">MS Teams</label>

                                                    <input type="checkbox" id="Outlook" name="outlook" value="Outlook" checked={mobileAccess.outlook} onChange={handlemobileCheckboxChange} />
                                                    <label htmlFor="Outlook">Outlook</label>

                                                    <input type="checkbox" id="Jira_Mobile" name="jira" value="Jira" checked={mobileAccess.jira} onChange={handlemobileCheckboxChange} />
                                                    <label htmlFor="Jira_Mobile">Jira</label>
                                                </span>
                                                <strong></strong>





                                                <textarea
                                                    value={formData.remove_mobile_access_comment}
                                                    onChange={handleChange}
                                                    name="remove_mobile_access_comment"
                                                    placeholder="Add Comment..."
                                                    className={styles.input}
                                                />
                                            </li>
                                            
                                            
                                            <li><strong>Google Bluent.net email:</strong> <span>
                                                <input type="checkbox" id="google_email" name="google_email" value="yes" checked={formData.google_email}
                                                    onChange={handleCheckboxChange} />
                                                <label htmlFor="google_email"></label>
                                            </span></li>
                                            <li><strong>Forward email address to:
                                                <i className="required"></i></strong>
                                                <span>
                                                    <Select
                                                        value={selectedBuddyEmail}
                                                        onChange={handleSelectChange1}
                                                        options={emailOptions}
                                                        placeholder="Select Email"
                                                        name="email_forward"
                                                        isDisabled={isEmailFieldActive}
                                                    />

                                                </span>
                                            </li>
                                            <li><strong>Mail Box Access:
                                            </strong>
                                                <span>
                                                <Select
                                                        value={emailBox}
                                                        onChange={handleSelectChange3}
                                                        options={emailOptions}
                                                        placeholder="Select Email"
                                                        name="mail_box"
                                                       
                                                    />

                                                </span>
                                            </li>
                                            <li>
                                                <strong>Forward email/Mail Box Time Duration(Months):</strong>
                                                <span>
                                                    <input
                                                        type="text"
                                                        value={formData.time_duration}
                                                        onChange={handleChange}
                                                        name="time_duration"
                                                        placeholder="Add time duration..."
                                                        className={styles.input}
                                                    />


                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className={styles.col6}>
                                    <div className={styles['left-space']}>
                                        <ul className={styles['two-col']}>



                                            {/* <li>
                                                <strong>Logme IN:</strong>*/}
                                            <span>
                                                <input type="hidden" id="logme" name="logme" checked={formData.logme}
                                                    onChange={handleCheckboxChange} />
                                                <label htmlFor="logme"></label>
                                            </span>
                                            {/*  </li>*/}

                                            {/*<li>
                                                <strong>Rack Space email:</strong>*/}
                                            <span>
                                                <input
                                                    type="hidden"
                                                    id="rack_space_email"
                                                    name="rack_space_email"
                                                    checked={formData.rack_space_email}
                                                    onChange={handleCheckboxChange}
                                                />
                                                <label htmlFor="rack_space_email"></label>
                                            </span>
                                            {/*</li>*/}

                                        </ul>
                                        <ul className={styles['two-col']}>
                                            {/* <li>
                                                <strong>SVN:</strong>*/}
                                            <span>
                                                <input type="hidden" id="svn" name="svn" value="yes" checked={formData.svn}
                                                    onChange={handleCheckboxChange} />
                                                <label htmlFor="svn"></label>
                                            </span>
                                            {/*</li>*/}



                                            {/*<li><strong>Skype + credit/back:<i className="required"></i></strong> <span>*/}
                                            <input type="hidden" id="skype" name="skype" value="skype" checked={formData.skype}
                                                onChange={handleCheckboxChange} />
                                            <label htmlFor="skype"></label>
                                            {/*</span></li>*/}


                                            {/*<li><strong>Reason for Dismissal:                                            :<i className="required"></i></strong> <span>
                                                <input type="text" id="reason" name="reason"  className={styles.input} value={formData.reason}
                                                    onChange={handleChange} />
                                                <label htmlFor="reason"></label>
                                            </span></li>*/}
                                             <li><strong>All Timesheet Approved:</strong>
                                                <span>
                                                    <input
                                                        type="checkbox"
                                                        id="timesheet_approved"
                                                        name="timesheet_approved"
                                                        onChange={handleCheckboxChange}
                                                        checked={formData.timesheet_approved}

                                                    />
                                                    <label htmlFor="timesheet_approved"></label>
                                                </span></li>
                                            

                                            
                                            <li>
                                                <strong>Remove client folder access:</strong>
                                                <span>
                                                    <input
                                                        type="checkbox"
                                                        checked={formData.remove_client_folder_access}
                                                        onChange={handleCheckboxChange}
                                                        name="remove_client_folder_access"
                                                        id="remove_client_folder_access"
                                                        className={styles.input}
                                                    />
                                                    <label htmlFor="remove_client_folder_access"></label>
                                                </span>
                                                <strong></strong>

                                                {formData.remove_client_folder_access && (



                                                    <textarea
                                                        value={formData.remove_client_folder_access_comment}
                                                        onChange={handleChange}
                                                        name="remove_client_folder_access_comment"
                                                        placeholder="Add folder name in comment..."
                                                        className={styles.input}
                                                    />

                                                )}

                                            </li>
                                            <li>
                                                <strong>Email Backup:</strong>
                                                <span>
                                                    <input
                                                        type="checkbox"
                                                        checked={formData.email_backup}
                                                        onChange={handleCheckboxChange}
                                                        name="email_backup"
                                                        id="email_backup"
                                                    />
                                                    <label htmlFor="email_backup"></label>
                                                </span>
                                                <strong></strong>

                                                
                                                {formData.email_backup && (


                                                    <textarea
                                                        value={formData.email_backup_comment}
                                                        onChange={handleChange}
                                                        name="email_backup_comment"
                                                        placeholder="Add specific email name..."
                                                        className={styles.input}
                                                    />

                                                )}

                                            </li>
                                            <li>
                                                <strong>Project Backup(To ensure that all project backups have been taken):</strong>
                                                <span>
                                                    <input
                                                        type="checkbox"
                                                        checked={formData.project_backup}
                                                        onChange={handleCheckboxChange}
                                                        name="project_backup"
                                                        id="project_backup"
                                                    />
                                                    <label htmlFor="project_backup"></label>
                                                </span>
                                                <strong></strong>

                                                {formData.project_backup && (



                                                    <textarea
                                                        value={formData.project_backup_comment}
                                                        onChange={handleChange}
                                                        name="project_backup_comment"
                                                        placeholder="Add Specific backup Project name..."
                                                        className={styles.input}
                                                    />

                                                )}

                                            </li>
                                            <li>
                                                <strong>System Backup:</strong>
                                                <span>
                                                    <input
                                                        type="checkbox"
                                                        checked={formData.system_backup}
                                                        onChange={handleCheckboxChange}
                                                        name="system_backup"
                                                        id="system_backup"
                                                    />
                                                    <label htmlFor="system_backup"></label>
                                                </span>
                                                <strong></strong>

                                                
                                                {formData.system_backup && (


                                                    <textarea
                                                        value={formData.system_backup_comment}
                                                        onChange={handleChange}
                                                        name="system_backup_comment"
                                                        placeholder="Add specific backup folder name ..."
                                                        className={styles.input}
                                                    />

                                                )}

                                            </li>
                                            <li><strong>Project Handover:
                                                <i className="required"></i></strong>
                                                <span>
                                                    <Select
                                                        value={selectedEmailList}
                                                        onChange={handleSelectChange2}
                                                        options={emailListOptions}
                                                        placeholder="Select Email"
                                                        name="project_handover"
                                                        
                                                    />

                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className={styles['right-space']}>
                                    <ul className={styles['two-col']}>
                                        <li><strong>Comment<i className={styles['red']}>*</i>:</strong> <span><textarea name="comment" className={styles.input} value={formData.comment} onChange={handleChange} ></textarea></span></li>

                                    </ul>
                                </div>

                                {/* Button block */}
                                <div className={styles['button-block']}>
                                    <input type="submit" value="Save " className={styles.button} onClick={hanldeSaveInfo} disabled={loading || buttonsDisabled || department === "Human Resources"} />
                                    <input type="submit" value={buttonsDisabled ? 'Submitted' : 'Submit'} className={styles.button} onClick={hanldeSubmit} disabled={loading || buttonsDisabled || department === "Human Resources"} />
                                    <ToastContainer />
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
                {loading && <div className={`${styles['loader-center']} ${styles['loaderHeight-classification']} `}>
                    <div className={`${styles['newsloader-container']} ${styles['newsloaderContainer-classification']} `}>
                        <div className={styles.newsloader}></div></div>
                </div>}
            </div>

        </div>
    );
};

export default OffBoadingClassification;
