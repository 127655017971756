
import axios from 'axios';
import React from 'react';

const SaveFeedbackData = async (formDataToSave) => {
    
    
    try {
        const response = await axios.post( process.env.REACT_APP_API_URL + 'api/feedback/save-feedback', formDataToSave, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
       
        return response.data; 

    } catch (error) {
        console.error('Error saving form data:', error);
        throw error; // Throw the error to be handled by the caller
    }
};

export default SaveFeedbackData;