import React, { useState, useEffect } from 'react'
import styles from '../MainContent/Main.module.scss';
import { useToken } from '../../../context/AuthContext'
import Basic from '../../../assets/images/basic-details-icon.svg';
import Family from '../../../assets/images/family-icon.svg';
import Professional from '../../../assets/images/professional-icon.svg';
import Education from '../../../assets/images/education-icon.svg';
import Information from '../../../assets/images/other-information-icon.svg'
import StepEmitter from '../../../components/StepEmitter/StepEmitter'
const LeftPopup = ({step}) => {
    
    return (

        <div className={`${styles['popup-left']} ${step === 5 || step === 6 ? styles['family-bg'] : ''} ${step === 7  ? styles['experience-bg'] : ''} ${step === 8  ? styles['education-bg'] : ''} ${step === 9 || step === 10  ? styles['other-bg'] : ''} ${step === 11 ? styles['preview-left'] : ''}`}>


            {(step === 1 || step === 2 || step === 3 || step === 4) && (
                <div className={styles.step1}>
                    <img src={Basic} alt="" width="90px" height="100px"/>
                    <span>Basic Details</span>
                    <p>Fill basic Information and address.</p>
                    <a href='#'>Step 1 <i>→</i></a>
                </div>
            )}
            {(step === 5 || step === 6) && (
                <div className={styles.step2}  style={{ display: 'block', whiteSpace: 'pre-line' }}>
                    <img src={Family} alt="" width="90px" height="100px"/>
                    <span>Family/<br></br>Reference</span>
                    <p>Fill details about your family and professional references.</p>
                    <a href='#'>Step 2 <i>→</i></a>
                </div>
            )}
            {(step === 7) && (
                <div className={styles.step3} style={{ display: 'block' }}>
                    <img src={Professional} alt="" width="90px" height="100px"/>
                    <span>Professional <br /> Experience</span>
                    <p>Required details of your work experience.</p>
                    <a href='#'>Step 3 <i>→</i></a>
                </div>
            )}
            {( step === 8) && (
                <div className={styles.step4} style={{ display: 'block' }}>
                    <img src={Education} alt="" width="90px" height="100px"/>
                    <span>Education/ <br />Awards</span>
                    <p>Required details of your education.</p>
                    <a href='#'>Step 4 <i>→</i></a>
                </div>
            )}
            {( step === 9 || step === 10) && (
                <div className={styles.step5} style={{ display: 'block', whiteSpace: 'pre-line' }}>
                    <img src={Information} alt="" width="90px" height="100px"/>
                    <span>Other <br /> Information</span>
                    <p>You can fill any other information and your salary details.</p>
                    <a href='#'>Step 5 <i>→</i></a>
                </div>
            )}
        </div>
    );
}
export default LeftPopup;
