import React, { useState, useEffect } from 'react';
import styles from '../Boading.module.scss';
import OffBoadingEmployeeInfo from '../OffBoadingEmployeeInfo/OffBoadingEmployeeInfo';
import OffboadingEmployeeDetails from '../../api/FetchOffboadingEmployeeDetails';
import OffboadingSaveEmployeeDetails from '../../api/OffboadingSaveEmployeeDetails';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const OffBoadingIT = ({ department, isActive, authorized, token, employeeId, setEmployeeId, admin, disableTab, onSubmit, activeTabIndex }) => {
    const [loading, setLoading] = useState(false);
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [fields, setFields] = useState({
        access_control: false,
        access_control_no: false,
        access_control_comment: "",
        ad_account_disabled: false,
        ad_account_disabled_no: false,
        ad_account_disabled_comment: "",
        comment: "",
        email_address_removed: false,
        email_address_removed_no: false,
        email_address_removed_comment: "",
        email_backup: false,
        email_backup_no: false,
        email_backup_comment: "",
        email_forward_to: "",
        mail_box_to:"",
        email_forward: false,
        email_forward_no: false,
        email_forward_comment: '',
        mail_box: false,
        mail_box_no: false,
        mail_box_comment: '',
        folder_permission: false,
        folder_permission_no: false,
        folder_permission_comment: "",
        google_email: false,
        google_email_no: false,
        google_email_comment: "",
        inform_client: false,
        inform_client_no: false,
        inform_client_comment: "",
        mobile_and_SIM: false,
        mobile_and_SIM_no: false,
        mobile_and_SIM_comment: "",
        system_backup: false,
        system_backup_no: false,
        system_backup_comment: "",
        time_duration: "",
        project_backup: false,
        project_backup_no: false,
        project_backup_comment: "",
        remove_client_folder_access: false,
        remove_client_folder_access_no: false,
        remove_client_folder_access_comment: "",
        mobile_access_no: false,
        mobile_access_comment: "",
        pc_equipment_no: false,
        pc_equipment_comment: "",
        software_access_no: false,
        software_access_classification_comment: "",
        project_backup_classification_comment: "",
        inform_client_classification_comment: "",
        mobile_access_classification_comment: "",
        remove_client_folder_access_classification_comment: "",
        system_backup_classification_comment: "",
        email_backup_classification_comment: "",
        enable_shared_classification_comment: "",
        google_email_classification_comment: ""

    });

    const [pcEquipment, setPCEquipment] = useState({
        Headphone: false,
        Monitor: false,
        Mouse: false,
        Keyboard: false
    });

    const [softwareAccess, setSoftwareAccess] = useState({});

    const [mobileAccess, setMobileAccess] = useState({});

    useEffect(() => {
        const url = 'get-it-information';

        const fetchData = async () => {
            try {
                const employeeData = await OffboadingEmployeeDetails(url, token, employeeId);

                if (employeeData.status === 200) {
                    const empDetails = employeeData.data;
                    setFields({
                        access_control: empDetails.access_control || false,
                        access_control_no: empDetails.access_control_no || false,
                        access_control_comment: empDetails.access_control_comment || "",
                        ad_account_disabled: empDetails.ad_account_disabled || false,
                        ad_account_disabled_no: empDetails.ad_account_disabled_no || false,
                        ad_account_disabled_comment: empDetails.ad_account_disabled_comment || "",
                        comment: empDetails.comment || "",
                        email_address_removed: empDetails.email_address_removed || false,
                        email_address_removed_no: empDetails.email_address_removed_no || false,
                        email_address_removed_comment: empDetails.email_address_removed_comment || "",
                        email_backup: empDetails.email_backup || false,
                        email_backup_no: empDetails.email_backup_no || false,
                        email_backup_comment: empDetails.email_backup_comment || "",
                        email_forward: empDetails.email_forward || false,
                        email_forward_to: empDetails.email_forward_to || '',
                        email_forward_no: empDetails.email_forward_no || false,
                        email_forward_comment : empDetails.email_forward_comment || '',
                        mail_box: empDetails.mail_box || false,
                        mail_box_to: empDetails.mail_box_to || '',
                        mail_box_no: empDetails.mail_box_no || false,
                        mail_box_comment : empDetails.mail_box_comment || '',
                        enable_shared_folder: empDetails.enable_shared_folder || false,
                        enable_shared_no: empDetails.enable_shared_no || "",
                        enable_shared_comment: empDetails.enable_shared_comment || "",
                        folder_permission: empDetails.folder_permission || false,
                        folder_permission_no: empDetails.folder_permission_no || false,
                        folder_permission_comment: empDetails.folder_permission_comment || "",
                        google_email: empDetails.google_email || false,
                        google_email_no: empDetails.google_email_no || false,
                        google_email_comment: empDetails.google_email_comment || "",
                        inform_client: empDetails.inform_client || false,
                        inform_client_no: empDetails.inform_client_no || false,
                        inform_client_comment: empDetails.inform_client_comment || "",
                        mobile_and_SIM: empDetails.mobile_and_SIM || false,
                        mobile_and_SIM_no: empDetails.mobile_and_SIM_no || false,
                        mobile_and_SIM_comment: empDetails.mobile_and_SIM_comment || "",
                        system_backup: empDetails.system_backup || false,
                        system_backup_no: empDetails.system_backup_no || false,
                        system_backup_comment: empDetails.system_backup_comment || "",
                        time_duration: empDetails.time_duration || "",
                        project_backup: empDetails.project_backup || false,
                        project_backup_no: empDetails.project_backup_no || false,
                        project_backup_comment: empDetails.project_backup_comment || "",
                        remove_client_folder_access: empDetails.remove_client_folder_access || false,
                        remove_client_folder_access_no: empDetails.remove_client_folder_access_no || false,
                        remove_client_folder_access_comment: empDetails.remove_client_folder_access_comment || "",
                        mobile_access_no: empDetails.mobile_access_no || false,
                        mobile_access_comment: empDetails.mobile_access_comment || "",
                        pc_equipment_no: empDetails.pc_equipment_no || false,
                        pc_equipment_comment: empDetails.pc_equipment_comment || "",
                        software_access_no: empDetails.software_access_no || false,
                        software_access_classification_comment: empDetails.software_access_classification_comment || "",
                        project_backup_classification_comment: empDetails.project_backup_classification_comment || "",
                        inform_client_classification_comment: empDetails.inform_client_classification_comment || "",
                        mobile_access_classification_comment: empDetails.mobile_access_classification_comment || "",
                        remove_client_folder_access_classification_comment: empDetails.remove_client_folder_access_classification_comment || "",
                        system_backup_classification_comment: empDetails.system_backup_classification_comment || "",
                        email_backup_classification_comment: empDetails.email_backup_classification_comment || "",
                        enable_shared_classification_comment: empDetails.enable_shared_classification_comment || "",
                        google_email_classification_comment: empDetails.google_email_classification_comment || "",
                    });

                    const PCAccess = empDetails.pc_equipment_value;
                    if (PCAccess) {
                        setPCEquipment({
                            Headphone: PCAccess.Headphone || false,
                            Keyboard: PCAccess.Keyboard || false,
                            Monitor: PCAccess.Monitor || false,
                            Mouse: PCAccess.Mouse || false
                        });
                    }

                    const mobileAccessData = empDetails.mobile_access_value;
                    if (mobileAccessData) {
                        setMobileAccess(mobileAccessData);
                    }

                    const softwareAccessData = empDetails.software_access_value;
                    if (softwareAccessData) {
                        setSoftwareAccess(softwareAccessData);
                    }

                    if (empDetails.status === 'Submitted') {
                        setButtonsDisabled(true);
                    }
                } else {
                    console.error('Error fetching employee data:', employeeData.error);
                }
            } catch (error) {
                console.error('Error fetching employee data:', error);
            }
        };

        fetchData();
    }, [token, employeeId]);

    const handleChange = (e) => {
        const { name, type, checked } = e.target;

        if (type === "checkbox") {
            if (name.endsWith("_no")) {
                setFields((prevFields) => ({
                    ...prevFields,
                    [name]: checked,
                    [name.replace("_no", "")]: !checked
                }));
            } else {
                setFields((prevFields) => ({
                    ...prevFields,
                    [name]: checked,
                    [name + "_no"]: !checked
                }));
            }
        } else {
            setFields((prevFields) => ({
                ...prevFields,
                [name]: e.target.value
            }));
        }
    };
    const handleCheckboxChange = (checkedName) => {

        setFields((prevFields) => ({
            ...prevFields,
            enable_shared_folder: checkedName === 'enable_shared_folder',
            enable_shared_no: checkedName === 'enable_shared_no',
        }));
    };

    const handleSaveInfo = async (event) => {
        event.preventDefault();
        let invalidFields = [];

        Object.entries(fields).forEach(([fieldName, fieldValue]) => {
            if (fieldName.endsWith('_no') && fieldValue) {
                const baseFieldName = fieldName.replace('_no', '');
                const commentFieldName = `${baseFieldName}_comment`;
                if (!fields[commentFieldName]) {
                    invalidFields.push(commentFieldName);
                }
            }
        });

        if (!fields.comment) {
            invalidFields.push('comment');
        }

        if (invalidFields.length > 0) {
            toast.error('Please provide comments options.');
        } else {
            try {
                setLoading(true);
                const url = 'create-it';

                const requestData = {
                    ...fields,
                    software_access_value: softwareAccess,
                    mobile_access_value: mobileAccess,
                    pc_equipment_value: pcEquipment,
                    status: "Pending",
                    created_by: admin,
                };

                const response = await OffboadingSaveEmployeeDetails(url, token, employeeId, requestData);
                if (response.status === 200) {
                    toast.success('Form data saved successfully!');
                } else {
                    toast.error('Failed to save form data');
                }
            } catch (error) {
                console.error('Error saving form data:', error);
                toast.error('An error occurred while saving data');
            } finally {
                setLoading(false);
            }
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        let invalidFields = [];

        Object.entries(fields).forEach(([fieldName, fieldValue]) => {
            if (fieldName.endsWith('_no') && fieldValue) {
                const baseFieldName = fieldName.replace('_no', '');
                const commentFieldName = `${baseFieldName}_comment`;
                if (!fields[commentFieldName]) {
                    invalidFields.push(commentFieldName);
                }
            }
        });

        if (!fields.comment) {
            invalidFields.push('comment');
        }

        if (invalidFields.length > 0) {
            toast.error('Please provide comments options.');
        } else {
            try {
                setLoading(true);
                const url = 'create-it';

                const requestData = {
                    ...fields,
                    software_access_value: softwareAccess,
                    mobile_access_value: mobileAccess,
                    pc_equipment_value: pcEquipment,
                    status: "Submitted",
                    created_by: admin,
                };

                const response = await OffboadingSaveEmployeeDetails(url, token, employeeId, requestData);
                if (response.status === 200) {
                    toast.success('Form data saved successfully!');
                } else {
                    toast.error('Failed to save form data');
                }
            } catch (error) {
                console.error('Error saving form data:', error);
                toast.error('An error occurred while saving data');
            } finally {
                setButtonsDisabled(true);
                setLoading(false);
            }
        }
    };

    const handleSoftwareChange = (software, isChecked) => {
        setSoftwareAccess(prev => ({ ...prev, [software]: isChecked }));
    };

    const handleMobileChange = (mobile, isChecked) => {
        setMobileAccess(prev => ({ ...prev, [mobile]: isChecked }));
    };
    return (
        <div className={styles.employee} >
            <div className={styles.grid}>
                <div className={styles['edit-bg']}></div>

                <OffBoadingEmployeeInfo token={token} employeeId={employeeId} />

                <div className={styles.col9}>
                    <div className={`${styles['box-shadow']} ${styles['border-radius']} `}>
                        <fieldset className={styles.fieldset}>
                            <legend>Information Technology</legend>
                            <div className={styles.grid} style={{ marginTop: "10px" }}>
                                <div className={styles.col6}>
                                    <div className={styles['right-space']}>
                                        <ul className={styles['two-col']}>
                                            {fields.access_control !== undefined && (
                                                <li>
                                                    <strong>Access Control:</strong>
                                                    <span>
                                                        <input
                                                            type="checkbox"
                                                            name="access_control"
                                                            id="access_control"
                                                            checked={fields.access_control}
                                                            onChange={handleChange}
                                                        />
                                                        <label htmlFor="access_control"></label>

                                                    </span>
                                                    {fields.access_control_no && (
                                                        <textarea
                                                            className={styles.input}
                                                            name="access_control_comment"
                                                            value={fields.access_control_comment}
                                                            onChange={handleChange}
                                                            placeholder="Comment for Access Control"
                                                        />
                                                    )}
                                                </li>
                                            )}

                                            <li>
                                                <strong>AD Account Disabled:</strong>
                                                <span>
                                                    <input
                                                        type="checkbox"
                                                        name="ad_account_disabled"
                                                        id="ad_account_disabled"
                                                        checked={fields.ad_account_disabled}
                                                        onChange={handleChange}
                                                    />
                                                    <label htmlFor="ad_account_disabled">Yes</label>

                                                    <input
                                                        type="checkbox"
                                                        name="ad_account_disabled_no"
                                                        id="ad_account_disabled_no"
                                                        checked={fields.ad_account_disabled_no}
                                                        onChange={handleChange}
                                                    />
                                                    <label htmlFor="ad_account_disabled_no">No</label>
                                                </span>
                                                {fields.ad_account_disabled_no && (
                                                    <textarea
                                                        className={styles.input}
                                                        name="ad_account_disabled_comment"
                                                        value={fields.ad_account_disabled_comment}
                                                        onChange={handleChange}
                                                        placeholder="Comment for AD Account Disabled"
                                                    />
                                                )}
                                            </li>


                                            <li>
                                                <strong>Email Address Removed:</strong>
                                                <span>
                                                    <input
                                                        type="checkbox"
                                                        name="email_address_removed"
                                                        id="email_address_removed"
                                                        checked={fields.email_address_removed}
                                                        onChange={handleChange}
                                                    />
                                                    <label htmlFor="email_address_removed">Yes</label>

                                                    <input
                                                        type="checkbox"
                                                        name="email_address_removed_no"
                                                        id="email_address_removed_no"
                                                        checked={fields.email_address_removed_no}
                                                        onChange={handleChange}
                                                    />
                                                    <label htmlFor="email_address_removed_no">No</label>
                                                </span>
                                                {fields.email_address_removed_no && (
                                                    <textarea
                                                        className={styles.input}
                                                        name="email_address_removed_comment"
                                                        value={fields.email_address_removed_comment}
                                                        onChange={handleChange}
                                                        placeholder="Comment for Email Address Removed"
                                                    />
                                                )}
                                            </li>

                                            {fields.email_backup_classification_comment && (
                                                <li>
                                                    <strong>Email Backup:<p style={{ fontSize: '12px', overflowWrap: 'break-word' }}>({fields.email_backup_classification_comment})</p></strong>
                                                    <span>
                                                        <input
                                                            type="checkbox"
                                                            name="email_backup"
                                                            id="email_backup"
                                                            checked={fields.email_backup}
                                                            onChange={handleChange}
                                                        />
                                                        <label htmlFor="email_backup">Yes</label>

                                                        <input
                                                            type="checkbox"
                                                            name="email_backup_no"
                                                            id="email_backup_no"
                                                            checked={fields.email_backup_no}
                                                            onChange={handleChange}
                                                        />
                                                        <label htmlFor="email_backup_no">No</label>
                                                    </span>
                                                    {fields.email_backup_no && (
                                                        <textarea
                                                            className={styles.input}
                                                            name="email_backup_comment"
                                                            value={fields.email_backup_comment}
                                                            onChange={handleChange}
                                                            placeholder="Comment for Email Backup"
                                                        />
                                                    )}
                                                </li>
                                            )}
                                            {fields.folder_permission && (
                                                <li>
                                                    <strong>Folder Permission:</strong>
                                                    <span>
                                                        <input
                                                            type="checkbox"
                                                            name="folder_permission"
                                                            id="folder_permission"
                                                            checked={fields.folder_permission}
                                                            onChange={handleChange}
                                                        />
                                                        <label htmlFor="folder_permission">Yes</label>

                                                        <input
                                                            type="checkbox"
                                                            name="folder_permission_no"
                                                            id="folder_permission_no"
                                                            checked={fields.folder_permission_no}
                                                            onChange={handleChange}
                                                        />
                                                        <label htmlFor="folder_permission_no">No</label>
                                                    </span>
                                                    {fields.folder_permission_no && (
                                                        <textarea
                                                            className={styles.input}
                                                            name="folder_permission_comment"
                                                            value={fields.folder_permission_comment}
                                                            onChange={handleChange}
                                                            placeholder="Comment for Folder Permission"
                                                        />
                                                    )}
                                                </li>
                                            )}
                                            {fields.google_email_classification_comment && (
                                                <li>
                                                    <strong>Google Email:</strong>
                                                    <span>
                                                        <input
                                                            type="checkbox"
                                                            name="google_email"
                                                            id="google_email"
                                                            checked={fields.google_email}
                                                            onChange={handleChange}
                                                        />
                                                        <label htmlFor="google_email">Yes</label>

                                                        <input
                                                            type="checkbox"
                                                            name="google_email_no"
                                                            id="google_email_no"
                                                            checked={fields.google_email_no}
                                                            onChange={handleChange}
                                                        />
                                                        <label htmlFor="google_email_no">No</label>
                                                    </span>
                                                    {fields.google_email_no && (
                                                        <textarea
                                                            className={styles.input}
                                                            name="google_email_comment"
                                                            value={fields.google_email_comment}
                                                            onChange={handleChange}
                                                            placeholder="Comment for Google Email"
                                                        />
                                                    )}
                                                </li>
                                            )}
                                            {fields.inform_client_classification_comment && (
                                                <li>
                                                    <strong>Inform Client: <p style={{ fontSize: '12px', overflowWrap: 'break-word' }}>({fields.inform_client_classification_comment})</p></strong>
                                                    <span>
                                                        <input
                                                            type="checkbox"
                                                            name="inform_client"
                                                            id="inform_client"
                                                            checked={fields.inform_client}
                                                            onChange={handleChange}
                                                        />
                                                        <label htmlFor="inform_client">Yes</label>

                                                        <input
                                                            type="checkbox"
                                                            name="inform_client_no"
                                                            id="inform_client_no"
                                                            checked={fields.inform_client_no}
                                                            onChange={handleChange}
                                                        />
                                                        <label htmlFor="inform_client_no">No</label>
                                                    </span>
                                                    {fields.inform_client_no && (
                                                        <textarea
                                                            className={styles.input}
                                                            name="inform_client_comment"
                                                            value={fields.inform_client_comment}
                                                            onChange={handleChange}
                                                            placeholder="Comment for Inform Client"
                                                        />
                                                    )}
                                                </li>
                                            )}
                                            {fields.mobile_and_SIM && (
                                                <li>
                                                    <strong>Mobile and SIM:</strong>
                                                    <span>
                                                        <input
                                                            type="checkbox"
                                                            name="mobile_and_SIM"
                                                            id="mobile_and_SIM"
                                                            checked={fields.mobile_and_SIM}
                                                            onChange={handleChange}
                                                        />
                                                        <label htmlFor="mobile_and_SIM">Yes</label>

                                                        <input
                                                            type="checkbox"
                                                            name="mobile_and_SIM_no"
                                                            id="mobile_and_SIM_no"
                                                            checked={fields.mobile_and_SIM_no}
                                                            onChange={handleChange}
                                                        />
                                                        <label htmlFor="mobile_and_SIM_no">No</label>
                                                    </span>
                                                    {fields.mobile_and_SIM_no && (
                                                        <textarea
                                                            className={styles.input}
                                                            name="mobile_and_SIM_comment"
                                                            value={fields.mobile_and_SIM_comment}
                                                            onChange={handleChange}
                                                            placeholder="Comment for Mobile and SIM"
                                                        />
                                                    )}
                                                </li>
                                            )}
                                            {fields.system_backup_classification_comment && (
                                                <li>
                                                    <strong>System Backup: <p style={{ fontSize: '12px', overflowWrap: 'break-word' }}>
                                                        ({fields.system_backup_classification_comment})
                                                    </p></strong>
                                                    <span>
                                                        <input
                                                            type="checkbox"
                                                            name="system_backup"
                                                            id="system_backup"
                                                            checked={fields.system_backup}
                                                            onChange={handleChange}
                                                        />
                                                        <label htmlFor="system_backup">Yes</label>

                                                        <input
                                                            type="checkbox"
                                                            name="system_backup_no"
                                                            id="system_backup_no"
                                                            checked={fields.system_backup_no}
                                                            onChange={handleChange}
                                                        />
                                                        <label htmlFor="system_backup_no">No</label>
                                                    </span>
                                                    {fields.system_backup_no && (
                                                        <textarea
                                                            className={styles.input}
                                                            name="system_backup_comment"
                                                            value={fields.system_backup_comment}
                                                            onChange={handleChange}
                                                            placeholder="Comment for System Backup "
                                                        />
                                                    )}
                                                </li>
                                            )}
                                            {fields.project_backup_classification_comment && (
                                                <li>
                                                    <strong>Project Backup: <p style={{ fontSize: '12px', overflowWrap: 'break-word' }}>({fields.project_backup_classification_comment})</p></strong>
                                                    <span>
                                                        <input
                                                            type="checkbox"
                                                            name="project_backup"
                                                            id="project_backup"
                                                            checked={fields.project_backup}
                                                            onChange={handleChange}
                                                        />
                                                        <label htmlFor="project_backup">Yes</label>

                                                        <input
                                                            type="checkbox"
                                                            name="project_backup_no"
                                                            id="project_backup_no"
                                                            checked={fields.project_backup_no}
                                                            onChange={handleChange}
                                                        />
                                                        <label htmlFor="project_backup_no">No</label>
                                                    </span>
                                                    {fields.project_backup_no && (
                                                        <textarea
                                                            className={styles.input}
                                                            name="project_backup_comment"
                                                            value={fields.project_backup_comment}
                                                            onChange={handleChange}
                                                            placeholder="Comment for Project Backup"
                                                        />
                                                    )}
                                                </li>
                                            )}
                                            {fields.remove_client_folder_access_classification_comment && (
                                                <li>
                                                    <strong>Remove Client Folder Access: <p style={{ fontSize: '12px', overflowWrap: 'break-word' }}>( {fields.remove_client_folder_access_classification_comment})</p></strong>
                                                    <span>
                                                        <input
                                                            type="checkbox"
                                                            name="remove_client_folder_access"
                                                            id="remove_client_folder_access"
                                                            checked={fields.remove_client_folder_access}
                                                            onChange={handleChange}
                                                        />
                                                        <label htmlFor="remove_client_folder_access">Yes</label>

                                                        <input
                                                            type="checkbox"
                                                            name="remove_client_folder_access_no"
                                                            id="remove_client_folder_access_no"
                                                            checked={fields.remove_client_folder_access_no}
                                                            onChange={handleChange}
                                                        />
                                                        <label htmlFor="remove_client_folder_access_no">No</label>
                                                    </span>
                                                    {fields.remove_client_folder_access_no && (
                                                        <textarea
                                                            className={styles.input}
                                                            name="remove_client_folder_access_comment"
                                                            value={fields.remove_client_folder_access_comment}
                                                            onChange={handleChange}
                                                            placeholder="Comment for Remove Client Folder Access"
                                                        />
                                                    )}
                                                </li>
                                            )}

                                            {fields.mail_box_to && (

                                                <li>
                                                    <strong>Mail Box Access: <p style={{ fontSize: '12px', overflowWrap: 'break-word' }}>
                                                            ({fields.mail_box_to})
                                                        </p>
                                                    </strong>
                                                    <span>
                                                        <input
                                                            type="checkbox"
                                                            name="mail_box"
                                                            id="mail_box"
                                                            checked={fields.mail_box}
                                                            onChange={handleChange}
                                                        />
                                                        <label htmlFor="mail_box">Yes</label>

                                                        <input
                                                            type="checkbox"
                                                            name="mail_box_no"
                                                            id="mail_box_no"
                                                            checked={fields.mail_box_no}
                                                            onChange={handleChange}
                                                        />
                                                        <label htmlFor="mail_box_no">No</label>
                                                    </span>
                                                    {fields.mail_box_no && (
                                                        <textarea
                                                            className={styles.input}
                                                            name="mail_box_comment"
                                                            value={fields.mail_box_comment}
                                                            onChange={handleChange}
                                                            placeholder="Comment for Enable Shared"
                                                        />
                                                    )}
                                                </li>
                                            )}
                                            {fields.email_forward_to && (
                                                <li>
                                                    <strong>
                                                        Email Forward:
                                                        <p style={{ fontSize: '12px', overflowWrap: 'break-word' }}>
                                                            ({fields.email_forward_to})
                                                        </p>
                                                    </strong>
                                                    <span>
                                                        <input
                                                            type="checkbox"
                                                            name="email_forward"
                                                            id="email_forward"
                                                            checked={fields.email_forward}
                                                            onChange={handleChange}
                                                        />
                                                        <label htmlFor="email_forward">Yes</label>

                                                        <input
                                                            type="checkbox"
                                                            name="email_forward_no"
                                                            id="email_forward_no"
                                                            checked={fields.email_forward_no}
                                                            onChange={handleChange}
                                                        />
                                                        <label htmlFor="email_forward_no">No</label>
                                                    </span>
                                                    {fields.email_forward_no && (
                                                        <textarea
                                                            className={styles.input}
                                                            name="email_forward_comment"
                                                            value={fields.email_forward_comment}
                                                            onChange={handleChange}
                                                            placeholder="Comment for Email Forward"
                                                        />
                                                    )}
                                                </li>
                                            )}
                                            {fields.time_duration && (
                                                <li>
                                                    <strong>Forward email/Mail Box Time Duration(Months):</strong>
                                                    <span>
                                                        <input
                                                            type="text"
                                                            name="time_duration"
                                                            value={fields.time_duration}
                                                            onChange={handleChange}
                                                            placeholder="Enter Time Duration"
                                                            className={styles.input}
                                                            disabled
                                                        />
                                                    </span>

                                                </li>

                                            )}
                                        </ul>
                                    </div>
                                </div>

                                <div className={styles.col6}>
                                    <div className={styles['left-space']}>
                                        <ul className={styles['two-col']}>

                                            <li>
                                                <strong>PC Equipment:</strong>
                                                <span>
                                                    <input
                                                        type="checkbox"
                                                        name="Headphone"
                                                        id="Headphone"
                                                        checked={pcEquipment.Headphone}
                                                        onChange={(e) => setPCEquipment({ ...pcEquipment, Headphone: e.target.checked })}
                                                    />
                                                    <label htmlFor="Headphone">Headphone</label>

                                                    <input
                                                        type="checkbox"
                                                        name="Monitor"
                                                        id="Monitor"
                                                        checked={pcEquipment.Monitor}
                                                        onChange={(e) => setPCEquipment({ ...pcEquipment, Monitor: e.target.checked })}
                                                    />
                                                    <label htmlFor="Monitor">Monitor</label>

                                                    <input
                                                        type="checkbox"
                                                        name="Mouse"
                                                        id="Mouse"
                                                        checked={pcEquipment.Mouse}
                                                        onChange={(e) => setPCEquipment({ ...pcEquipment, Mouse: e.target.checked })}
                                                    />
                                                    <label htmlFor="Mouse">Mouse</label>

                                                    <input
                                                        type="checkbox"
                                                        name="Keyboard"
                                                        id="Keyboard"
                                                        checked={pcEquipment.Keyboard}
                                                        onChange={(e) => setPCEquipment({ ...pcEquipment, Keyboard: e.target.checked })}
                                                    />
                                                    <label htmlFor="Keyboard">Keyboard</label>
                                                </span>
                                            </li>
                                            {Object.keys(softwareAccess).length > 0 && (
                                            <li>
                                                <strong>Software Access:</strong>
                                                <span>
                                                    {Object.entries(softwareAccess).map(([software, isChecked]) => (
                                                        <div key={software}>
                                                            <input
                                                                type="checkbox"
                                                                name={software}
                                                                id={software}
                                                                checked={isChecked}
                                                                onChange={(e) => setSoftwareAccess(prev => ({ ...prev, [software]: e.target.checked }))}
                                                                disabled={buttonsDisabled}
                                                            />
                                                            <label htmlFor={software}>{software.charAt(0).toUpperCase() + software.slice(1)}</label>
                                                        </div>
                                                    ))}
                                                </span>
                                                {fields.software_access_classification_comment && (
                                                    <textarea
                                                        className={styles.input}
                                                        name="software_access_classification_comment"
                                                        value={fields.software_access_classification_comment}
                                                        onChange={handleChange}
                                                        placeholder="Comment for Software access"
                                                    />
                                                )}
                                            </li>
                                             )}
                                            {Object.keys(mobileAccess).length > 0 && (
                                                <li>
                                                    <strong>Mobile Access:</strong>
                                                    <span>
                                                        {Object.entries(mobileAccess).map(([mobile, isChecked]) => (
                                                            <div key={mobile}>
                                                                <input
                                                                    type="checkbox"
                                                                    name={mobile}
                                                                    id={mobile}
                                                                    checked={isChecked}
                                                                    onChange={(e) => setMobileAccess(prev => ({ ...prev, [mobile]: e.target.checked }))}
                                                                    disabled={buttonsDisabled}
                                                                />
                                                                <label htmlFor={mobile}>{mobile.charAt(0).toUpperCase() + mobile.slice(1)}</label>
                                                            </div>
                                                        ))}
                                                    </span>

                                                    {fields.mobile_access_classification_comment && (
                                                        <textarea
                                                            className={styles.input}
                                                            name="mobile_access_classification_comment"
                                                            value={fields.mobile_access_classification_comment}
                                                            onChange={handleChange}
                                                            placeholder="Comment for Mobile access"
                                                        />
                                                    )}
                                                </li>
                                            )}

                                        </ul>
                                    </div>
                                </div>

                                <div className={styles.col12}>
                                    <label>
                                        <strong>Comment<i className={styles['red']}>*</i>:</strong>
                                        <textarea
                                            name="comment"
                                            className={styles.input}
                                            rows={3}
                                            cols={60}
                                            value={fields.comment}
                                            onChange={(e) => setFields(prevState => ({
                                                ...prevState,
                                                comment: e.target.value
                                            }))}
                                        />
                                    </label>
                                </div>
                                <div className={styles['button-block']}>
                                    <input type="submit" value="Save" className={styles.button} onClick={handleSaveInfo} disabled={loading || buttonsDisabled || department === "Human Resources"} />
                                    <input type="submit" value={buttonsDisabled ? 'Submitted' : 'Submit'} className={styles.button} onClick={handleSubmit} disabled={loading || buttonsDisabled || department === "Human Resources"} />
                                    <ToastContainer />
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
                {loading && <div className={`${styles['loader-center']} ${styles['loaderHeight-sales']} `}>
                    <div className={`${styles['newsloader-container']} ${styles['newsloaderContainer-payroll']} `}>
                        <div className={styles.newsloader}></div>
                    </div>
                </div>}
            </div>
        </div>
    );
};

export default OffBoadingIT;
