import React from 'react';
import styles from '../MainContent/Main.module.scss'

const ProgressBar = ({ currentStep, totalSteps }) => {
    const progressWidth = (currentStep / totalSteps) * 100;

    return (
        <div className={styles['progress-bar']}>
            <span style={{ width: `${progressWidth}%` }}></span>
        </div>
    );
};

export default ProgressBar;