import React from 'react';
import styles from '../MainContent/Main.module.scss';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className={styles.copyrights}>
      &copy; All rights reserved. 2003 - {currentYear}
    </div>
  );
};

export default Footer;
