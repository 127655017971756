import React, { useState, useEffect } from 'react';
import { useToken } from '../../../context/AuthContext'
import styles from '../MainContent/Main.module.scss';
import InputField from '../../../components/InputField/InputField'
import SelectField from '../../../components/SelectField/SelectField'
import Button from '../../../components/Button/Button'
import Family from '../Details/Family'
import ProgressBar from './ProgressBar'
import GetCandidateDetails from '../../../api/fetchCandidateData'
import SaveCandidateData from '../../../api/SaveCandidateData';
import StepEmitter from '../../../components/StepEmitter/StepEmitter'

const Address = ({ step, setStep, isEdit, setIsEdit, handlePreviewClick, apiData,experiData }) => {
  const { token, zoho_candidate_id } = useToken();
  const [showRreview, setshowRreview] = useState(false);
  const [basicData, setbasicData] = useState(null);
  const nameRegex = /^[A-Za-z.-]+(\s*[A-Za-z.-]+)*$/;
  const countries = [
    { value: '-1', label: 'Select Country' },
    { value: 'Afghanistan', label: 'Afghanistan' },
    { value: 'Albania', label: 'Albania' },
    { value: 'Algeria', label: 'Algeria' },
    { value: 'American Samoa', label: 'American Samoa' },
    { value: 'Angola', label: 'Angola' },
    { value: 'Anguilla', label: 'Anguilla' },
    { value: 'Antarctica', label: 'Antarctica' },
    { value: 'Antigua and Barbuda', label: 'Antigua and Barbuda' },
    { value: 'Argentina', label: 'Argentina' },
    { value: 'Armenia', label: 'Armenia' },
    { value: 'Aruba', label: 'Aruba' },
    { value: 'Ashmore and Cartier Island', label: 'Ashmore and Cartier Island' },
    { value: 'Australia', label: 'Australia' },
    { value: 'Austria', label: 'Austria' },
    { value: 'Azerbaijan', label: 'Azerbaijan' },
    { value: 'Bahamas', label: 'Bahamas' },
    { value: 'Bahrain', label: 'Bahrain' },
    { value: 'Bangladesh', label: 'Bangladesh' },
    { value: 'Barbados', label: 'Barbados' },
    { value: 'Belarus', label: 'Belarus' },
    { value: 'Belgium', label: 'Belgium' },
    { value: 'Belize', label: 'Belize' },
    { value: 'Benin', label: 'Benin' },
    { value: 'Bermuda', label: 'Bermuda' },
    { value: 'Bhutan', label: 'Bhutan' },
    { value: 'Bolivia', label: 'Bolivia' },
    { value: 'Bosnia and Herzegovina', label: 'Bosnia and Herzegovina' },
    { value: 'Botswana', label: 'Botswana' },
    { value: 'Brazil', label: 'Brazil' },
    { value: 'British Virgin Islands', label: 'British Virgin Islands' },
    { value: 'Brunei', label: 'Brunei' },
    { value: 'Bulgaria', label: 'Bulgaria' },
    { value: 'Burkina Faso', label: 'Burkina Faso' },
    { value: 'Burma', label: 'Burma' },
    { value: 'Burundi', label: 'Burundi' },
    { value: 'Cambodia', label: 'Cambodia' },
    { value: 'Cameroon', label: 'Cameroon' },
    { value: 'Canada', label: 'Canada' },
    { value: 'Cape Verde', label: 'Cape Verde' },
    { value: 'Cayman Islands', label: 'Cayman Islands' },
    { value: 'Central African Republic', label: 'Central African Republic' },
    { value: 'Chad', label: 'Chad' },
    { value: 'Chile', label: 'Chile' },
    { value: 'China', label: 'China' },
    { value: 'Christmas Island', label: 'Christmas Island' },
    { value: 'Clipperton Island', label: 'Clipperton Island' },
    { value: 'Cocos (Keeling) Islands', label: 'Cocos (Keeling) Islands' },
    { value: 'Colombia', label: 'Colombia' },
    { value: 'Comoros', label: 'Comoros' },
    { value: 'Congo, Democratic Republic of the', label: 'Congo, Democratic Republic of the' },
    { value: 'Congo, Republic of the', label: 'Congo, Republic of the' },
    { value: 'Cook Islands', label: 'Cook Islands' },
    { value: 'Costa Rica', label: 'Costa Rica' },
    { value: 'Cote d\'Ivoire', label: 'Cote d\'Ivoire' },
    { value: 'Croatia', label: 'Croatia' },
    { value: 'Cuba', label: 'Cuba' },
    { value: 'Cyprus', label: 'Cyprus' },
    { value: 'Czech Republic', label: 'Czech Republic' },
    { value: 'Denmark', label: 'Denmark' },
    { value: 'Djibouti', label: 'Djibouti' },
    { value: 'Dominica', label: 'Dominica' },
    { value: 'Dominican Republic', label: 'Dominican Republic' },
    { value: 'Ecuador', label: 'Ecuador' },
    { value: 'Egypt', label: 'Egypt' },
    { value: 'El Salvador', label: 'El Salvador' },
    { value: 'Equatorial Guinea', label: 'Equatorial Guinea' },
    { value: 'Eritrea', label: 'Eritrea' },
    { value: 'Estonia', label: 'Estonia' },
    { value: 'Ethiopia', label: 'Ethiopia' },
    { value: 'Europa Island', label: 'Europa Island' },
    { value: 'Falkland Islands (Islas Malvinas)', label: 'Falkland Islands (Islas Malvinas)' },
    { value: 'Faroe Islands', label: 'Faroe Islands' },
    { value: 'Fiji', label: 'Fiji' },
    { value: 'Finland', label: 'Finland' },
    { value: 'France', label: 'France' },
    { value: 'French Guiana', label: 'French Guiana' },
    { value: 'French Polynesia', label: 'French Polynesia' },
    { value: 'French Southern and Antarctic Lands', label: 'French Southern and Antarctic Lands' },
    { value: 'Gabon', label: 'Gabon' },
    { value: 'Gambia, The', label: 'Gambia, The' },
    { value: 'Gaza Strip', label: 'Gaza Strip' },
    { value: 'Georgia', label: 'Georgia' },
    { value: 'Germany', label: 'Germany' },
    { value: 'Ghana', label: 'Ghana' },
    { value: 'Gibraltar', label: 'Gibraltar' },
    { value: 'Glorioso Islands', label: 'Glorioso Islands' },
    { value: 'Greece', label: 'Greece' },
    { value: 'Greenland', label: 'Greenland' },
    { value: 'Grenada', label: 'Grenada' },
    { value: 'Guadeloupe', label: 'Guadeloupe' },
    { value: 'Guam', label: 'Guam' },
    { value: 'Guatemala', label: 'Guatemala' },
    { value: 'Guernsey', label: 'Guernsey' },
    { value: 'Guinea', label: 'Guinea' },
    { value: 'Guinea-Bissau', label: 'Guinea-Bissau' },
    { value: 'Guyana', label: 'Guyana' },
    { value: 'Haiti', label: 'Haiti' },
    { value: 'Heard Island and McDonald Islands', label: 'Heard Island and McDonald Islands' },
    { value: 'Holy See (Vatican City)', label: 'Holy See (Vatican City)' },
    { value: 'Honduras', label: 'Honduras' },
    { value: 'Hong Kong', label: 'Hong Kong' },
    { value: 'Howland Island', label: 'Howland Island' },
    { value: 'Hungary', label: 'Hungary' },
    { value: 'Iceland', label: 'Iceland' },
    { value: 'India', label: 'India' },
    { value: 'Indonesia', label: 'Indonesia' },
    { value: 'Iran', label: 'Iran' },
    { value: 'Iraq', label: 'Iraq' },
    { value: 'Ireland', label: 'Ireland' },
    { value: 'Ireland, Northern', label: 'Ireland, Northern' },
    { value: 'Israel', label: 'Israel' },
    { value: 'Italy', label: 'Italy' },
    { value: 'Jamaica', label: 'Jamaica' },
    { value: 'Jan Mayen', label: 'Jan Mayen' },
    { value: 'Japan', label: 'Japan' },
    { value: 'Jarvis Island', label: 'Jarvis Island' },
    { value: 'Jersey', label: 'Jersey' },
    { value: 'Johnston Atoll', label: 'Johnston Atoll' },
    { value: 'Jordan', label: 'Jordan' },
    { value: 'Juan de Nova Island', label: 'Juan de Nova Island' },
    { value: 'Kazakhstan', label: 'Kazakhstan' },
    { value: 'Kenya', label: 'Kenya' },
    { value: 'Kiribati', label: 'Kiribati' },
    { value: 'Korea, North', label: 'Korea, North' },
    { value: 'Korea, South', label: 'Korea, South' },
    { value: 'Kuwait', label: 'Kuwait' },
    { value: 'Kyrgyzstan', label: 'Kyrgyzstan' },
    { value: 'Laos', label: 'Laos' },
    { value: 'Latvia', label: 'Latvia' },
    { value: 'Lebanon', label: 'Lebanon' },
    { value: 'Lesotho', label: 'Lesotho' },
    { value: 'Liberia', label: 'Liberia' },
    { value: 'Libya', label: 'Libya' },
    { value: 'Liechtenstein', label: 'Liechtenstein' },
    { value: 'Lithuania', label: 'Lithuania' },
    { value: 'Luxembourg', label: 'Luxembourg' },
    { value: 'Macau', label: 'Macau' },
    { value: 'Macedonia, Former Yugoslav Republic of', label: 'Macedonia, Former Yugoslav Republic of' },
    { value: 'Madagascar', label: 'Madagascar' },
    { value: 'Malawi', label: 'Malawi' },
    { value: 'Malaysia', label: 'Malaysia' },
    { value: 'Maldives', label: 'Maldives' },
    { value: 'Mali', label: 'Mali' },
    { value: 'Malta', label: 'Malta' },
    { value: 'Man, Isle of', label: 'Man, Isle of' },
    { value: 'Marshall Islands', label: 'Marshall Islands' },
    { value: 'Martinique', label: 'Martinique' },
    { value: 'Mauritania', label: 'Mauritania' },
    { value: 'Mauritius', label: 'Mauritius' },
    { value: 'Mayotte', label: 'Mayotte' },
    { value: 'Mexico', label: 'Mexico' },
    { value: 'Micronesia, Federated States of', label: 'Micronesia, Federated States of' },
    { value: 'Midway Islands', label: 'Midway Islands' },
    { value: 'Moldova', label: 'Moldova' },
    { value: 'Monaco', label: 'Monaco' },
    { value: 'Mongolia', label: 'Mongolia' },
    { value: 'Montserrat', label: 'Montserrat' },
    { value: 'Morocco', label: 'Morocco' },
    { value: 'Mozambique', label: 'Mozambique' },
    { value: 'Namibia', label: 'Namibia' },
    { value: 'Nauru', label: 'Nauru' },
    { value: 'Nepal', label: 'Nepal' },
    { value: 'Netherlands', label: 'Netherlands' },
    { value: 'Netherlands Antilles', label: 'Netherlands Antilles' },
    { value: 'New Caledonia', label: 'New Caledonia' },
    { value: 'New Zealand', label: 'New Zealand' },
    { value: 'Nicaragua', label: 'Nicaragua' },
    { value: 'Niger', label: 'Niger' },
    { value: 'Nigeria', label: 'Nigeria' },
    { value: 'Niue', label: 'Niue' },
    { value: 'Norfolk Island', label: 'Norfolk Island' },
    { value: 'Northern Mariana Islands', label: 'Northern Mariana Islands' },
    { value: 'Norway', label: 'Norway' },
    { value: 'Oman', label: 'Oman' },
    { value: 'Pakistan', label: 'Pakistan' },
    { value: 'Palau', label: 'Palau' },
    { value: 'Panama', label: 'Panama' },
    { value: 'Papua New Guinea', label: 'Papua New Guinea' },
    { value: 'Paraguay', label: 'Paraguay' },
    { value: 'Peru', label: 'Peru' },
    { value: 'Philippines', label: 'Philippines' },
    { value: 'Pitcaim Islands', label: 'Pitcaim Islands' },
    { value: 'Poland', label: 'Poland' },
    { value: 'Portugal', label: 'Portugal' },
    { value: 'Puerto Rico', label: 'Puerto Rico' },
    { value: 'Qatar', label: 'Qatar' },
    { value: 'Reunion', label: 'Reunion' },
    { value: 'Romainia', label: 'Romainia' },
    { value: 'Russia', label: 'Russia' },
    { value: 'Rwanda', label: 'Rwanda' },
    { value: 'Saint Helena', label: 'Saint Helena' },
    { value: 'Saint Kitts and Nevis', label: 'Saint Kitts and Nevis' },
    { value: 'Saint Lucia', label: 'Saint Lucia' },
    { value: 'Saint Pierre and Miquelon', label: 'Saint Pierre and Miquelon' },
    { value: 'Saint Vincent and the Grenadines', label: 'Saint Vincent and the Grenadines' },
    { value: 'Samoa', label: 'Samoa' },
    { value: 'San Marino', label: 'San Marino' },
    { value: 'Sao Tome and Principe', label: 'Sao Tome and Principe' },
    { value: 'Saudi Arabia', label: 'Saudi Arabia' },
    { value: 'Scotland', label: 'Scotland' },
    { value: 'Senegal', label: 'Senegal' },
    { value: 'Seychelles', label: 'Seychelles' },
    { value: 'Sierra Leone', label: 'Sierra Leone' },
    { value: 'Singapore', label: 'Singapore' },
    { value: 'Slovakia', label: 'Slovakia' },
    { value: 'Slovenia', label: 'Slovenia' },
    { value: 'Solomon Islands', label: 'Solomon Islands' },
    { value: 'Somalia', label: 'Somalia' },
    { value: 'South Africa', label: 'South Africa' },
    { value: 'South Georgia and South Sandwich Islands', label: 'South Georgia and South Sandwich Islands' },
    { value: 'Spain', label: 'Spain' },
    { value: 'Spratly Islands', label: 'Spratly Islands' },
    { value: 'Sri Lanka', label: 'Sri Lanka' },
    { value: 'Sudan', label: 'Sudan' },
    { value: 'Suriname', label: 'Suriname' },
    { value: 'Svalbard', label: 'Svalbard' },
    { value: 'Swaziland', label: 'Swaziland' },
    { value: 'Sweden', label: 'Sweden' },
    { value: 'Switzerland', label: 'Switzerland' },
    { value: 'Syria', label: 'Syria' },
    { value: 'Taiwan', label: 'Taiwan' },
    { value: 'Tajikistan', label: 'Tajikistan' },
    { value: 'Tanzania', label: 'Tanzania' },
    { value: 'Thailand', label: 'Thailand' },
    { value: 'Tobago', label: 'Tobago' },
    { value: 'Toga', label: 'Toga' },
    { value: 'Tokelau', label: 'Tokelau' },
    { value: 'Tonga', label: 'Tonga' },
    { value: 'Trinidad', label: 'Trinidad' },
    { value: 'Tunisia', label: 'Tunisia' },
    { value: 'Turkey', label: 'Turkey' },
    { value: 'Turkmenistan', label: 'Turkmenistan' },
    { value: 'Tuvalu', label: 'Tuvalu' },
    { value: 'Uganda', label: 'Uganda' },
    { value: 'Ukraine', label: 'Ukraine' },
    { value: 'United Arab Emirates', label: 'United Arab Emirates' },
    { value: 'United Kingdom', label: 'United Kingdom' },
    { value: 'Uruguay', label: 'Uruguay' },
    { value: 'USA', label: 'USA' },
    { value: 'Uzbekistan', label: 'Uzbekistan' },
    { value: 'Vanuatu', label: 'Vanuatu' },
    { value: 'Venezuela', label: 'Venezuela' },
    { value: 'Vietnam', label: 'Vietnam' },
    { value: 'Virgin Islands', label: 'Virgin Islands' },
    { value: 'Wales', label: 'Wales' },
    { value: 'Wallis and Futuna', label: 'Wallis and Futuna' },
    { value: 'West Bank', label: 'West Bank' },
    { value: 'Western Sahara', label: 'Western Sahara' },
    { value: 'Yemen', label: 'Yemen' },
    { value: 'Yugoslavia', label: 'Yugoslavia' },
    { value: 'Zambia', label: 'Zambia' },
    { value: 'Zimbabwe', label: 'Zimbabwe' }
  ];
  const [showPermanentAddress, setShowPermanentAddress] = useState(true);
  const totalSteps = 12;

  const [showFamily, setShowFamily] = useState(false);
  const [formData, setFormData] = useState({
    Present_Address: '',
    Present_City: '',
    Present_State: '',
    Present_Country: '',
    Present_Zip_Code: '',
    Present_Phone: '',
    Permanent_Address: '',
    Permanent_Country: '',
    Permanent_State: '',
    Permanent_City: '',
    Permanent_Zip_Code: '',
    Present_Phones: [],

  });

  const [errors, setErrors] = useState({
    Present_Address: '',
    Present_City: '',
    Present_State: '',
    Present_Country: '',
    Present_Zip_Code: '',
    Present_Phone: '',
    Permanent_Address: '',
    Permanent_City: '',
    Permanent_State: '',
    Permanent_Country: '',
    Permanent_Zip_Code: ''
  });

  const [mobileCount, setMobileCount] = useState(formData.Present_Phones.length);
  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    // Validation logic for each field
    if (!formData.Present_Address.trim()) {
      newErrors.Present_Address = 'Address is required';
      isValid = false;
    }

    if (!formData.Present_City.trim()) {
      newErrors.Present_City = 'City is required';
      isValid = false;
    }
    else if(!nameRegex.test(formData.Present_City.trim())){
      newErrors.Present_City = 'City should be characters.';
      isValid = false;
  }

    if (!formData.Present_State.trim()) {
      newErrors.Present_State = 'State is required';
      isValid = false;
    }
    else if(!nameRegex.test(formData.Present_State.trim())){
      newErrors.Present_State = 'State should be characters.';
      isValid = false;
  }

    if (formData.Present_Country === '-1') {
      newErrors.Present_Country = 'Please select a country';
      isValid = false;
    }

    if (!formData.Present_Zip_Code.trim()) {
      newErrors.Present_Zip_Code = 'Zip Code is required';
      isValid = false;
    }else if (!/^\d{6}$/.test(formData.Present_Zip_Code)) {
      newErrors.Present_Zip_Code = 'Zip Code must contain exactly 6 digits';
      isValid = false;
    }

    if (!formData.Present_Phone.trim()) {
      newErrors.Present_Phone = 'Mobile is required';
      isValid = false;
    }
   else if (formData.Present_Phone < 0) {
      newErrors.Present_Phone = 'Mobile Number should be positive.';
      isValid = false;
  }
    //console.log(showPermanentAddress);
    if (!showPermanentAddress) {
      if (!formData.Permanent_Address.trim()) {
        newErrors.Permanent_Address = 'Permanent Address is required';
        isValid = false;
      }


      if (!formData.Permanent_City.trim()) {
        newErrors.Permanent_City = 'City is required';
        isValid = false;
      }

      if (!formData.Permanent_State.trim()) {
        newErrors.Permanent_State = 'State is required';
        isValid = false;
      }

      if (formData.Permanent_Country === '-1') {
        newErrors.Permanent_Country = 'Please select a country';
        isValid = false;
      }

      if (!formData.Permanent_Zip_Code.trim()) {
        newErrors.Permanent_Zip_Code = 'Zip Code is required';
        isValid = false;
      } else if (!/^\d{6}$/.test(formData.Permanent_Zip_Code)) {
        newErrors.Permanent_Zip_Code = 'Zip Code must contain exactly 6 digits';
        isValid = false;
    }
    }
    setErrors(newErrors);
    return isValid;
  };


  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
   
    setShowPermanentAddress(isChecked);
    if (isChecked) {
      // If checkbox is checked, copy present address to permanent address
      setFormData({
        ...formData,
        Permanent_Address: formData.Present_Address,
        Permanent_City: formData.Present_City,
        Permanent_State: formData.Present_State,
        Permanent_Country: formData.Present_Country,
        Permanent_Zip_Code: formData.Present_Zip_Code
      });
      setErrors({
        ...errors,
        Permanent_Address: '',
        Permanent_City: '',
        Permanent_State: '',
        Permanent_Country: '',
        Permanent_Zip_Code: ''
      });
    } else {
      // If checkbox is unchecked, clear permanent address fields
      setFormData({
        ...formData,
        Permanent_Address: '',
        Permanent_City: '',
        Permanent_State: '',
        Permanent_Country: '',
        Permanent_Zip_Code: ''
      });
      setErrors({
        ...errors,
        Permanent_Address: '',
        Permanent_City: '',
        Permanent_State: '',
        Permanent_Country: '',
        Permanent_Zip_Code: ''
      });
    }
  };


  const fetchCandidateDetails = async () => {
    try {
      const apiData = await GetCandidateDetails(token, zoho_candidate_id);
  
      const basicDetails = apiData.data.basic_details;
      const mobileFields = Object.keys(basicDetails).filter(key => key.startsWith('Present_Phone'));
      const Present_Phones = mobileFields.map(key => basicDetails[key]).filter(Present_Phone => Present_Phone !== null);
     
      
      const isPermanentSameAsPresent = (
        basicDetails.Permanent_Address === basicDetails.Present_Address &&
        basicDetails.Permanent_City === basicDetails.Present_City &&
        basicDetails.Permanent_State === basicDetails.Present_State &&
        basicDetails.Permanent_Country === basicDetails.Present_Country &&
        basicDetails.Permanent_Zip_Code === basicDetails.Present_Zip_Code
      );
  
      setShowPermanentAddress(isPermanentSameAsPresent);
  
      setFormData({
        ...formData,
        Present_Address: basicDetails.Present_Address || '',
        Present_City: basicDetails.Present_City || '',
        Present_State: basicDetails.Present_State || '',
        Present_Country: basicDetails.Present_Country || '',
        Present_Zip_Code: basicDetails.Present_Zip_Code || '',
        Present_Phone: basicDetails.Present_Phone || '',
        Permanent_Address: basicDetails.Permanent_Address || '',
        Permanent_City: basicDetails.Permanent_City || '',
        Permanent_State: basicDetails.Permanent_State || '',
        Permanent_Country: basicDetails.Permanent_Country || '',
        Permanent_Zip_Code: basicDetails.Permanent_Zip_Code || '',
        Present_Phones: Present_Phones
      });
  
      setbasicData(basicDetails);
      setMobileCount(Present_Phones.length);
    } catch (error) {
      console.error('Error fetching candidate details:', error);
    }
  };
  
  

  useEffect(() => {
    fetchCandidateDetails();
  }, []);

  const handleBackClick = () => {
    //setStep(3);
    StepEmitter.emit('stepChange', step - 1);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    const newErrors = { ...errors };
    if (!value.trim()) {
      newErrors[name] = 'This field is required.';
    } else {
      delete newErrors[name];
    }
    setErrors(newErrors);
  };

  const handleContinueClick = async () => {
    if (validateForm()) {
      const Present_Phones = formData.Present_Phones.slice(1).join(', ');
      const [Present_Phone2, Present_Phone3] = formData.Present_Phones.slice(1, 3);
      try {
        // Define data to be saved
        let dataToSave = {
          basic_details: {
            ...formData,
            First_Name: basicData.First_Name || '',
            Middle_Name: basicData.Middle_Name || '',
            Last_Name: basicData.Last_Name || '',
            Height: basicData.Height || '',
            Weight: basicData.Weight || '',
            Date_Of_Birth: basicData.Date_Of_Birth || '',
            Blood_Group: basicData.Blood_Group || '',
            Identification_Marks: basicData.Identification_Marks || '',
            Email: basicData.Email || '',
            Email2: basicData.Email2 || '',
            Email3: basicData.Email3 || '',
            Gender: basicData.Gender || '',
            Marital_Status: basicData.Marital_Status || '',
            Zoho_Candidate_Id: zoho_candidate_id,
            Zoho_Item_Id: zoho_candidate_id,
            Job_Opening_Id: zoho_candidate_id,
            FormStatus: 'Pending',
            Present_Phone2,
            Present_Phone3
          },
          Zoho_Item_Id: zoho_candidate_id,
          Process_Status: "5"
        };
  
        // If showPermanentAddress is true, set permanent address data same as present address
        if (showPermanentAddress) {
          dataToSave = {
            ...dataToSave,
            basic_details: {
              ...dataToSave.basic_details,
              Permanent_Address: formData.Present_Address,
              Permanent_City: formData.Present_City,
              Permanent_State: formData.Present_State,
              Permanent_Country: formData.Present_Country,
              Permanent_Zip_Code: formData.Present_Zip_Code
            }
          };
        }
  
        // Save data
        const response = await SaveCandidateData(dataToSave);
  
        if (response.status === 200) {
          setShowFamily(true);
          StepEmitter.emit('stepChange', step + 1);
        } else {
          console.error('Error saving candidate data:', response.statusText);
        }
      } catch (error) {
        console.error('Error saving candidate data:', error);
      }
    }
  };
  

  const handlePreviewPage = async () => {
    if (validateForm()) {
      const Present_Phones = formData.Present_Phones.slice(1).join(', ');
      const [Present_Phone2, Present_Phone3] = formData.Present_Phones.slice(1, 3);
      try {
        // Define data to be saved
        let dataToSave = {
          basic_details: {
            ...formData,
            First_Name: basicData.First_Name || '',
            Middle_Name: basicData.Middle_Name || '',
            Last_Name: basicData.Last_Name || '',
            Height: basicData.Height || '',
            Weight: basicData.Weight || '',
            Date_Of_Birth: basicData.Date_Of_Birth || '',
            Blood_Group: basicData.Blood_Group || '',
            Identification_Marks: basicData.Identification_Marks || '',
            Email: basicData.Email || '',
            Email2: basicData.Email2 || '',
            Email3: basicData.Email3 || '',
            Gender: basicData.Gender || '',
            Marital_Status: basicData.Marital_Status || '',
            Zoho_Candidate_Id: zoho_candidate_id,
            Zoho_Item_Id: zoho_candidate_id,
            Job_Opening_Id: zoho_candidate_id,
            FormStatus: 'Pending',
            Present_Phone2,
            Present_Phone3
          },
          Zoho_Item_Id: zoho_candidate_id,
          Process_Status: "4"
        };
  
        // If showPermanentAddress is true, set permanent address data same as present address
        if (showPermanentAddress) {
          dataToSave = {
            ...dataToSave,
            basic_details: {
              ...dataToSave.basic_details,
              Permanent_Address: formData.Present_Address,
              Permanent_City: formData.Present_City,
              Permanent_State: formData.Present_State,
              Permanent_Country: formData.Present_Country,
              Permanent_Zip_Code: formData.Present_Zip_Code
            }
          };
        }
  
        //console.log(dataToSave);
        const response = await SaveCandidateData(dataToSave);
        //console.log('Form data saved:', response);

        if (response.status === 200) {
          setshowRreview(true);

          setStep(11);
          StepEmitter.emit('stepChange', 11);
        } else {
          console.error('Error saving candidate data:', response.statusText);

        }
      } catch (error) {
        console.error('Error saving candidate data:', error);

      }
    }
  };


  const handleRemoveMobile = (index) => {
    const updatedPresent_Phones = [...formData.Present_Phones];
    updatedPresent_Phones.splice(index, 1);

    setFormData(prevFormData => ({
      ...prevFormData,
      Present_Phones: updatedPresent_Phones
    }));

    setMobileCount(prevCount => prevCount - 1);
  };

  const handleAddMobile = () => {

    if (mobileCount < 3) {
      setMobileCount(prevCount => prevCount + 1);
      setFormData(prevFormData => ({
        ...prevFormData,
        Present_Phones: [...prevFormData.Present_Phones, '']
      }));
    }
  };


  const handleMobileChange = (value, index) => {
    const updatedPresent_Phones = [...formData.Present_Phones];
    updatedPresent_Phones[index] = value;

    setFormData(prevFormData => ({
      ...prevFormData,
      Present_Phones: updatedPresent_Phones
    }));
  };

  const renderMobileInputs = () => {
    return formData.Present_Phones.slice(1).map((phone, index) => (
      <div key={index + 1}>
        <input
          type="number"
          className={styles.input}
          name={`Present_Phone${index + 2}`}
          value={phone}
          onChange={(e) => handleMobileChange(e.target.value, index + 1)}
        />
        <input
          type="button"
          value="Remove"
          className={styles.remove}
          onClick={() => handleRemoveMobile(index + 1)}
        />
      </div>
    ));
  };



  return (
    <>
      {step === 4 && (
        <>
          <ProgressBar currentStep={step} totalSteps={totalSteps} />
          <div className={styles['required-details']}>Kindly fill the required details <span>*</span> of the form below.</div>
          <h4 className={styles.heading1} style={{ display: step === 4 ? 'block' : 'none' }}><span>Basic Details</span></h4>

          <div className={`${styles['row-c']} ${styles.width} ${styles.addrESS}`} style={{ display: step === 4 ? 'block' : 'none' }}>
            <form id={styles.addrESS} name="addrESS">
              <div className={styles['col-left']}>
                <h5>Present Address</h5>
                <ul>
                  <li> <span className={styles.span}>Address<i className={styles.red}>*</i>:</span>
                    <div className={styles.div}>
                      <textarea id="address1" name="Present_Address" className={`${styles.input} ${styles.textarea} ${errors.Present_Address ? styles.error : ''}`} required="required" aria-required="true"
                        aria-invalid={errors.Present_Address ? 'true' : 'false'} onChange={(e) => {
                          handleInputChange(e);
                          setFormData({ ...formData, Present_Address: e.target.value });
                          setErrors({ ...errors, Present_Address: '' });
                        }}
                        value={formData.Present_Address}
                      ></textarea>
                      {errors.Present_Address && <label id="address1-error" className={styles.error} htmlFor="Present_Address" style={{ display: 'inline' }}>{errors.Present_Address}</label>}

                      <input type="hidden" name="formStep" className={styles.input} value="3" />
                    </div>
                  </li>
                  <li> <span className={styles.span}></span>
                    <div className={styles.div}>
                      <div className={styles['row-c']}>
                        <div className={styles['td-left']}> <strong className={styles.strong1}>City<i className={styles.red}>*</i>:</strong>
                          <InputField
                            type="text"
                            id="Present_City"
                            name="Present_City"
                            className={styles.input}
                            value={formData.Present_City}
                            error={errors.Present_City}
                            onChange={(e) => {
                              handleInputChange(e);
                              setFormData({ ...formData, Present_City: e.target.value })
                            }}
                            required
                            aria-required="true"
                            mainClass=""
                          />
                        </div>
                        <div className={styles['td-right']}> <strong className={styles.strong1}>State<i className={styles.red}>*</i>:</strong>
                          <InputField
                            type="text"
                            id="Present_State"
                            name="Present_State"
                            className={styles.input}
                            value={formData.Present_State}
                            error={errors.Present_State}
                            onChange={(e) => {
                              handleInputChange(e);
                              setFormData({ ...formData, Present_State: e.target.value })
                            }}
                            required
                            aria-required="true"
                            mainClass={styles.div}
                          />
                        </div>
                        <div className="clear"></div>
                      </div>
                    </div>
                  </li>
                  <li> <span className={styles.span}></span>
                    <div className={styles.div}>
                      <div className={styles['row-c']}>
                        <div className={styles['td-left']}> <strong className={styles.strong1}>Country<i className={styles.red}>*</i>:</strong>
                          <SelectField
                            id="Present_Country"
                            name="Present_Country"
                            className={styles.select}
                            options={countries}
                            value={formData.Present_Country}
                            error={errors.Present_Country}
                            defaultValue={formData.Present_Country ? formData.Present_Country : "India"}
                            onChange={(e) => {
                              handleInputChange(e);
                              setFormData({ ...formData, Present_Country: e.target.value })
                            }}
                          />
                        </div>
                        <div className={styles['td-right']}> <strong className={styles.strong1}>Pin/Zip Code<i className={styles.red}>*</i>:</strong>
                          <InputField
                            type="number"
                            id="Present_Zip_Code"
                            name="Present_Zip_Code"
                            className={styles.input}
                            value={formData.Present_Zip_Code}
                            error={errors.Present_Zip_Code}
                            onChange={(e) => {
                              handleInputChange(e);
                              setFormData({ ...formData, Present_Zip_Code: e.target.value })
                            }}
                            required
                            aria-required="true"
                            mainClass={styles.div}
                          />
                        </div>
                        <div className={styles.clear}></div>
                      </div>
                    </div>
                  </li>
                  <li> <span className={styles.span}>Cell/Phone<i className={styles.red}>*</i>:</span>
                    <InputField
                      type="number"
                      id="Present_Phone"
                      name="Present_Phone"
                      className={styles.Present_Phone}
                      addButton={true}
                      containerId="addMobileTextbox"
                      value={formData.Present_Phone}
                      error={errors.Present_Phone}
                      handleAddMobile={handleAddMobile}
                      renderMobileInputs={renderMobileInputs}
                      onChange={(e) => {
                        handleInputChange(e);
                        setFormData({ ...formData, Present_Phone: e.target.value })
                      }}
                      required
                      aria-required="true"
                      mainClass={styles.div}

                    /> <div id="addMobileTextbox" className={styles.TextBoxContainer}></div>

                  </li>
                </ul>
              </div>
              <div className={styles['col-right']}>
                <h5>Permanent Address
                  <label className={styles.checkbox}>(Same as present address)
                    <input type="checkbox" id="sameAddress" name="sameAddress" onChange={handleCheckboxChange} checked={showPermanentAddress} />
                    <span className={styles.checkmark}
                    ></span></label>
                </h5>
                <ul className={!showPermanentAddress || formData.Permanent_Address ? '' : styles['dis-none']} id="permanentAddress">

                  {!showPermanentAddress && (
                    <>
                      <li>
                        <span className={styles.span}>Address<i className={styles.red}>*</i>:</span>
                        <div className={styles.div}>
                          <textarea
                            id="Permanent_Address"
                            name="Permanent_Address"
                            className={`${styles.input} ${styles.textarea} ${errors.Permanent_Address ? styles.error : ''}`}
                            required="required"
                            aria-required="true"
                            aria-invalid={errors.address1 ? 'true' : 'false'}
                            onChange={(e) => {
                              handleInputChange(e);
                              setFormData({ ...formData, Permanent_Address: e.target.value });
                              setErrors({ ...errors, Permanent_Address: '' });
                            }}
                            value={formData.Permanent_Address}
                          ></textarea>
                          {errors.Permanent_Address && (
                            <label id="address2-error" className={styles.error} htmlFor="Permanent_Address" style={{ display: 'inline' }}>
                              {errors.Permanent_Address}
                            </label>
                          )}
                        </div>
                      </li>
                      <li>
                        <span className={styles.span}></span>
                        <div className={styles.div}>
                          <div className={styles['row-c']}>
                            <div className={styles['td-left']}>
                              <strong className={styles.strong1}>City<i className={styles.red}>*</i>:</strong>
                              <InputField
                                type="text"
                                id="Permanent_City"
                                name="Permanent_City"
                                className={styles.input}
                                value={formData.Permanent_City}
                                error={errors.Permanent_City}
                                onChange={(e) => {
                                  handleInputChange(e);
                                  setFormData({ ...formData, Permanent_City: e.target.value });
                                }}
                                required
                                aria-required="true"
                                mainClass=""
                              />
                            </div>
                            <div className={styles['td-right']}>
                              <strong className={styles.strong1}>State<i className={styles.red}>*</i>:</strong>
                              <InputField
                                type="text"
                                id="Permanent_State"
                                name="Permanent_State"
                                className={styles.input}
                                value={formData.Permanent_State}
                                error={errors.Permanent_State}
                                onChange={(e) => {
                                  handleInputChange(e);
                                  setFormData({ ...formData, Permanent_State: e.target.value });
                                }}
                                required
                                aria-required="true"
                                mainClass={styles.div}
                              />
                            </div>
                            <div className={styles.clear}></div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <span className={styles.span}></span>
                        <div className={styles.div}>
                          <div className={styles['row-c']}>
                            <div className={styles['td-left']}>
                              <strong className={styles.strong1}>Country<i className={styles.red}>*</i>:</strong>
                              <SelectField
                                id="Permanent_Country"
                                name="Permanent_Country"
                                className={styles.select}
                                options={countries}
                                value={formData.Permanent_Country}
                                error={errors.Permanent_Country}
                                onChange={(e) => {
                                  handleInputChange(e);
                                  setFormData({ ...formData, Permanent_Country: e.target.value });
                                }}
                              />
                            </div>
                            <div className={styles['td-right']}>
                              <strong className={styles.strong1}>Pin/Zip Code<i className={styles.red}>*</i>:</strong>
                              <InputField
                                type="number"
                                id="Permanent_Zip_Code"
                                name="Permanent_Zip_Code"
                                className={styles.input}
                                value={formData.Permanent_Zip_Code}
                                error={errors.Permanent_Zip_Code}
                                onChange={(e) => {
                                  handleInputChange(e);
                                  setFormData({ ...formData, Permanent_Zip_Code: e.target.value });
                                }}
                                required
                                aria-required="true"
                                mainClass={styles.div}
                              />
                            </div>
                            <div className={styles.clear}></div>
                          </div>
                        </div>
                      </li>
                    </>
                  )}

                </ul>



              </div>
            </form>
            <div className={styles.clear}></div>
            <Button
              backButtonLabel="Back"
              continueButtonLabel="Save & Step 2 →"
              onBackClick={handleBackClick}
              onContinueClick={handleContinueClick}
              backButtonClass={styles['custom-back-class']}
              continueButtonClass={styles['custom-continue-class']}
              previewButtonLabel={isEdit ? "Preview" : ""}
              previewButtonAction={isEdit ? handlePreviewPage : ''}
            />


          </div>
        </>
      )}
      {showFamily && (
        <Family step={step} setStep={setStep} isEdit={isEdit} setIsEdit={setIsEdit} handlePreviewClick={handlePreviewClick} apiData={apiData} />
      )}</>
  );

}
export default Address;