import React, { useState, useEffect } from 'react';
import { Table as RsuiteTable, Pagination } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import styles from './Table.module.scss';

const Table = ({ data, columns, pageSize, onSort, loading, pagination = false, height, fillHeight, total, minWidth }) => {
  const [page, setPage] = useState(1);
  const [displayData, setDisplayData] = useState([]);
  
  useEffect(() => {
    const startIndex = (page - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize, data.length);
    const pagedData = data.slice(startIndex, endIndex);
    setDisplayData(pagedData);
  }, [page, data, pageSize]);

  const handleChangePage = (nextPage) => {
    setPage(nextPage);
  };

  return (
    <div className={styles.table}>
      <RsuiteTable
        height={height}
        autoHeight
        fillHeight={fillHeight}
        data={displayData}
        onSortColumn={(sortColumn, sortType) => onSort(sortColumn, sortType)} 
        loading={loading}
        hover={false}
        wordWrap='break-word'
        defaultSortColumn="First_Name"
        defaultSortType="asc"
      >
        {columns.map((col) => (
          <RsuiteTable.Column
            key={col.dataKey}
            sortable={col.sortable}
            fullText={false}
            minWidth={col.minwidth}
            width={col.minwidth}
          >
            <RsuiteTable.HeaderCell style={{height:"100px"}} className={styles.header}>{col.title}</RsuiteTable.HeaderCell>
            <RsuiteTable.Cell className={styles.row} dataKey={col.dataKey}>
              {(rowData) => {
                return col.renderer ? col.renderer(rowData) : rowData[col.dataKey];
              }}
            </RsuiteTable.Cell>
          </RsuiteTable.Column>
        ))}
      </RsuiteTable>
      {pagination && (
        <Pagination
          total={total}
          prev
          next
          activePage={page}
          onChangePage={handleChangePage}
          limit={1}
        />
      )}
    </div>
  );
};

export default Table;
