import React, { useState,useEffect } from 'react';
import { useToken } from '../../context/AuthContext'
import styles from '../../applicantContainers/Candidate/MainContent/Main.module.scss';
import avtar from '../../assets/images//avtar.jpg';
import SaveCandidateImage from '../../api/SaveImageData'
import FechCandidateImage from '../../api/FetchImageData'

const UploadPhoto = ({ onImageUpload }) => {
  const [image, setImage] = useState(null);
  const { token, zoho_candidate_id } = useToken();
  const [loading, setLoading] = useState(true);
  const [imageSizeError, setImageSizeError] = useState('');

   // Fetch image URL when component mounts
   useEffect(() => {
    const fetchImageURL = async () => {
     try {
        const response = await FechCandidateImage(zoho_candidate_id); 
        const imageUrl = response.picture_url;
        setImage(imageUrl);
      } catch (error) {
        console.error('Error fetching image URL:', error);
      } finally {
        setLoading(false);
      }
    };


    fetchImageURL();
  }, [zoho_candidate_id]);


  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    
   if (file) {

    if (file.size > 150 * 1024) {
      setImageSizeError('Image size exceeds 150KB limit.');
      return;
    }

    setImageSizeError('');
      const formData = new FormData();
      formData.append('Image', file); 
      try {
        const response = await SaveCandidateImage(formData, zoho_candidate_id);
       } catch (error) {
        console.error('Error saving candidate image:', error);
        
      }
  
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
      onImageUpload(file.name);
    }
  };
  

  return (
    <div>
      <span className={styles.span}>
        Upload Photo: <span className={styles['span-small']}>(Height - 200px, Width - 200px)</span>
      </span>
      <div className={`${styles['div']} ${styles.width} ${styles['basicINFO']}`}>
        <div className={styles['avatar-upload']}>
          <div className={styles['avatar-edit']}>
            <input type='file' id="imageUpload" name="imageUpload" accept="image/x-png,image/gif,image/jpeg" onChange={handleImageChange} />
            <label htmlFor="imageUpload"></label>
            
          </div>
          
          <div className={styles['avatar-preview']}>
          {loading ? (
              <div className={styles.loader}></div>
            ) : (
              <img id="imagePreview" src={image ? image : avtar} alt={image ? 'Uploaded' : 'Default'} />
            )}
          </div>
          
        </div>
        <span htmlFor="imageUpload" style={{ color: 'red', fontSize: '13px', paddingLeft: '10px' }}>{imageSizeError ? imageSizeError : ''}</span>
      </div>
    </div>
  );
};

export default UploadPhoto;
