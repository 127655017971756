import React, { useState, useEffect } from 'react';
import styles from '../../applicantContainers/Candidate/MainContent/Main.module.scss';
import axios from 'axios';
import ViewFeedback from '../../api/ViewFeedback';

const FeedbackDetail = ({ url, onClose,authenticateToken }) => {
    const [feedbackData, setFeedbackData] = useState([]);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        document.body.classList.add(styles['fixed-overlay-preview']);
        document.body.classList.add(styles['admin-popup-overlay']);
        document.body.classList.remove(styles['close-popup']);
    });

    const handleClosePopup = () => {
        document.body.classList.remove(styles['fixed-overlay-preview']);
        document.body.classList.remove(styles['admin-popup-overlay']);
        onClose();
    };

    const fetchFeedbackDetails = async () => {
        const params = new URLSearchParams(new URL(url).search);
        const token = params.get("token");
        try {

            const apiData = await ViewFeedback(token);
            const { data } = apiData.data;
            if (data) {
                if (Array.isArray(data)) {
                    setFeedbackData(data);
                    //setVisibleQuestions(data.length);
                }
            } else {
                setErrorMessage(apiData.data.msg);
                setShowErrorModal(true);
            }

        } catch (error) {
            console.error('Error fetching feedback details:', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            await fetchFeedbackDetails();
        };

        fetchData();
    }, [url]);

    return (
        <div className={styles['popup-preview']}>
            <div className={styles['popup-content-preview']}>
                <i className={styles['close-popup-preview']} onClick={handleClosePopup}></i>
                <h2><span>Preview</span> of Feedback Form</h2>
                <h4 className={styles['accordion-btn']} id="first-active"><span></span> Feedback Questions</h4>
                {errorMessage ? (
                    <span style={{ textAlign: 'center' }}><p>{errorMessage}</p></span>
                ) : (
                    <div className={styles['accordion-content']}>
                        <table cellPadding="0" cellSpacing="0" className={styles['basicTABLE']}>
                            <tbody>
                                {feedbackData.map((item, index) => (
                                    <React.Fragment key={index}>
                                        <tr>
                                            <td>
                                                <span className={styles['span']}>{item.question_title}:</span>
                                                <div className={styles.div}>
                                                    <input
                                                        type="text"
                                                        id={`prview_${item.question_title.replace(/\s+/g, '_').toLowerCase()}`}
                                                        name={item.question_title}
                                                        className={styles.input}
                                                        value={item.candidate_answer}
                                                        disabled
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        {item.question_type === "comment" && item.candidate_answer && (
                                            <tr>
                                                <td>
                                                    <div className={styles.div}>
                                                        <textarea
                                                            id={`prview_${item.question_title.replace(/\s+/g, '_').toLowerCase()}_comment`}
                                                            name={`${item.question_title}_comment`}
                                                            className={styles.textarea}
                                                            value={item.candidate_answer}
                                                            disabled
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
}

export default FeedbackDetail;