import axios from 'axios';


const GetFeedbackDetails = async (token) => {
 try {
  const survey_id = 1;
    const response = await axios.get(
      process.env.REACT_APP_API_URL + 'api/feedback/get-feedback-form/' + token, 
      {
        headers: {
          'Content-Type': 'application/json', 
        },
      }
    );
    if (response.status === 409) {
      // Return null if the status is 409
      return null;
    }
    return response;
  } catch (error) {
    console.error('Error fetching candidate details:', error);
    if (error.response) {
      console.log('Response data:', error.response.data);
    }
    throw error;
  }
};

export default GetFeedbackDetails;
