import React, { useState, useEffect } from 'react';
import { useToken } from '../../../context/AuthContext'
import styles from '../MainContent/Main.module.scss';
import InputField from '../../../components/InputField/InputField';
import Button from '../../../components/Button/Button';
import SelectField from '../../../components/SelectField/SelectField';
import Experience from './Experience';
import ProgressBar from './ProgressBar'
import GetCandidateDetails from '../../../api/fetchCandidateData'
import SaveCandidateData from '../../../api/SaveCandidateData'
import StepEmitter from '../../../components/StepEmitter/StepEmitter'
import Education from './Education';

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const nameRegex = /^[A-Za-z].*/;
const mobileRegex = /^\d/;
const ProfessionalEXP = ({ step, setStep, isEdit, setIsEdit, handlePreviewClick, apiData }) => {
    const { token, zoho_candidate_id } = useToken();
    const [showExperience, setExperience] = useState(false);
    const [showRreview, setshowRreview] = useState(false);
    const [showEducation,setEducation] =useState(false);
    const [experiData, setexperiData] = useState('');
    const totalSteps = 12;
    const referenceRelation = [
        { value: '-1', label: 'Select Relation' },
        { value: 'Boss', label: 'Boss' },
        { value: 'Project Manager', label: 'Project Manager' },
        { value: 'Reporting Manager', label: 'Reporting Manager' },
        { value: 'Team Lead', label: 'Team Lead' },
        { value: 'Colleague', label: 'Colleague' },
    ];

    

    const [references, setReferences] = useState([
        {
            Name: '',
            Mobile: '',
            Relation: '',
            Address: '',
            Email: '',
        },
        {
            Name: '',
            Mobile: '',
            Relation: '',
            Address: '',
            Email: '',
        }
    ]);

    const handleBackClick = () => {
        //setStep(5);
        StepEmitter.emit('stepChange', step - 1);
    };


    const handleContinueClick = async () => {
        if (validateForm()) {
            try {
                const referencesToSave = references.map(reference => ({
                    Name: reference.Name,
                    Mobile: reference.Mobile,
                    Relation: reference.Relation,
                    Address: reference.Address,
                    Email: reference.Email
                }));


                const dataToSave = {
                    candidate_references_details: referencesToSave,
                    Zoho_Item_Id: zoho_candidate_id,
                    Process_Status: "7"
                };
                const response = await SaveCandidateData(dataToSave);


                if (response.status === 200) {
                    
                        setExperience(true);
                        StepEmitter.emit('stepChange', step + 1);
                    
                } else {
                    console.error('Error saving candidate data:', response.statusText);

                }
            } catch (error) {
                console.error('Error saving candidate data:', error);

            }
        }
    };

    const handlePreviewPage = async () => {
        if (validateForm()) {
            try {
                const referencesToSave = references.map(reference => ({
                    Name: reference.Name,
                    Mobile: reference.Mobile,
                    Relation: reference.Relation,
                    Address: reference.Address,
                    Email: reference.Email
                }));


                const dataToSave = {
                    candidate_references_details: referencesToSave,
                    Zoho_Item_Id: zoho_candidate_id,
                    Process_Status: "7"
                };
                const response = await SaveCandidateData(dataToSave);


                if (response.status === 200) {
                    setshowRreview(true);

                    setStep(11);
                    StepEmitter.emit('stepChange', 11);
                } else {
                    console.error('Error saving candidate data:', response.statusText);

                }
            } catch (error) {
                console.error('Error saving candidate data:', error);

            }
        }
    };

    const handleAddReference = () => {
        setReferences(prevReferences => [
            ...prevReferences,
            {
                Name: '',
                Mobile: '',
                Relation: '',
                Address: '',
                Email: '',
            }
        ]);
    };

    const handleRemoveReference = (index) => {
        setReferences(prevReferences => prevReferences.filter((_, i) => i !== index));
    };

    // Function to fetch candidate details from API
    const fetchCandidateDetails = async () => {
        try {
            const details = await GetCandidateDetails(token, zoho_candidate_id, step);
            const apiData = details.data;
            const work = apiData.basic_details.work_experience;
            setexperiData(work);

            if (apiData.candidate_references_details && apiData.candidate_references_details.length > 0) {
                let referencesFromApi = apiData.candidate_references_details;
                if (referencesFromApi.length < 2) {
                    referencesFromApi = [
                        ...referencesFromApi,
                        {
                            Name: '',
                            Mobile: '',
                            Relation: '',
                            Address: '',
                            Email: '',
                        }
                    ];
                }
                setReferences(referencesFromApi);
            } else {
                setReferences([
                    {
                        Name: '',
                        Mobile: '',
                        Relation: '',
                        Address: '',
                        Email: '',
                    },
                    {
                        Name: '',
                        Mobile: '',
                        Relation: '',
                        Address: '',
                        Email: '',
                    }
                ]);
            }
        } catch {
            console.error('API has no data');
        }
    };

    useEffect(() => {
        fetchCandidateDetails();
    }, []);



    const handleSelectChange = (index, field, value) => {
        const newReferences = [...references];
        newReferences[index] = {
            ...newReferences[index],
            [field]: value,
            error: false
        };
        setReferences(newReferences);
    };


    const handleInputChange = (index, field, value) => {
        const newReferences = [...references];
        newReferences[index][field] = value;
        setReferences(newReferences);
    };




    // Validation function for the form
    const validateForm = () => {
        let isValid = true;
        const newReferences = [...references];

        newReferences.forEach((reference, index) => {

            if (
                !reference.Name.trim() ||
                !nameRegex.test(reference.Name) ||
                !reference.Address.trim() ||
                !reference.Mobile.trim() ||
                !mobileRegex.test(reference.Mobile) ||
                !reference.Email.trim() ||
                !emailRegex.test(reference.Email) ||
                reference.Relation === '-1'
            ) {
                isValid = false;
                newReferences[index].error = true;
                newReferences[index].nameError = !nameRegex.test(reference.Name);
                newReferences[index].addressError = !nameRegex.test(reference.Address);
                newReferences[index].mobileError = !mobileRegex.test(reference.Mobile);
                newReferences[index].emailError = !emailRegex.test(reference.Email);
            } else {
                newReferences[index].error = false;
                newReferences[index].emailError = false;
            }
        });
        setReferences(newReferences);
        return isValid;
    };


    return (
        <>
            {step === 6 && (
                <>
                    <ProgressBar currentStep={step} totalSteps={totalSteps} />
                    <div className={styles['required-details']}>Kindly fill the required details <span>*</span> of the form below.</div>
                    <h4 className={styles.heading1} style={{ display: step === 6 ? 'block' : 'none' }}><span>Reference</span></h4>
                    <div className={`${styles['row-c']} ${styles.width} ${styles.credentialREFER}`} style={{ display: step === 6 ? 'block' : 'none' }}>
                        <h5>Whom can we refer about your past experience/credentials?</h5>
                        <form id="credentialREFER" name="credentialREFER">
                            <div className={styles.reference}>
                                <p><strong>(Kindly share 2 professional references)</strong></p>
                                {references.map((reference, index) => (
                                    <ul key={index}>
                                        <li>
                                            <strong className={styles.strong}>Name<i className={styles.red}>*</i>:</strong>
                                            <InputField
                                                type="text"
                                                id={`Name_${index + 1}`}
                                                name={`Name_${index + 1}`}
                                                mainClass={styles['div-right']}
                                                value={reference.Name}
                                                error={
                                                    (reference.error && !reference.Name.trim() ? "This field is required" : '') +
                                                    (reference.nameError ? " Invalid name format." : '')
                                                }
                                                onChange={(e) => handleInputChange(index, 'Name', e.target.value)}
                                            />
                                        </li>
                                        <li>
                                            <strong className={styles.strong}>Address<i className={styles.red}>*</i>:</strong>
                                            <div className="div-right">
                                                <textarea
                                                    id={`Address_${index + 1}`}
                                                    name={`Address_${index + 1}`}
                                                    className={`${styles.input} ${styles.textarea} 
                                                    ${reference.error && !reference.Address.trim() ? styles.error : ''}
                                                     `}
                                                    required
                                                    value={reference.Address}
                                                    onChange={(e) => handleInputChange(index, 'Address', e.target.value)}
                                                ></textarea>
                                            </div>
                                        </li>
                                        <li>
                                            <strong className={styles.strong}>Telephone/Cell<i className={styles.red}>*</i>:</strong>
                                            <InputField
                                                type="number"
                                                id={`Mobile_${index + 1}`}
                                                name={`Mobile_${index + 1}`}
                                                mainClass={styles['div-right']}
                                                value={reference.Mobile}
                                                error={
                                                    (reference.error && !reference.Mobile.trim() ? "This field is required" : '') +
                                                    (reference.mobileError ? " Invalid Mobile format." : '')
                                                }
                                                onChange={(e) => handleInputChange(index, 'Mobile', e.target.value)}
                                            />
                                        </li>
                                        <li>
                                            <strong className={styles.strong}>Email Id<i className={styles.red}>*</i>:</strong>
                                            <InputField
                                                type="email"
                                                id={`Email_${index + 1}`}
                                                name={`Email_${index + 1}`}
                                                mainClass={styles['div-right']}
                                                value={reference.Email}
                                                error={
                                                    (reference.error && !reference.Email.trim() ? "This field is required" : '') +
                                                    (reference.emailError ? " Invalid email format." : '')
                                                }
                                                onChange={(e) => handleInputChange(index, 'Email', e.target.value)}
                                            />
                                        </li>
                                        <li>
                                            <strong className={styles.strong}>Relation<i className={styles.red}>*</i>:</strong>
                                            <div className={styles['div-right']}>
                                                <SelectField
                                                    id={`Relation_${index + 1}`}
                                                    name={`Relation_${index + 1}`}
                                                    className={`${styles.select} ${reference.error && (reference.Relation === '-1' || reference.Relation.trim() === '') ? styles.error : ''}`}
                                                    options={referenceRelation}
                                                    value={reference.Relation}
                                                    onChange={(e) => handleSelectChange(index, 'Relation', e.target.value)}
                                                    defaultValue={reference.Relation ? reference.Relation : "Select Relation"}
                                                />
                                            </div>
                                        </li>
                                        {index > 1 && (
                                            <li>
                                                <input
                                                    type="button"
                                                    value="Remove"
                                                    className={styles.remove}
                                                    onClick={() => handleRemoveReference(index)}
                                                />
                                            </li>
                                        )}
                                        {index > 1 && (
                                            <li>
                                                <input id="addreference" className={`${styles.BTNadd2} ${styles.BTNadd3} `} type="button" value="Add" onClick={handleAddReference} />
                                            </li>
                                        )}

                                    </ul>
                                ))}

                            </div>
                        </form>
                        <Button
                            backButtonLabel="Back"
                            continueButtonLabel="Save & Step 3 →"
                            onBackClick={handleBackClick}
                            onContinueClick={handleContinueClick}
                            previewButtonLabel={isEdit ? "Preview" : ""}
                            previewButtonAction={isEdit ? handlePreviewPage : ''}
                        />

                    </div>

                </>
            )}
            {showExperience && (
                <Experience step={step} setStep={setStep} isEdit={isEdit} setIsEdit={setIsEdit} handlePreviewClick={handlePreviewClick} apiData={apiData} />
            )}
        </>
    );
};

export default ProfessionalEXP;
